.rules_view {
    font-size: 14px;
    padding: 20px;
    overflow-y: scroll;

    .rules_header {
        .header {
            display: grid;
            grid-template-columns: auto 200px;
            grid-template-rows: 40px;
            align-items: center;

            .title {
                font-size: 20px;
            }

            .actions {
                display: flex;
                flex-direction: row;
                justify-content: end;

                .new_rule_button {
                    display: flex;
                    flex-direction: column;
                    height: 30px;
                    width: 150px;
                    display: flex;
                    flex-direction: row;
                    // background-color: #e3e3e3;
                    color: #fff;
                    border-radius: 3px;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;

                    .icon {
                        margin-right: 10px;
                    }
                }
            }
        }

        .resources_container {
            display: flex;
            flex-direction: row;
            height: 30px;
            font-size: 12px;
            margin-bottom: 10px;

            .resource_container {
                height: 100%;
                display: flex;
                flex-direction: row;
                width: 200px;
                align-items: center;
                color: #2196f3;
                font-weight: bold;
                cursor: pointer;

                .icon {
                    margin-right: 10px;
                }
            }
        }

        .line_break {
            height: 1px;
            display: flex;
            flex-direction: row;
            background-color: #eee;
        }
    }

    .rules_modifier {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin: 10px 0px;

        .search_container {
            width: 200px;
            display: flex;
            flex-direction: column;
            height: 30px;

            .input_container {
                display: flex;
                flex-direction: row;
                height: 100%;
                align-items: center;
                background-color: #f7f7f7;
                border-radius: 3px;
                color: #d6d6d6;

                .icon {
                    width: 20px;
                    margin-left: 10px;
                }

                .value {
                    input {
                        border: none;
                        background: none;
                        color: #969696;

                        &:focus {
                            outline: none;
                        }
                    }
                }
            }
        }
    }

    .rules_container {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 10px;

        .rule_container {
            margin: 5px 0px;
            display: grid;
            grid-template-columns: 70px auto 150px;
            grid-template-rows: auto;
            align-items: center;
            border: 1px solid #eee;
            border-radius: 5px;
            padding: 10px 0px 10px 10px;
            position: relative;

            .rule_description {
                grid-column: 2/2;
                display: grid;
                grid-template-columns: auto 150px 120px 100px;
                grid-template-rows: auto;
                font-size: 12px;
                font-weight: lighter;

                .rule_description_item {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    height: 100%;
                    justify-content: center;

                    .title {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        font-weight: bold;
                        margin-bottom: 5px;
                    }

                    .value {
                        line-height: 1.5em;
                    }
                }

                .name {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    padding-right: 30px;
                }

                span {
                    font-weight: bold;
                }
            }

            .actions {
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                align-items: center;

                .action_item {
                    margin: 0px 10px;
                    cursor: pointer;
                }

                a {
                    color: black;
                }

                .logs {
                    background-color: #e3e3e3;
                    padding: 7px 10px;
                    border-radius: 3px;
                    color: #fff;
                }
            }
        }
    }
}
