.integrations_view {
    display: grid;
    grid-template-columns: 200px auto;
    font-size: 14px;
    overflow-y: scroll;

    .nav_container {
        grid-column: 1/1;
        display: flex;
        flex-direction: column;

        .nav {
            height: 100%;
            margin: 10px;
            padding: 10px;
            background-color: #f5f5f5;
            border-radius: 5px;

            .nav_item {
                cursor: pointer;
                padding: 10px 0px 10px 5px;
                color: #bebebe;

                &:hover {
                    color: #fff;
                    background-color: #41a9ff;
                    border-radius: 3px;
                }
            }
        }
    }

    .integrations_container {
        grid-column: 2/2;
        overflow-y: scroll;

        .integrations_group_container {
            display: flex;
            flex-direction: column;
            margin: 10px 0px;

            .group_name {
                margin-left: 15px;
                font-size: 16px;
                font-weight: bold;
                margin-bottom: 5px;
            }

            .integrations {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-template-rows: 150px;
                margin: 10px 0px;

                .integration_container {
                    border: 1px solid #eee;
                    border-radius: 5px;
                    margin: 0px 10px;
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    padding: 20px;
                    display: flex;
                    flex-direction: column;
                    cursor: pointer;

                    .actions {
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        .action_container {
                            margin: 0px 20px;
                            font-weight: bold;
                        }

                        .action_container:first-child {
                            margin: 0px 20px 0px 0px;
                        }
                    }

                    .logo {
                        display: flex;
                        flex-direction: column;
                        height: 60px;
                        margin: 5px 0px;

                        img {
                            width: 40px;
                        }
                    }

                    .name {
                        margin: 10px 0px;
                    }

                    .status {
                        margin: 5px 0px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
