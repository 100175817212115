.audiences_view {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 20px;
    overflow: hidden;

    .tabs {
        display: flex;
        flex-direction: row;
        min-height: 40px;
        border: 1px solid #eee;
        align-items: center;

        .tab_container {
            display: flex;
            flex-direction: column;
            width: 200px;
            justify-content: center;
            align-items: center;
            border-right: 1px solid #eee;
            height: 100%;
            cursor: pointer;
            color: #666;
        }
    }

    .audiences_finder_container {
        display: flex;
        flex-direction: column;
        height: 100%;
        margin-top: 20px;

        .search_header_container {
            display: flex;
            flex-direction: column;
            height: 40px;
            width: 100%;
            margin-bottom: 20px;

            .search_container {
                display: grid;
                grid-template-columns: auto 50px;
                grid-template-rows: 40px auto;
                height: 100%;

                .search_bar {
                    grid-column: 1/1;
                    grid-row: 1/1;
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    height: 100%;

                    input {
                        height: 100%;
                    }
                }

                .search_icon {
                    grid-column: 2/2;
                    grid-row: 1/1;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                    cursor: pointer;
                }
            }
        }

        .audience_table_container {
            display: flex;
            flex-direction: column;
            width: 100%;
            border: 1px solid #eee;
            min-height: 500px;
            overflow: hidden;
            margin-bottom: 60px;

            .audience_table_tabs {
                display: grid;
                grid-template-columns: 450px 140px 120px 120px 220px auto;
                grid-template-rows: 40px;
                align-items: center;

                .tab_cell {
                    padding-left: 20px;
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    justify-content: center;
                    border-bottom: 1px solid #eee;
                }

                .tab_cell:first-of-type {
                    padding-left: 20px;
                }

                .tab_cell:last-of-type {
                    min-width: 100px;
                    // min-width: 100%;
                }
            }

            .table_container {
                display: flex;
                flex-direction: column;
                height: 100%;
                overflow: scroll;
                -ms-overflow-style: none;
                scrollbar-width: none;

                &::-webkit-scrollbar {
                    display: none;
                }

                .name_audience_cell {
                    padding-left: 20px;
                }

                .audience_row {
                    display: grid;
                    grid-template-columns: 450px 140px 120px 120px 220px auto;
                    grid-template-rows: 40px;
                    align-items: center;
                    font-size: 14px;

                    .audience_cell {
                        padding-left: 20px;
                        display: flex;
                        flex-direction: column;
                        height: 40px;
                        justify-content: center;
                        border-bottom: 1px solid #eee;
                        // align-items: flex-end;
                        // border-right: 1px solid #eee;
                    }

                    .audience_cell:first-of-type {
                        // align-items: flex-start;
                    }

                    .age_row {
                        display: flex;
                        flex-direction: row;

                        .age {
                            width: 80px;
                        }
                    }

                    .actions {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-end;
                        height: 40px;
                        align-items: center;
                        border-bottom: 1px solid #eee;
                        min-width: 100px;

                        .stats {
                            display: flex;
                            flex-direction: row;
                            justify-content: flex-end;
                            margin-right: 20px;
                            cursor: pointer;
                        }

                        .save {
                            display: flex;
                            flex-direction: row;
                            justify-content: flex-end;
                            margin-right: 20px;

                            .icon {
                                cursor: pointer;
                            }
                        }

                        .suggestions {
                            display: flex;
                            flex-direction: row;
                            justify-content: flex-end;
                            margin-right: 20px;

                            .icon {
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
    }
}

.column_title_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    min-height: 25px;
    justify-content: space-between;
    padding: 0px 5px;
    margin-top: 2px;
    border-bottom: 1px solid #eee;
    cursor: pointer;
}

.loading_view {
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    z-index: 999;
    background-color: #fff;
    opacity: 0.6;

    .loading_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        font-size: 40px;
    }
}

.interst_insights_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0px 20px;

    .interest_header {
        display: grid;
        grid-template-columns: auto 300px;
        grid-template-rows: 30px;
        width: 100%;
        border-bottom: 1px solid #eee;
        margin-bottom: 20px;
        padding-bottom: 10px;

        .left_adjusted {
            grid-column: 1/1;
            grid-row: 1/1;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .interest_name {
                font-size: 25px;
            }
        }

        .right_adjusted {
            grid-column: 2/2;
            grid-row: 1/1;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;

            .size_container {
                display: flex;
                flex-direction: row;
                margin-right: 20px;

                .icon {
                    margin-right: 10px;
                }
            }
        }
    }

    .full_container {
        display: flex;
        flex-direction: row;
        width: 100%;
        min-height: 400px;
        margin-bottom: 20px;

        .full_outer_container {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
        }

        .half_outer_container {
            display: flex;
            flex-direction: column;
            width: 50%;
            height: 100%;
        }

        .inner_container {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
            border-radius: 5px;
            align-items: center;

            .chart_header {
                display: flex;
                flex-direction: column;
                min-height: 40px;
                width: 100%;
                border-bottom: 1px solid #eee;
                justify-content: center;
                font-weight: bold;
                padding-left: 20px;
            }

            .chart_container {
                display: grid;
                grid-template-columns: 100%;
                grid-template-rows: auto 50px;
                height: 100%;
                width: 100%;
                justify-content: center;
                align-items: center;

                .chart {
                    grid-row: 1/1;
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    height: 80%;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
}

.labels_container {
    grid-row: 2/2;
    display: flex;
    flex-direction: row;
    height: 50px;
    align-items: center;
    justify-content: center;

    .label_container {
        width: 150px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #f5f5f5;
        margin: 0px 5px;
        border-radius: 5px;
        margin-top: -20px;

        .name {
            display: flex;
            flex-direction: column;
            min-height: 20px;
            font-size: 12px;
            color: #666;
        }

        .value {
            display: flex;
            flex-direction: column;
            min-height: 20px;
            font-size: 18px;
            font-weight: bold;
        }
    }
}

.bar_chart_label {
    font-weight: bold;
}

.ReactModal__Content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .interst_insights_container {
        padding: 20px;
        display: flex;
        flex-direction: row;
        min-width: 100%;
        overflow: hidden;

        .modal_content {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-right: auto;
            padding-right: 20px;
            overflow: scroll;
            padding-bottom: 10px;
            margin-bottom: -20px;
            padding-left: 10px;
            -ms-overflow-style: none;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .audience_close_modal_container {
            display: flex;
            flex-direction: column;
            width: 35px;
            cursor: pointer;
            font-size: 40px;
            color: #eee;
            height: 40px;
            cursor: pointer;
            margin-top: -5px;
        }
    }
}
