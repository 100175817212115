%conditions_container {
    .trigger_container {
        width: 375px;
        display: flex;
        flex-direction: column;
        font-size: 12px;
        border: 1px solid #eee;
        border-radius: 3px;
        padding: 10px;
        background-color: #fff;

        .header_container {
            display: grid;
            grid-template-columns: 100px auto;
            margin-bottom: 15px;
            margin-top: 5px;

            .title_text {
                grid-column: 1/1;
                font-weight: bold;
                padding-left: 2px;
            }

            .scopes_container {
                grid-column: 2/2;
                display: flex;
                flex-direction: row;
                justify-content: end;

                .metric_scope {
                    margin-right: 10px;
                    display: flex;
                    cursor: pointer;
                }
            }
        }

        .search_bar {
            width: calc(100% - 10px);
        }

        .categories {
            margin-top: 10px;
            height: 300px;
            overflow: scroll;

            .categories_dropdown_container {
                background-color: #fff;
                display: flex;
                flex-direction: column;
                height: 100%;

                .category_container {
                    width: 100%;
                    border-bottom: none;
                    font-size: 12px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    font-weight: bold;
                    cursor: pointer;

                    .accordion__button {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        height: 40px;
                        width: 100%;
                        padding-left: 10px;
                    }
                }

                .metric_field {
                    padding: 10px 0px;
                    padding-left: 30px;
                    cursor: pointer;

                    &:hover {
                        background-color: #eee;
                    }
                }
            }
        }
    }

    .timeframe_container {
        width: 300px;
        display: flex;
        flex-direction: column;
        font-size: 12px;
        border: 1px solid #eee;
        border-radius: 3px;
        padding: 10px;
        background-color: #fff;

        .search_bar {
            width: calc(100% - 10px);
        }

        .categories {
            margin-top: 10px;
            height: 200px;
            overflow: scroll;

            .categories_dropdown_container {
                background-color: #fff;
                display: flex;
                flex-direction: column;
                height: 100%;

                .category_container {
                    width: 100%;
                    border-bottom: none;
                    font-size: 12px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    font-weight: bold;
                    cursor: pointer;

                    .accordion__button {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        height: 40px;
                        width: 100%;
                        padding-left: 10px;
                    }
                }

                .metric_field {
                    padding: 10px 0px;
                    padding-left: 30px;
                    cursor: pointer;

                    &:hover {
                        background-color: #eee;
                    }
                }
            }
        }
    }

    .predicate_container {
        width: 230px;
        display: flex;
        flex-direction: column;
        font-size: 12px;
        border: 1px solid #eee;
        border-radius: 3px;
        background-color: #fff;

        .metric_field {
            padding: 10px 10px;
            cursor: pointer;

            &:hover {
                background-color: #eee;
            }
        }
    }

    .conditions_container {
        display: grid !important;
        border-radius: 5px;

        .expressions_operand_bar {
            grid-column: 1/1;
            height: calc(100% - 67px);
            padding-top: 5px;
            display: flex;
            flex-direction: row;
            align-items: center;

            .background {
                border-radius: 3px;
                height: 100%;
                width: 20px;
                display: flex;
                flex-direction: row;
                align-items: center;
                // background-color: #41a9ff;
                background-color: #e3e3e3;

                .text {
                    transform: rotate(-90deg);
                    color: #fff;
                    font-weight: lighter;
                    font-size: 12px;
                }
            }
        }

        .conditions_grid {
            font-size: 14px;

            .expressions_container {
                display: grid;
                grid-template-rows: auto;

                .expressions_operand_bar {
                    grid-column: 1/1;
                    height: calc(100% - 55px);
                    padding-top: 5px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .background {
                        border-radius: 3px;
                        height: 100%;
                        width: 20px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        // background-color: #41a9ff;
                        background-color: #e3e3e3;

                        .text {
                            margin-left: -4px;
                            transform: rotate(-90deg);
                            color: #fff;
                            font-weight: lighter;
                            font-size: 12px;
                        }
                    }
                }

                .expressions_grid {
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 10px;

                    .expression_container {
                        display: grid;
                        grid-template-columns: repeat(4, minmax(0, 1fr)) 50px;
                        margin: 5px 0px;
                        font-size: 12px;

                        .expression_metric {
                            display: flex;
                            flex-direction: column;
                            padding: 0px 10px;
                            margin: 0px 10px 0px 0px;
                            border: 1px solid #eee;
                            border-radius: 3px;
                            background-color: #fff;
                            position: relative;

                            .select {
                                cursor: pointer;
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                                height: 30px;
                                align-items: center;

                                .icon {
                                    font-size: 12px;
                                }
                            }

                            .dropdown {
                                position: absolute;
                                z-index: 99999;
                                top: 35px;
                                left: 0px;
                            }
                        }

                        .expression_timeframe {
                            display: flex;
                            flex-direction: column;
                            padding: 0px 10px;
                            margin: 0px 10px 0px 0px;
                            border: 1px solid #eee;
                            border-radius: 3px;
                            background-color: #fff;
                            position: relative;

                            .select {
                                cursor: pointer;
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                                height: 30px;
                                align-items: center;

                                .icon {
                                    font-size: 12px;
                                }
                            }

                            .dropdown {
                                position: absolute;
                                z-index: 99999;
                                top: 35px;
                                left: 0px;
                            }
                        }

                        .expression_predicate {
                            display: flex;
                            flex-direction: column;
                            padding: 0px 10px;
                            margin: 0px 10px 0px 0px;
                            border: 1px solid #eee;
                            border-radius: 3px;
                            background-color: #fff;
                            position: relative;

                            .select {
                                cursor: pointer;
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                                height: 30px;
                                align-items: center;

                                .icon {
                                    font-size: 12px;
                                }
                            }

                            .dropdown {
                                position: absolute;
                                z-index: 99999;
                                top: 35px;
                                left: 0px;
                            }
                        }

                        .expression_value {
                            display: flex;
                            flex-direction: column;
                            padding: 0px 10px;
                            margin: 0px 10px 0px 0px;
                            border: 1px solid #eee;
                            border-radius: 3px;
                            background-color: #fff;

                            input {
                                border: none;
                                height: 30px;

                                &:focus {
                                    outline: none;
                                }
                            }
                        }

                        .expression_actions {
                            display: flex;
                            align-items: center;
                            flex-direction: row;

                            .delete {
                                cursor: pointer;
                            }
                        }
                    }

                    .ads_manager_expression_container {
                        display: grid;
                        grid-template-columns: repeat(3, minmax(0, 1fr)) 50px;
                        margin: 5px 0px;
                        font-size: 12px;

                        .expression_metric {
                            display: flex;
                            flex-direction: column;
                            padding: 0px 10px;
                            margin: 0px 10px 0px 0px;
                            border: 1px solid #eee;
                            border-radius: 3px;
                            background-color: #fff;
                            position: relative;

                            .select {
                                cursor: pointer;
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                                height: 30px;
                                align-items: center;

                                .icon {
                                    font-size: 12px;
                                }
                            }

                            .dropdown {
                                position: absolute;
                                z-index: 99999;
                                top: 35px;
                                left: 0px;
                            }
                        }

                        .expression_timeframe {
                            display: flex;
                            flex-direction: column;
                            padding: 0px 10px;
                            margin: 0px 10px 0px 0px;
                            border: 1px solid #eee;
                            border-radius: 3px;
                            background-color: #fff;
                            position: relative;

                            .select {
                                cursor: pointer;
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                                height: 30px;
                                align-items: center;

                                .icon {
                                    font-size: 12px;
                                }
                            }

                            .dropdown {
                                position: absolute;
                                z-index: 99999;
                                top: 35px;
                                left: 0px;
                            }
                        }

                        .expression_predicate {
                            display: flex;
                            flex-direction: column;
                            padding: 0px 10px;
                            margin: 0px 10px 0px 0px;
                            border: 1px solid #eee;
                            border-radius: 3px;
                            background-color: #fff;
                            position: relative;

                            .select {
                                cursor: pointer;
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                                height: 30px;
                                align-items: center;

                                .icon {
                                    font-size: 12px;
                                }
                            }

                            .dropdown {
                                position: absolute;
                                z-index: 99999;
                                top: 35px;
                                left: 0px;
                            }
                        }

                        .expression_value {
                            display: flex;
                            flex-direction: column;
                            padding: 0px 10px;
                            margin: 0px 10px 0px 0px;
                            border: 1px solid #eee;
                            border-radius: 3px;
                            background-color: #fff;

                            input {
                                border: none;
                                height: 30px;

                                &:focus {
                                    outline: none;
                                }
                            }
                        }

                        .expression_actions {
                            display: flex;
                            align-items: center;
                            flex-direction: row;

                            .delete {
                                cursor: pointer;
                            }
                        }
                    }

                    .group_actions_container {
                        margin: 5px 0px;
                    }
                }
            }

            .conditions_actions_container {
                .add_or_condition {
                    font-size: 12px;
                    .button {
                        display: flex;
                        flex-direction: row;
                        cursor: pointer;
                        margin-left: 3px;
                        width: 100px;

                        .icon {
                            margin-right: 10px;
                        }

                        .text {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                        }
                    }
                }
            }
        }
    }
}

.add_filter_button {
    font-size: 12px;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    margin-left: 3px;
    width: 100%;
    height: 70px;
    align-items: center;
    justify-content: center;

    .icon {
        margin-right: 10px;
    }

    .text,
    .icon {
        margin-top: -5px;
    }
}
