.visualizer_search_view {
    display: flex;
    margin-top: 300px;
    // align-items: center;
    justify-content: center;

    .search_container {
        .header {
            display: flex;
            justify-content: center;
            margin-bottom: 20px;
        }

        .input {
            width: 500px;
        }
    }
}

.visualizer_view {
    width: 100%;
    height: 100%;
    overflow: hidden;

    .page_view_iframe {
        position: absolute;
        right: 0px;
        width: calc(100% - 200px);
        height: 100%;
        border: none;
    }
}
