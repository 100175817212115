html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.home_view {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  font-family: "Inter", sans-serif !important;
  font-size: 20px;
  margin-bottom: 20px;
}
.home_view .header_section_container {
  display: flex;
  flex-direction: column;
  height: 60px;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid #eee;
}
.home_view .header_section_container .header_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 1000px;
}
.home_view .header_section_container .header_container .header_content_container {
  display: grid;
  grid-template-columns: 130px auto 300px;
  grid-template-rows: 60px;
  align-items: center;
}
.home_view .header_section_container .header_container .header_content_container .logo {
  grid-column: 1/1;
}
.home_view .header_section_container .header_container .header_content_container .nav {
  grid-column: 2/2;
  display: flex;
  flex-direction: row;
}
.home_view .header_section_container .header_container .header_content_container .nav .nav_item {
  display: flex;
  flex-direction: column;
  margin: 0px 20px;
  font-size: 14px;
  cursor: pointer;
}
.home_view .header_section_container .header_container .header_content_container .actions {
  grid-column: 3/3;
  display: flex;
  flex-direction: row;
  justify-content: end;
}
.home_view .header_section_container .header_container .header_content_container .actions .action_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0px 20px;
  font-size: 14px;
  cursor: pointer;
}
.home_view .header_section_container .header_container .header_content_container .actions .try_it_button {
  padding: 10px 20px;
  font-size: 12px;
  margin-right: 0px;
}
.home_view .home_section_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.home_view .home_section_container::-webkit-scrollbar {
  display: none;
}
.home_view .home_section_container .home_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-top: 30px;
}
.home_view .home_section_container .home_container .headline_container {
  grid-column: 2/2;
  font-size: 36px;
  font-weight: 800;
  text-align: center;
}
.home_view .home_section_container .home_container .main_video_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 60px;
  width: 100%;
}
.home_view .home_section_container .home_container .main_video_container .video {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
}
.home_view .home_section_container .home_container .content_section_outer_container {
  display: grid;
  grid-template-columns: auto 1000px auto;
  grid-template-rows: auto;
  padding: 30px 0px 40px 0px;
}
.home_view .home_section_container .home_container .content_section_container {
  grid-column: 2/2;
  display: flex;
  flex-direction: column;
}
.home_view .home_section_container .home_container .content_section_container .headline {
  font-size: 50px;
  font-weight: bold;
  margin-bottom: 40px;
}
.home_view .home_section_container .home_container .content_section_container .content .text_section {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding-right: 30px;
  line-height: 1.2;
}
.home_view .home_section_container .home_container .content_section_container .content .text_section .steps_section {
  display: flex;
  flex-direction: row;
}
.home_view .home_section_container .home_container .content_section_container .content .text_section .steps_section .icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}
.home_view .home_section_container .home_container .right_content_section {
  display: grid;
  grid-template-rows: auto auto;
}
.home_view .home_section_container .home_container .right_content_section .headline {
  grid-column: 1/2;
  grid-row: 1/1;
  font-size: 50px;
  font-weight: bold;
  margin-bottom: 40px;
}
.home_view .home_section_container .home_container .right_content_section .content {
  grid-column: 1/2;
  grid-row: 2/2;
}
.home_view .home_section_container .home_container .right_content_section .content .text_section {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding-right: 30px;
  line-height: 1.2;
}
.home_view .home_section_container .home_container .right_content_section .side_section {
  grid-column: 2/3;
  grid-row: 1/3;
}
.home_view .home_section_container .home_container .right_content_section .image {
  grid-column: 2/3;
  grid-row: 1/3;
}
.home_view .home_section_container .home_container .right_content_section .image .cookie_monster_img_container {
  display: flex;
  flex-direction: column;
}
.home_view .home_section_container .home_container .right_content_section .image .img_container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.home_view .home_section_container .home_container .right_content_section .image .img_container img {
  border-radius: 5px;
}
.home_view .home_section_container .home_container .left_content_section {
  display: grid;
  grid-template-rows: auto auto;
}
.home_view .home_section_container .home_container .left_content_section .headline {
  grid-column: 2/3;
  grid-row: 1/1;
  font-size: 50px;
  font-weight: bold;
  margin-bottom: 40px;
  padding-left: 40px;
}
.home_view .home_section_container .home_container .left_content_section .content {
  grid-column: 2/3;
  grid-row: 2/2;
}
.home_view .home_section_container .home_container .left_content_section .content .text_section {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding-left: 40px;
  line-height: 1.2;
}
.home_view .home_section_container .home_container .left_content_section .side_section {
  grid-column: 1/2;
  grid-row: 1/3;
}
.home_view .home_section_container .home_container .left_content_section .image {
  grid-column: 1/2;
  grid-row: 1/3;
}
.home_view .home_section_container .home_container .left_content_section .image .img_container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.home_view .home_section_container .home_container .left_content_section .image .img_container img {
  border-radius: 5px;
}
.home_view .home_section_container .home_container .feature_content_section {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
.home_view .home_section_container .home_container .feature_content_section .feature_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-right: 1px solid #eee;
  align-items: center;
}
.home_view .home_section_container .home_container .feature_content_section .feature_container:last-of-type {
  border: none;
}
.home_view .home_section_container .home_container .feature_content_section .feature_container .title {
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.home_view .home_section_container .home_container .feature_content_section .feature_container .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.home_view .home_section_container .home_container .feature_content_section .feature_container .content .bullet {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding: 0px 15px;
  line-height: 1.2;
}
.home_view .home_section_container .home_container .feature_content_section .feature_container .content .bullet .text {
  margin-bottom: 15px;
  font-size: 14px;
}
.home_view .home_section_container .home_container .headline_content_section {
  grid-column: 2/2;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  text-align: center;
}
.home_view .home_section_container .home_container .features_container {
  display: flex;
  flex-direction: column;
  background-color: #f8f8f8;
  padding: 20px;
  border-radius: 10px;
}
.home_view .home_section_container .home_container .features_container .feature {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  font-size: 14px;
  padding-left: 10px;
}
.home_view .home_section_container .home_container .features_container .feature:last-of-type {
  margin-bottom: 0px;
}
.home_view .home_section_container .home_container .features_container .feature .icon {
  margin-right: 10px;
}
.home_view .home_section_container .home_container .cost_per_hour_section {
  display: flex;
  flex-direction: row;
  background-color: #f8f8f8;
  padding: 20px;
  border-radius: 10px;
  height: 200px;
  justify-content: center;
  align-items: center;
}
.home_view .home_section_container .home_container .cost_per_hour_section .amount {
  font-size: 50px;
  font-weight: bold;
  margin-right: 10px;
}
.home_view .home_section_container .home_container .cost_per_hour_section .time {
  margin-top: 20px;
  font-size: 20px;
}
.home_view .home_section_container .home_container .get_started_section {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.home_view .home_section_container .home_container .get_started_section .headline {
  font-weight: bold;
  font-size: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}
.home_view .home_section_container .home_container .get_started_section .subheadline {
  font-weight: bold;
  font-size: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}
.home_view .home_section_container .home_container .get_started_section .cta {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px;
  height: 50px;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  border-radius: 5px;
  margin-bottom: 20px;
  cursor: pointer;
}
.home_view .home_section_container .home_container .get_started_section .risk_reversals {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  font-size: 12px;
}
.home_view .home_section_container .home_container .get_started_section .risk_reversals .item {
  display: flex;
  flex-direction: row;
  margin: 0px 20px;
}
.home_view .home_section_container .home_container .get_started_section .risk_reversals .item .icon {
  margin-right: 10px;
}

.header_try_it_button_container {
  display: grid;
  grid-template-columns: 100px 50px;
  grid-template-rows: 40px;
  border-radius: 30px;
  background-color: #eb072a;
  color: #fff;
  align-items: center;
}
.header_try_it_button_container .text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: -20px;
}
.header_try_it_button_container .bubble_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 40px;
  background-color: #eb072a;
  border-radius: 100px;
}
.header_try_it_button_container .bubble_container .icon_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: -7px;
  width: 30px;
  height: 30px;
  background-color: #fff;
  border-radius: 100px;
}
.header_try_it_button_container .bubble_container .icon_container .icon {
  color: #000;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: -3px;
}

.cta_try_it_button_container {
  display: grid;
  grid-template-columns: 300px 50px;
  grid-template-rows: 60px;
  border-radius: 30px;
  background-color: #eb072a;
  color: #fff;
  align-items: center;
}
.cta_try_it_button_container .text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: -60px;
}
.cta_try_it_button_container .bubble_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #eb072a;
  border-radius: 100px;
}
.cta_try_it_button_container .bubble_container .icon_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: -7px;
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 100px;
}
.cta_try_it_button_container .bubble_container .icon_container .icon {
  color: #000;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: -3px;
}

.wave {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100px;
  z-index: 0;
  margin-top: 3px;
  transform: skewY(-3deg);
  transform-origin: top left;
}

.order_view {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  font-family: "Inter", sans-serif !important;
  overflow-x: hidden;
  overflow-y: scroll;
}
.order_view .loading_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.order_view .loading_container .text {
  margin-bottom: 40px;
  font-size: 30px;
}
.order_view .loading_container .icon {
  font-size: 50px;
}
.order_view .header_section_container {
  display: flex;
  flex-direction: column;
  height: 60px;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid #eee;
}
.order_view .header_section_container .header_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 1000px;
}
.order_view .header_section_container .header_container .header_content_container {
  display: grid;
  grid-template-columns: 130px auto 300px;
  grid-template-rows: 60px;
  align-items: center;
}
.order_view .header_section_container .header_container .header_content_container .logo {
  grid-column: 1/1;
}
.order_view .header_section_container .header_container .header_content_container .nav {
  grid-column: 2/2;
  display: flex;
  flex-direction: row;
}
.order_view .header_section_container .header_container .header_content_container .nav .nav_item {
  display: flex;
  flex-direction: column;
  margin: 0px 20px;
  font-size: 14px;
  cursor: pointer;
}
.order_view .header_section_container .header_container .header_content_container .actions {
  grid-column: 3/3;
  display: flex;
  flex-direction: row;
  justify-content: end;
}
.order_view .header_section_container .header_container .header_content_container .actions .action_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0px 20px;
  font-size: 14px;
  cursor: pointer;
}
.order_view .main_section_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  overflow-x: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.order_view .main_section_container::-webkit-scrollbar {
  display: none;
}
.order_view .main_section_container .main_container {
  display: flex;
  flex-direction: column;
  width: 1000px;
  padding-top: 30px;
  align-items: center;
}
.order_view .main_section_container .main_container .form_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 5px;
  padding: 0px 30px;
  margin-bottom: 30px;
}
.order_view .main_section_container .main_container .form_container .form_section_contianer {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.order_view .main_section_container .main_container .form_container .form_section_contianer .headline {
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
}
.order_view .main_section_container .main_container .form_container .form_section_contianer .subheadline {
  font-weight: 500;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.order_view .main_section_container .main_container .form_container .form_section_contianer .title {
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 20px;
}
.order_view .main_section_container .main_container .form_container .form_section_contianer .order_confirmation_container {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  line-height: 1.2;
  font-size: 14px;
  padding: 15px;
  background-color: #ffffe0;
  border-radius: 3px;
}
.order_view .main_section_container .main_container .form_container .form_section_contianer .order_confirmation_container .order_confirmation_textbox {
  margin-right: 10px;
}
.order_view .main_section_container .main_container .form_container .form_section_contianer .contact_info_container {
  display: grid;
  grid-template-columns: 50% 50%;
  margin: 10px 0px;
}
.order_view .main_section_container .main_container .form_container .form_section_contianer .contact_info_container .title {
  grid-column: 1/3;
  grid-row: 1/1;
  font-size: 25px;
  margin-bottom: 10px;
  font-weight: normal;
  margin-bottom: 15px;
}
.order_view .main_section_container .main_container .form_container .form_section_contianer .contact_info_container .input_container {
  margin-right: 30px;
  grid-row: 2/2;
}
.order_view .main_section_container .main_container .form_container .form_section_contianer .contact_info_container .input_container input {
  border: none;
  border: 1px solid #eee;
  padding: 10px;
  width: 100%;
}
.order_view .main_section_container .main_container .form_container .form_section_contianer .payment_info_container {
  display: flex;
  flex-direction: column;
  background-color: #eff8fe;
  padding: 20px;
  padding-bottom: 40px;
  border-radius: 5px;
  margin-top: 30px;
}
.order_view .main_section_container .main_container .form_container .form_section_contianer .payment_info_container .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.order_view .main_section_container .main_container .form_container .form_section_contianer .payment_info_container .header .title {
  font-size: 25px;
}
.order_view .main_section_container .main_container .form_container .form_section_contianer .payment_info_container input {
  border: none;
  border: 1px solid #eee;
  padding: 10px;
  width: 100%;
}
.order_view .main_section_container .main_container .form_container .form_section_contianer .payment_info_container .credit_card_logos {
  margin-bottom: 20px;
}
.order_view .main_section_container .main_container .form_container .form_section_contianer .payment_info_container .card_details_container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-top: 20px;
}
.order_view .main_section_container .main_container .form_container .form_section_contianer .payment_info_container .card_details_container .detail_container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.order_view .main_section_container .main_container .form_container .form_section_contianer .payment_info_container .card {
  background-color: #fff;
  border-radius: 5px;
  padding: 0px 10px;
}
.order_view .main_section_container .main_container .form_container .form_section_contianer .payment_info_container .ant-select-selector {
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.order_view .main_section_container .main_container .form_container .form_section_contianer .order_summary_container {
  margin-top: 40px;
}
.order_view .main_section_container .main_container .form_container .form_section_contianer .order_summary_container .text {
  font-weight: normal;
  margin-bottom: 15px;
  font-size: 25px;
}
.order_view .main_section_container .main_container .form_container .form_section_contianer .order_summary_container .order_details_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 20px;
}
.order_view .main_section_container .main_container .form_container .form_section_contianer .order_summary_container .order_details_container .label {
  font-weight: bold;
}
.order_view .main_section_container .main_container .form_container .form_section_contianer .cta_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 40px;
}
.order_view .main_section_container .main_container .form_container .form_section_contianer .cta_container .button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 500px;
  height: 50px;
  border-radius: 3px;
  color: #fff;
  font-weight: bold;
  border: none;
}

.recurly-element-card {
  height: 50px;
}

.ant-checkbox-inner {
  width: 20px;
  height: 20px;
}

.open_left_nav {
  display: flex;
  flex-direction: column;
  width: 200px;
  height: 100%;
  color: #fff;
  justify-content: space-between;
  border: none;
  border-right: 2px solid #eee;
}
.open_left_nav .header_nav .nav_item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  cursor: pointer;
  padding: 10px 17px;
  color: #666;
  border-bottom: 1px solid #eee;
}
.open_left_nav .header_nav .nav_item:first-of-type {
  border-top: 1px solid #eee;
}
.open_left_nav .header_nav .nav_item .icon {
  font-size: 1em;
  margin-right: 15px;
}
.open_left_nav .header_nav .nav_item .text {
  font-size: 12px;
  line-height: 1.3;
}
.open_left_nav .footer_nav {
  position: relative;
}
.open_left_nav .footer_nav .nav_item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  cursor: pointer;
  padding: 10px 17px;
  color: #666;
  border-bottom: 1px solid #eee;
  border-top: 1px solid #eee;
}
.open_left_nav .footer_nav .nav_item .icon {
  font-size: 1em;
  margin-right: 15px;
}
.open_left_nav .footer_nav .nav_item .text {
  font-size: 12px;
}
.open_left_nav .footer_nav .settings_select {
  position: absolute;
  background-color: #fff;
  width: 185px;
  bottom: 70px;
  left: 15px;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  z-index: 2147483647;
}
.open_left_nav .footer_nav .settings_select .close {
  color: #fff;
  right: 0px;
  position: absolute;
  font-size: 10px;
  background-color: #d6d6d6;
  border-radius: 3px;
  top: 5px;
  right: 5px;
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.open_left_nav .footer_nav .settings_select .setting_nav_item {
  color: #474747;
  font-size: 24px;
  font-weight: lighter;
  display: flex;
  flex-direction: row;
  font-size: 12px;
  height: 30px;
  align-items: center;
  padding-left: 10px;
  cursor: pointer;
}
.open_left_nav .footer_nav .settings_select .setting_nav_item .icon {
  margin-right: 10px;
}
.open_left_nav .footer_nav .settings_select .setting_nav_item:hover {
  background-color: #eeeeee;
}
.open_left_nav .footer_nav .settings_select .setting_nav_item:last-child {
  border-bottom-left-radius: 3px;
}
.open_left_nav .footer_nav .settings_select .setting_nav_item:first-child {
  border-top-left-radius: 3px;
}
.open_left_nav .footer_nav .settings_nav_item {
  display: grid;
  grid-template-columns: 150px auto;
  grid-template-rows: auto;
  font-size: 10px;
}
.open_left_nav .footer_nav .settings_nav_item .left {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 15px;
}
.open_left_nav .footer_nav .settings_nav_item .left .avatar {
  margin-right: 10px;
}
.open_left_nav .footer_nav .settings_nav_item .right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  padding-right: 15px;
}
.open_left_nav .footer_nav .settings_nav_item .right .icon {
  font-size: 16px;
  cursor: pointer;
}
.open_left_nav .footer_nav .retract_item {
  justify-content: end;
  padding-right: 0px;
}

.account_status_container {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  padding: 5px;
  color: #000;
}
.account_status_container .content_container {
  border: 1px solid #eee;
}
.account_status_container .content_container .account_status_header {
  display: flex;
  flex-direction: column;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #eee;
  font-weight: bold;
  background-color: #fffacd;
}
.account_status_container .content_container .status_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  border-bottom: 1px solid #eee;
  line-height: 1.3;
}
.account_status_container .content_container .status_container .icon {
  width: 30px;
  padding-left: 5px;
}
.account_status_container .content_container .status_container .label {
  width: 120px;
}
.account_status_container .content_container .schedule_a_call_container {
  margin: 10px 0px;
  padding: 5px;
}
.account_status_container .content_container .schedule_a_call_container div {
  margin-bottom: 10px;
}
.account_status_container .content_container .schedule_a_call_container a {
  display: flex;
  flex-direction: column;
  width: calc(100% - 10px);
  height: 30px;
  background-color: #57e480;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  margin-left: 5px;
}

.visualizer_search_view {
  display: flex;
  margin-top: 300px;
  justify-content: center;
}
.visualizer_search_view .search_container .header {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.visualizer_search_view .search_container .input {
  width: 500px;
}

.visualizer_view {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.visualizer_view .page_view_iframe {
  position: absolute;
  right: 0px;
  width: calc(100% - 200px);
  height: 100%;
  border: none;
}

.analysis_container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.analysis_container .query_builder_container {
  display: flex;
  flex-direction: column;
}
.analysis_container .query_builder_container .conditions_container {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #f5f5f5;
  padding: 10px 0px 5px 10px;
}
.analysis_container .query_builder_container .conditions_container .event_type_container {
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.analysis_container .query_builder_container .conditions_container .event_type_container .select {
  width: 150px;
  margin-right: 10px;
}
.analysis_container .query_builder_container .conditions_container .event_type_container .select .ant-select {
  width: 100%;
}
.analysis_container .query_builder_container .conditions_container .event_type_container .remove {
  cursor: pointer;
}
.analysis_container .query_builder_container .conditions_container .condition_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 0px;
}
.analysis_container .query_builder_container .conditions_container .condition_container .filter {
  width: 150px;
  margin-right: 10px;
}
.analysis_container .query_builder_container .conditions_container .condition_container .filter .ant-select {
  width: 100%;
}
.analysis_container .query_builder_container .conditions_container .condition_container .condition {
  width: 150px;
  margin-right: 10px;
}
.analysis_container .query_builder_container .conditions_container .condition_container .condition .ant-select {
  width: 100%;
}
.analysis_container .query_builder_container .conditions_container .condition_container .value {
  width: 300px;
  margin-right: 10px;
}
.analysis_container .query_builder_container .conditions_container .condition_container .value .ant-select {
  width: 100%;
}
.analysis_container .query_builder_container .conditions_container .condition_container .add {
  width: 30px;
  cursor: pointer;
}
.analysis_container .query_builder_container .conditions_container .condition_container .remove {
  width: 30px;
  cursor: pointer;
}
.analysis_container .add_step {
  font-size: 12px;
  padding: 10px 10px;
  border-bottom: 2px solid #f5f5f5;
  color: #9e9e9e;
  cursor: pointer;
}

.rule_container .trigger_container, .rule_view .column_container .left .rule_settings .rule_container .trigger_container, .filters_actions_container .trigger_container, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .trigger_container {
  width: 375px;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  border: 1px solid #eee;
  border-radius: 3px;
  padding: 10px;
  background-color: #fff;
}
.rule_container .trigger_container .header_container, .rule_view .column_container .left .rule_settings .rule_container .trigger_container .header_container, .filters_actions_container .trigger_container .header_container, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .trigger_container .header_container {
  display: grid;
  grid-template-columns: 100px auto;
  margin-bottom: 15px;
  margin-top: 5px;
}
.rule_container .trigger_container .header_container .title_text, .rule_view .column_container .left .rule_settings .rule_container .trigger_container .header_container .title_text, .filters_actions_container .trigger_container .header_container .title_text, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .trigger_container .header_container .title_text {
  grid-column: 1/1;
  font-weight: bold;
  padding-left: 2px;
}
.rule_container .trigger_container .header_container .scopes_container, .rule_view .column_container .left .rule_settings .rule_container .trigger_container .header_container .scopes_container, .filters_actions_container .trigger_container .header_container .scopes_container, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .trigger_container .header_container .scopes_container {
  grid-column: 2/2;
  display: flex;
  flex-direction: row;
  justify-content: end;
}
.rule_container .trigger_container .header_container .scopes_container .metric_scope, .filters_actions_container .trigger_container .header_container .scopes_container .metric_scope, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .trigger_container .header_container .scopes_container .metric_scope {
  margin-right: 10px;
  display: flex;
  cursor: pointer;
}
.rule_container .trigger_container .search_bar, .rule_view .column_container .left .rule_settings .rule_container .trigger_container .search_bar, .filters_actions_container .trigger_container .search_bar, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .trigger_container .search_bar {
  width: calc(100% - 10px);
}
.rule_container .trigger_container .categories, .rule_view .column_container .left .rule_settings .rule_container .trigger_container .categories, .filters_actions_container .trigger_container .categories, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .trigger_container .categories {
  margin-top: 10px;
  height: 300px;
  overflow: scroll;
}
.rule_container .trigger_container .categories .categories_dropdown_container, .rule_view .column_container .left .rule_settings .rule_container .trigger_container .categories .categories_dropdown_container, .filters_actions_container .trigger_container .categories .categories_dropdown_container, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .trigger_container .categories .categories_dropdown_container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.rule_container .trigger_container .categories .categories_dropdown_container .category_container, .filters_actions_container .trigger_container .categories .categories_dropdown_container .category_container, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .trigger_container .categories .categories_dropdown_container .category_container {
  width: 100%;
  border-bottom: none;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
}
.rule_container .trigger_container .categories .categories_dropdown_container .category_container .accordion__button, .filters_actions_container .trigger_container .categories .categories_dropdown_container .category_container .accordion__button, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .trigger_container .categories .categories_dropdown_container .category_container .accordion__button {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  width: 100%;
  padding-left: 10px;
}
.rule_container .trigger_container .categories .categories_dropdown_container .metric_field, .filters_actions_container .trigger_container .categories .categories_dropdown_container .metric_field, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .trigger_container .categories .categories_dropdown_container .metric_field {
  padding: 10px 0px;
  padding-left: 30px;
  cursor: pointer;
}
.rule_container .trigger_container .categories .categories_dropdown_container .metric_field:hover, .filters_actions_container .trigger_container .categories .categories_dropdown_container .metric_field:hover, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .trigger_container .categories .categories_dropdown_container .metric_field:hover {
  background-color: #eee;
}
.rule_container .timeframe_container, .rule_view .column_container .left .rule_settings .rule_container .timeframe_container, .filters_actions_container .timeframe_container, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .timeframe_container {
  width: 300px;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  border: 1px solid #eee;
  border-radius: 3px;
  padding: 10px;
  background-color: #fff;
}
.rule_container .timeframe_container .search_bar, .rule_view .column_container .left .rule_settings .rule_container .timeframe_container .search_bar, .filters_actions_container .timeframe_container .search_bar, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .timeframe_container .search_bar {
  width: calc(100% - 10px);
}
.rule_container .timeframe_container .categories, .rule_view .column_container .left .rule_settings .rule_container .timeframe_container .categories, .filters_actions_container .timeframe_container .categories, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .timeframe_container .categories {
  margin-top: 10px;
  height: 200px;
  overflow: scroll;
}
.rule_container .timeframe_container .categories .categories_dropdown_container, .rule_view .column_container .left .rule_settings .rule_container .timeframe_container .categories .categories_dropdown_container, .filters_actions_container .timeframe_container .categories .categories_dropdown_container, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .timeframe_container .categories .categories_dropdown_container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.rule_container .timeframe_container .categories .categories_dropdown_container .category_container, .filters_actions_container .timeframe_container .categories .categories_dropdown_container .category_container, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .timeframe_container .categories .categories_dropdown_container .category_container {
  width: 100%;
  border-bottom: none;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
}
.rule_container .timeframe_container .categories .categories_dropdown_container .category_container .accordion__button, .filters_actions_container .timeframe_container .categories .categories_dropdown_container .category_container .accordion__button, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .timeframe_container .categories .categories_dropdown_container .category_container .accordion__button {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  width: 100%;
  padding-left: 10px;
}
.rule_container .timeframe_container .categories .categories_dropdown_container .metric_field, .filters_actions_container .timeframe_container .categories .categories_dropdown_container .metric_field, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .timeframe_container .categories .categories_dropdown_container .metric_field {
  padding: 10px 0px;
  padding-left: 30px;
  cursor: pointer;
}
.rule_container .timeframe_container .categories .categories_dropdown_container .metric_field:hover, .filters_actions_container .timeframe_container .categories .categories_dropdown_container .metric_field:hover, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .timeframe_container .categories .categories_dropdown_container .metric_field:hover {
  background-color: #eee;
}
.rule_container .predicate_container, .rule_view .column_container .left .rule_settings .rule_container .predicate_container, .filters_actions_container .predicate_container, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .predicate_container {
  width: 230px;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  border: 1px solid #eee;
  border-radius: 3px;
  background-color: #fff;
}
.rule_container .predicate_container .metric_field, .rule_view .column_container .left .rule_settings .rule_container .predicate_container .metric_field, .filters_actions_container .predicate_container .metric_field, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .predicate_container .metric_field {
  padding: 10px 10px;
  cursor: pointer;
}
.rule_container .predicate_container .metric_field:hover, .rule_view .column_container .left .rule_settings .rule_container .predicate_container .metric_field:hover, .filters_actions_container .predicate_container .metric_field:hover, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .predicate_container .metric_field:hover {
  background-color: #eee;
}
.rule_container .conditions_container, .rule_view .column_container .left .rule_settings .rule_container .conditions_container, .filters_actions_container .conditions_container, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container {
  display: grid !important;
  border-radius: 5px;
}
.rule_container .conditions_container .expressions_operand_bar, .rule_view .column_container .left .rule_settings .rule_container .conditions_container .expressions_operand_bar, .filters_actions_container .conditions_container .expressions_operand_bar, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .expressions_operand_bar {
  grid-column: 1/1;
  height: calc(100% - 67px);
  padding-top: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.rule_container .conditions_container .expressions_operand_bar .background, .rule_view .column_container .left .rule_settings .rule_container .conditions_container .expressions_operand_bar .background, .filters_actions_container .conditions_container .expressions_operand_bar .background, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .expressions_operand_bar .background {
  border-radius: 3px;
  height: 100%;
  width: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #e3e3e3;
}
.rule_container .conditions_container .expressions_operand_bar .background .text, .filters_actions_container .conditions_container .expressions_operand_bar .background .text, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .expressions_operand_bar .background .text {
  transform: rotate(-90deg);
  color: #fff;
  font-weight: lighter;
  font-size: 12px;
}
.rule_container .conditions_container .conditions_grid, .rule_view .column_container .left .rule_settings .rule_container .conditions_container .conditions_grid, .filters_actions_container .conditions_container .conditions_grid, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid {
  font-size: 14px;
}
.rule_container .conditions_container .conditions_grid .expressions_container, .rule_view .column_container .left .rule_settings .rule_container .conditions_container .conditions_grid .expressions_container, .filters_actions_container .conditions_container .conditions_grid .expressions_container, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container {
  display: grid;
  grid-template-rows: auto;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_operand_bar, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_operand_bar, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_operand_bar {
  grid-column: 1/1;
  height: calc(100% - 55px);
  padding-top: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_operand_bar .background, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_operand_bar .background, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_operand_bar .background {
  border-radius: 3px;
  height: 100%;
  width: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #e3e3e3;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_operand_bar .background .text, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_operand_bar .background .text, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_operand_bar .background .text {
  margin-left: -4px;
  transform: rotate(-90deg);
  color: #fff;
  font-weight: lighter;
  font-size: 12px;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr)) 50px;
  margin: 5px 0px;
  font-size: 12px;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_metric, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_metric, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_metric {
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  margin: 0px 10px 0px 0px;
  border: 1px solid #eee;
  border-radius: 3px;
  background-color: #fff;
  position: relative;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_metric .select, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_metric .select, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_metric .select {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 30px;
  align-items: center;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_metric .select .icon, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_metric .select .icon, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_metric .select .icon {
  font-size: 12px;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_metric .dropdown, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_metric .dropdown, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_metric .dropdown {
  position: absolute;
  z-index: 99999;
  top: 35px;
  left: 0px;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_timeframe, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_timeframe, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_timeframe {
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  margin: 0px 10px 0px 0px;
  border: 1px solid #eee;
  border-radius: 3px;
  background-color: #fff;
  position: relative;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_timeframe .select, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_timeframe .select, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_timeframe .select {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 30px;
  align-items: center;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_timeframe .select .icon, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_timeframe .select .icon, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_timeframe .select .icon {
  font-size: 12px;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_timeframe .dropdown, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_timeframe .dropdown, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_timeframe .dropdown {
  position: absolute;
  z-index: 99999;
  top: 35px;
  left: 0px;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_predicate, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_predicate, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_predicate {
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  margin: 0px 10px 0px 0px;
  border: 1px solid #eee;
  border-radius: 3px;
  background-color: #fff;
  position: relative;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_predicate .select, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_predicate .select, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_predicate .select {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 30px;
  align-items: center;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_predicate .select .icon, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_predicate .select .icon, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_predicate .select .icon {
  font-size: 12px;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_predicate .dropdown, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_predicate .dropdown, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_predicate .dropdown {
  position: absolute;
  z-index: 99999;
  top: 35px;
  left: 0px;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_value, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_value, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_value {
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  margin: 0px 10px 0px 0px;
  border: 1px solid #eee;
  border-radius: 3px;
  background-color: #fff;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_value input, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_value input, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_value input {
  border: none;
  height: 30px;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_value input:focus, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_value input:focus, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_value input:focus {
  outline: none;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_actions, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_actions, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_actions {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_actions .delete, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_actions .delete, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_actions .delete {
  cursor: pointer;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr)) 50px;
  margin: 5px 0px;
  font-size: 12px;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_metric, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_metric, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_metric {
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  margin: 0px 10px 0px 0px;
  border: 1px solid #eee;
  border-radius: 3px;
  background-color: #fff;
  position: relative;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_metric .select, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_metric .select, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_metric .select {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 30px;
  align-items: center;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_metric .select .icon, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_metric .select .icon, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_metric .select .icon {
  font-size: 12px;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_metric .dropdown, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_metric .dropdown, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_metric .dropdown {
  position: absolute;
  z-index: 99999;
  top: 35px;
  left: 0px;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_timeframe, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_timeframe, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_timeframe {
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  margin: 0px 10px 0px 0px;
  border: 1px solid #eee;
  border-radius: 3px;
  background-color: #fff;
  position: relative;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_timeframe .select, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_timeframe .select, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_timeframe .select {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 30px;
  align-items: center;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_timeframe .select .icon, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_timeframe .select .icon, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_timeframe .select .icon {
  font-size: 12px;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_timeframe .dropdown, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_timeframe .dropdown, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_timeframe .dropdown {
  position: absolute;
  z-index: 99999;
  top: 35px;
  left: 0px;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_predicate, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_predicate, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_predicate {
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  margin: 0px 10px 0px 0px;
  border: 1px solid #eee;
  border-radius: 3px;
  background-color: #fff;
  position: relative;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_predicate .select, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_predicate .select, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_predicate .select {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 30px;
  align-items: center;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_predicate .select .icon, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_predicate .select .icon, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_predicate .select .icon {
  font-size: 12px;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_predicate .dropdown, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_predicate .dropdown, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_predicate .dropdown {
  position: absolute;
  z-index: 99999;
  top: 35px;
  left: 0px;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_value, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_value, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_value {
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  margin: 0px 10px 0px 0px;
  border: 1px solid #eee;
  border-radius: 3px;
  background-color: #fff;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_value input, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_value input, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_value input {
  border: none;
  height: 30px;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_value input:focus, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_value input:focus, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_value input:focus {
  outline: none;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_actions, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_actions, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_actions {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_actions .delete, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_actions .delete, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_actions .delete {
  cursor: pointer;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .group_actions_container, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .group_actions_container, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .group_actions_container {
  margin: 5px 0px;
}
.rule_container .conditions_container .conditions_grid .conditions_actions_container .add_or_condition, .filters_actions_container .conditions_container .conditions_grid .conditions_actions_container .add_or_condition, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .conditions_actions_container .add_or_condition {
  font-size: 12px;
}
.rule_container .conditions_container .conditions_grid .conditions_actions_container .add_or_condition .button, .filters_actions_container .conditions_container .conditions_grid .conditions_actions_container .add_or_condition .button, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .conditions_actions_container .add_or_condition .button {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  margin-left: 3px;
  width: 100px;
}
.rule_container .conditions_container .conditions_grid .conditions_actions_container .add_or_condition .button .icon, .filters_actions_container .conditions_container .conditions_grid .conditions_actions_container .add_or_condition .button .icon, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .conditions_actions_container .add_or_condition .button .icon {
  margin-right: 10px;
}
.rule_container .conditions_container .conditions_grid .conditions_actions_container .add_or_condition .button .text, .filters_actions_container .conditions_container .conditions_grid .conditions_actions_container .add_or_condition .button .text, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .conditions_actions_container .add_or_condition .button .text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.add_filter_button {
  font-size: 12px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  margin-left: 3px;
  width: 100%;
  height: 70px;
  align-items: center;
  justify-content: center;
}
.add_filter_button .icon {
  margin-right: 10px;
}
.add_filter_button .text,
.add_filter_button .icon {
  margin-top: -5px;
}

.ads_manager_view {
  width: 100%;
  padding: 20px;
  overflow-y: scroll;
}
.ads_manager_view .ads_manager_top_toolbar {
  display: grid;
  grid-template-columns: auto 500px;
  grid-template-rows: 50px;
  margin-top: 10px;
}
.ads_manager_view .ads_manager_top_toolbar .table_actions_container {
  width: 350px;
  height: 32px;
  z-index: 3;
}
.ads_manager_view .ads_manager_top_toolbar .table_actions_container .actions_container {
  height: 32px;
  border-radius: 3px;
  border: 1px solid #d9d9d9;
  display: grid;
  grid-template-columns: auto auto auto auto;
  margin-bottom: 5px;
}
.ads_manager_view .ads_manager_top_toolbar .table_actions_container .actions_container .action {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 12px;
}
.ads_manager_view .ads_manager_top_toolbar .table_actions_container .actions_container .action .icon {
  margin-right: 10px;
}
.ads_manager_view .ads_manager_top_toolbar .left_adjusted {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  grid-column: 1/1;
}
.ads_manager_view .ads_manager_top_toolbar .left_adjusted .adsmanger_view_switch_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: -20px;
  font-size: 12px;
}
.ads_manager_view .ads_manager_top_toolbar .left_adjusted .adsmanger_view_switch_container .switch {
  margin-right: 10px;
}
.ads_manager_view .ads_manager_top_toolbar .right_adjusted {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  grid-column: 2/2;
}
.ads_manager_view .ads_manager_top_toolbar .right_adjusted .date_select_container {
  display: flex;
  flex-direction: column;
  height: 50px;
  align-items: flex-end;
}
.ads_manager_view .ads_manager_top_toolbar .right_adjusted .conversion_window_container {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  margin-right: 10px;
  margin-top: -17px;
}
.ads_manager_view .ads_manager_top_toolbar .right_adjusted .conversion_window_container .label {
  display: flex;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 5px;
}
.ads_manager_view .ads_manager_top_toolbar .right_adjusted .conversion_window_container .input .ant-input-number {
  width: 110px;
}
.ads_manager_view .ads_manager_top_toolbar .right_adjusted .conversion_window_container .input input {
  height: 30px;
}
.ads_manager_view .ads_manager_top_toolbar .right_adjusted .attribution_click_setting_container {
  font-size: 10px;
  display: flex;
  flex-direction: column;
  width: 100px;
  margin-right: 10px;
  margin-top: -3px;
}
.ads_manager_view .ads_manager_top_toolbar .right_adjusted .attribution_click_setting_container .attribution_click_setting_row_container {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
}
.ads_manager_view .ads_manager_top_toolbar .right_adjusted .attribution_click_setting_container .attribution_click_setting_row_container .radio {
  margin-right: 10px;
}
.ads_manager_view .ads_manager_top_toolbar .right_adjusted .attribution_click_setting_container .attribution_click_setting_row_container .radio input {
  cursor: pointer;
}
.ads_manager_view .ads_manager_top_toolbar .right_adjusted .table_settings span {
  margin-right: 5px;
}
.ads_manager_view .ads_manager_container {
  min-height: 500px;
  width: 100%;
  font-size: 14px;
  border: 1px solid #eee;
  height: calc(100% - 60px);
  overflow: hidden;
  padding-bottom: 50px;
}
.ads_manager_view .ads_manager_container .tabs {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-rows: 50px;
  position: relative;
}
.ads_manager_view .ads_manager_container .tabs .tab {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #eee;
  border-top: none;
  cursor: pointer;
}
.ads_manager_view .ads_manager_container .tabs .tab .text {
  position: absolute;
  top: 20px;
}
.ads_manager_view .ads_manager_container .tabs .tab:first-child {
  border-right: none;
  border-left: none;
}
.ads_manager_view .ads_manager_container .tabs .tab:last-child {
  border-left: none;
  border-right: none;
}
.ads_manager_view .ads_manager_container .filters_container {
  height: 40px;
  border-bottom: 1px solid #eee;
  display: grid;
  grid-template-columns: 40px auto 30px;
  grid-template-rows: auto;
  color: #303030;
  position: relative;
}
.ads_manager_view .ads_manager_container .filters_container .icon {
  grid-column: 1/1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}
.ads_manager_view .ads_manager_container .filters_container .filters_content_container {
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.ads_manager_view .ads_manager_container .filters_container .filters_content_container::-webkit-scrollbar {
  display: none;
}
.ads_manager_view .ads_manager_container .filters_container .filters_content_container .filters {
  grid-column: 2/2;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  font-weight: lighter;
  width: 100%;
}
.ads_manager_view .ads_manager_container .filters_container .filters_content_container .filters .filter {
  color: #fff;
  background-color: #2196f3;
  border-radius: 50px;
  cursor: pointer;
  margin-right: 10px;
  display: grid;
  grid-template-columns: auto 1px 25px;
  height: 25px;
  align-items: center;
  padding-left: 10px;
}
.ads_manager_view .ads_manager_container .filters_container .filters_content_container .filters .filter .text {
  grid-column: 1/1;
  margin-right: 10px;
}
.ads_manager_view .ads_manager_container .filters_container .filters_content_container .filters .filter .line_break {
  grid-column: 2/2;
  height: 25px;
  background-color: #1e88e5;
}
.ads_manager_view .ads_manager_container .filters_container .filters_content_container .filters .filter .delete {
  grid-column: 3/3;
  margin-top: 1px;
  margin-left: 3px;
}
.ads_manager_view .ads_manager_container .filters_container .create_rule {
  grid-column: 3/3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  font-size: 20px;
  cursor: pointer;
  color: #303030;
}
.ads_manager_view .ads_manager_container .filters_container .conditions_dropdown {
  position: absolute;
  background-color: #fff;
  left: 40px;
  top: 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 5px;
  z-index: 2147483647;
}
.ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_header_container {
  border-bottom: 1px solid #eee;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_header_container .header {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 25px;
  font-weight: bold;
}
.ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_header_container .close {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 10px;
  cursor: pointer;
  font-weight: bold;
}
.ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container {
  padding: 10px;
}
.ads_manager_view .ads_manager_container .data_content_container {
  width: 100%;
  height: 100%;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.ads_manager_view .ads_manager_container .data_content_container::-webkit-scrollbar {
  display: none;
}
.ads_manager_view .ads_manager_container .data_content_container .table_actions {
  display: flex;
  flex-direction: row;
  height: 45px;
  border-bottom: 1px solid #eee;
  width: 100%;
  align-items: center;
  position: relative;
  z-index: 3;
  justify-content: flex-start;
}
.ads_manager_view .ads_manager_container .data_content_container .table_actions .pagination {
  display: flex;
  flex-direction: row;
  width: 80px;
  height: 100%;
  margin-left: auto;
  font-size: 16px;
  color: #ccc;
  padding: 10px;
}
.ads_manager_view .ads_manager_container .data_content_container .table_actions .pagination .prev {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid #eee;
  border-radius: 3px;
  margin-right: 10px;
}
.ads_manager_view .ads_manager_container .data_content_container .table_actions .pagination .next {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid #eee;
  border-radius: 3px;
}
.ads_manager_view .ads_manager_container .data_content_container .table_actions .create_asset_container {
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  background-color: #1e88e5;
  color: #fff;
  height: 25px;
  width: 90px;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  cursor: pointer;
  font-size: 12px;
}
.ads_manager_view .ads_manager_container .data_content_container .table_actions .create_asset_container .icon {
  margin-right: 10px;
}
.ads_manager_view .ads_manager_container .data_content_container .table_actions .action {
  width: 90px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
  font-size: 12px;
}
.ads_manager_view .ads_manager_container .data_content_container .table_actions .action .icon {
  margin-right: 10px;
}
.ads_manager_view .ads_manager_container .data_content_container .stats {
  border-bottom: 1px solid #eee;
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
}
.ads_manager_view .ads_manager_container .data_content_container .stats .stat {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 90px;
  border-right: 1px solid #eee;
  padding-left: 20px;
}
.ads_manager_view .ads_manager_container .data_content_container .stats .stat .title {
  font-size: 12px;
}
.ads_manager_view .ads_manager_container .data_content_container .stats .stat .value {
  font-size: 35px;
}
.ads_manager_view .ads_manager_container .data_content_container .stats .stat:first-child {
  border-left: none;
}
.ads_manager_view .ads_manager_container .data_content_container .stats .stat:last-child {
  border-right: none;
}
.ads_manager_view .ads_manager_container .data_content_container .table-wrapper {
  background: #fff;
  overflow: scroll;
}
.ads_manager_view .ads_manager_container .data_content_container table {
  width: 100%;
  overflow: auto;
  display: grid;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.ads_manager_view .ads_manager_container .data_content_container table::-webkit-scrollbar {
  display: none;
}
.ads_manager_view .ads_manager_container .data_content_container table thead tr th {
  border-bottom: 1px solid #eee;
}
.ads_manager_view .ads_manager_container .data_content_container table thead tr th .sort {
  font-size: 12px;
  cursor: pointer;
}
.ads_manager_view .ads_manager_container .data_content_container table thead tr th .header_content {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
}
.ads_manager_view .ads_manager_container .data_content_container table thead tr th .header_content .sort {
  margin-top: -5px;
}
.ads_manager_view .ads_manager_container .data_content_container table thead tr th .header_content .sortasc {
  height: 7px;
}
.ads_manager_view .ads_manager_container .data_content_container table thead tr th .header_content .sortdesc {
  height: 7px;
}
.ads_manager_view .ads_manager_container .data_content_container table thead tr th:nth-child(1) .header_content {
  justify-content: center;
}
.ads_manager_view .ads_manager_container .data_content_container table thead tr th:nth-child(1) .header_content input {
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.ads_manager_view .ads_manager_container .data_content_container table thead tr th:nth-child(2) .header_content {
  justify-content: center;
}
.ads_manager_view .ads_manager_container .data_content_container table thead tr .name .header_content {
  flex-direction: row-reverse;
}
.ads_manager_view .ads_manager_container .data_content_container table thead,
.ads_manager_view .ads_manager_container .data_content_container table tbody,
.ads_manager_view .ads_manager_container .data_content_container table tr {
  display: contents;
}
.ads_manager_view .ads_manager_container .data_content_container table th {
  position: relative;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  height: 40px;
  align-items: flex-end;
  justify-content: center;
  padding: 0px 10px;
  font-size: 12px;
  font-weight: bold;
}
.ads_manager_view .ads_manager_container .data_content_container table .name {
  align-items: flex-start;
  color: #2196f3;
  font-weight: bold;
}
.ads_manager_view .ads_manager_container .data_content_container tbody .name span {
  cursor: pointer;
}
.ads_manager_view .ads_manager_container .data_content_container table th span,
.ads_manager_view .ads_manager_container .data_content_container table td span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}
.ads_manager_view .ads_manager_container .data_content_container table td {
  position: relative;
}
.ads_manager_view .ads_manager_container .data_content_container table td .ad_stats {
  position: absolute;
  left: 0;
  background-color: #eee;
  padding: 3px;
  border-bottom-right-radius: 3px;
  font-size: 12px;
}
.ads_manager_view .ads_manager_container .data_content_container table td span {
  display: flex;
  flex-direction: column;
  height: 40px;
  align-items: flex-end;
  justify-content: center;
  padding: 0px 10px;
  font-size: 12px;
}
.ads_manager_view .ads_manager_container .data_content_container table .name span {
  align-items: flex-start;
}
.ads_manager_view .ads_manager_container .data_content_container table tr td {
  border-top: 1px solid #eee;
}
.ads_manager_view .ads_manager_container .data_content_container table > tbody > tr:last-child td {
  border-bottom: 1px solid #eee;
}
.ads_manager_view .ads_manager_container .data_content_container .resize-handle {
  display: block;
  position: absolute;
  cursor: col-resize;
  width: 1px;
  right: 0;
  top: 0;
  z-index: 0;
  border-right: 1px solid #eee;
  z-index: 2;
}
.ads_manager_view .ads_manager_container .data_content_container .resize-handle:hover {
  border-color: #2196f3;
}
.ads_manager_view .ads_manager_container .data_content_container .resize-handle.active {
  border-color: #2196f3;
}
.ads_manager_view .ads_manager_container .data_content_container tbody tr .checkbox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ads_manager_view .ads_manager_container .data_content_container tbody tr .checkbox span input {
  cursor: pointer;
  width: 18px;
  height: 18px;
}
.ads_manager_view .ads_manager_container .data_content_container tbody tr .actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.ads_manager_view .ads_manager_container .data_content_container .table {
  overflow-x: scroll;
  min-height: 500px;
}
.ads_manager_view .ads_manager_container .data_content_container .table .table_row {
  display: flex;
  flex-direction: row;
}
.ads_manager_view .ads_manager_container .data_content_container .table .table_row .table_cell {
  height: 40px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
  padding: 0px 10px;
  border: 1px solid #eee;
  border-top: none;
  border-right: none;
}
.ads_manager_view .ads_manager_container .data_content_container .table .table_row .table_name {
  align-items: start;
}
.ads_manager_view .ads_manager_container .data_content_container .table .table_row .table_column {
  display: flex;
  flex-direction: column;
  border: 1px solid #eee;
  border-top: none;
  border-right: none;
}
.ads_manager_view .ads_manager_container .data_content_container .table .table_row .table_column .header_cell {
  display: grid;
  grid-template-columns: auto 5px;
  height: 100%;
  position: relative;
}
.ads_manager_view .ads_manager_container .data_content_container .table .table_row .table_column .header_cell .cell {
  grid-column: 1/1;
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
}
.ads_manager_view .ads_manager_container .data_content_container .table .table_row .table_column .header_cell .cell .sort {
  cursor: pointer;
}
.ads_manager_view .ads_manager_container .data_content_container .table .table_row .table_column .header_cell .drag_handle {
  grid-column: 2/2;
  background-color: #1e88e5;
  cursor: col-resize;
}
.ads_manager_view .ads_manager_container .data_content_container .table .table_row .table_column .table_checkbox {
  cursor: pointer;
  width: 16px;
  height: 16px;
}
.ads_manager_view .ads_manager_container .data_content_container .table .table_row .table_column:first-child {
  border-left: none;
}
.table_settings_modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 20px;
  position: relative;
}

.table_settings_list_li {
  font-size: 12px;
  display: flex;
  flex-direction: row;
  width: 100%;
  z-index: 3 !important;
  padding: 7px;
  margin: 0.5em 0em;
  list-style-type: none;
  border: 1px solid #ccc;
  color: #333;
  border-radius: 3px;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
}
.table_settings_list_li .row_container {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
}
.table_settings_list_li .row_container .checkbox {
  margin-right: 10px;
}
.table_settings_list_li .row_container .checkbox input {
  cursor: pointer;
}
.table_settings_list_li .row_container .drag_handle {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  margin-left: auto;
  font-weight: bold;
  transform: rotateZ(90deg);
}

.columns_table_container {
  width: 350px;
  display: flex;
  flex-direction: column;
  height: 470px;
  overflow: scroll;
  background: #fff;
  padding: 0px 5px;
  border: 1px solid #eee;
  border-radius: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  position: absolute;
  top: 50px;
  left: 100px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.columns_table_container::-webkit-scrollbar {
  display: none;
}

.filters_actions_container {
  display: flex;
  flex-direction: column;
  width: 500px;
  background: #fff;
  padding: 5px 5px 0px 10px;
  border: 1px solid #eee;
  border-radius: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  position: absolute;
  top: 50px;
  left: 5px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.filters_actions_container::-webkit-scrollbar {
  display: none;
}

.sort_container {
  display: grid;
  grid-template-rows: 30px auto 70px;
  width: 350px;
  height: 470px;
  overflow: hidden;
  background: #fff;
  padding: 0px 5px;
  border: 1px solid #eee;
  border-radius: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  position: absolute;
  top: 50px;
  left: 175px;
}
.sort_container .sort_title_container {
  grid-row: 1/1;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 5px;
  border-bottom: 1px solid #eee;
  font-size: 14px;
  justify-content: space-between;
}
.sort_container .sort_title_container .close_icon {
  margin-right: 5px;
  cursor: pointer;
}
.sort_container .sort_list_container {
  grid-row: 2/2;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  height: 100%;
  border-bottom: 1px solid #eee;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.sort_container .sort_list_container::-webkit-scrollbar {
  display: none;
}
.sort_container .sort_list_container .sort_list_item_container {
  display: flex;
  flex-direction: row;
  min-height: 32px;
  align-items: center;
  padding-left: 5px;
  font-size: 14px;
}
.sort_container .sort_list_container .sort_list_item_container input {
  margin-right: 10px;
  cursor: pointer;
}
.sort_container .sort_order_container {
  grid-row: 3/3;
  display: flex;
  flex-direction: column;
}
.sort_container .sort_order_container .order_row_container {
  display: flex;
  flex-direction: column;
  min-height: 30px;
  justify-content: center;
  font-size: 14px;
  cursor: pointer;
  padding: 10px;
  padding-left: 0px;
}
.sort_container .sort_order_container .order_row_container .order_row_inner_container {
  display: flex;
  flex-direction: row;
  padding: 7px;
  padding-left: 5px;
  border-radius: 3px;
}
.sort_container .sort_order_container .order_row_container .order_row_inner_container:hover {
  background-color: #eee;
}
.sort_container .sort_order_container .order_row_container .order_row_inner_container .icon {
  margin-right: 10px;
}

.column_title_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  min-height: 25px;
  justify-content: space-between;
  padding: 0px 5px;
  margin-top: 2px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}

.product_sales_container {
  display: flex;
  flex-direction: column;
  border: 1px solid #eee;
  margin-bottom: 10px;
  padding: 6px;
  font-size: 12px;
}
.product_sales_container .product_header_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
}
.product_sales_container .product_header_container .title {
  font-weight: bold;
}
.product_sales_container .product_header_container .header_stats {
  display: grid;
  grid-template-columns: 105px auto;
}
.product_sales_container .product_header_container .header_stats .stats_container {
  width: 75px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 20px;
}
.product_sales_container .product_header_container .header_stats .stats_container:last-of-type {
  margin-right: 10px;
}
.product_sales_container .product_header_container .header_stats .stats_container .label {
  font-weight: bold;
}
.product_sales_container .sales_container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}
.product_sales_container .sales_container .sale_row_container {
  display: grid;
  align-items: center;
  grid-template-columns: 300px auto;
  height: 25px;
  padding-left: 5px;
}
.product_sales_container .sales_container .sale_row_container:nth-last-of-type(odd) {
  background-color: #f5f5f5;
}

.ReactModal__Content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #1e88e5;
}
.ReactModal__Content .orders_modal_container {
  padding: 20px;
  overflow: scroll;
  display: flex;
  flex-direction: row;
  min-width: 100%;
  overflow: hidden;
}
.ReactModal__Content .orders_modal_container .orders_modal_content {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: auto;
  padding-right: 20px;
  overflow: scroll;
  padding-bottom: 10px;
  margin-bottom: -20px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.ReactModal__Content .orders_modal_container .orders_modal_content::-webkit-scrollbar {
  display: none;
}
.ReactModal__Content .orders_modal_container .close_orders_modal_container {
  display: flex;
  flex-direction: column;
  width: 50px;
  cursor: pointer;
  font-size: 40px;
  color: #eee;
}

.close_modal_container {
  position: absolute;
  right: 0;
  top: 10px;
  right: 10px;
  font-size: 20px;
  color: #ccc;
  cursor: pointer;
}

.range_picker_actions {
  margin: 10px 0px;
  display: flex;
  flex-direction: row;
  justify-content: end;
}
.range_picker_actions .range_picker_button {
  margin: 0px 5px;
}

.expired_trial_modal_container .content_container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
}
.expired_trial_modal_container .content_container .post_outer_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.expired_trial_modal_container .content_container .post_outer_container .post_container {
  width: 600px;
  height: 300px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid #eee;
  border-radius: 3px;
}
.expired_trial_modal_container .content_container .post_outer_container .post_container .text {
  font-weight: bold;
  font-size: 40px;
  margin-bottom: 20px;
  text-align: center;
}
.expired_trial_modal_container .content_container .post_outer_container .post_container .cta {
  background-color: #eb072a;
  color: #fff;
  height: 50px;
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  cursor: pointer;
}
.expired_trial_modal_container .bg_img_container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
}

.complete_setup_modal .content_container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
}
.complete_setup_modal .content_container .post_outer_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.complete_setup_modal .content_container .post_outer_container .post_container {
  width: 600px;
  height: 300px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid #eee;
  border-radius: 3px;
}
.complete_setup_modal .content_container .post_outer_container .post_container .text {
  font-weight: bold;
  font-size: 40px;
  margin-bottom: 20px;
  text-align: center;
}
.complete_setup_modal .content_container .post_outer_container .post_container .call_to_actions .cta {
  background-color: #1e88e5;
  color: #fff;
  height: 50px;
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  cursor: pointer;
  margin-bottom: 10px;
}
.complete_setup_modal .bg_img_container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
}

.rule_container .trigger_container, .rule_view .column_container .left .rule_settings .rule_container .trigger_container, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .trigger_container, .filters_actions_container .trigger_container {
  width: 375px;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  border: 1px solid #eee;
  border-radius: 3px;
  padding: 10px;
  background-color: #fff;
}
.rule_container .trigger_container .header_container, .rule_view .column_container .left .rule_settings .rule_container .trigger_container .header_container, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .trigger_container .header_container, .filters_actions_container .trigger_container .header_container {
  display: grid;
  grid-template-columns: 100px auto;
  margin-bottom: 15px;
  margin-top: 5px;
}
.rule_container .trigger_container .header_container .title_text, .rule_view .column_container .left .rule_settings .rule_container .trigger_container .header_container .title_text, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .trigger_container .header_container .title_text, .filters_actions_container .trigger_container .header_container .title_text {
  grid-column: 1/1;
  font-weight: bold;
  padding-left: 2px;
}
.rule_container .trigger_container .header_container .scopes_container, .rule_view .column_container .left .rule_settings .rule_container .trigger_container .header_container .scopes_container, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .trigger_container .header_container .scopes_container, .filters_actions_container .trigger_container .header_container .scopes_container {
  grid-column: 2/2;
  display: flex;
  flex-direction: row;
  justify-content: end;
}
.rule_container .trigger_container .header_container .scopes_container .metric_scope, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .trigger_container .header_container .scopes_container .metric_scope, .filters_actions_container .trigger_container .header_container .scopes_container .metric_scope {
  margin-right: 10px;
  display: flex;
  cursor: pointer;
}
.rule_container .trigger_container .search_bar, .rule_view .column_container .left .rule_settings .rule_container .trigger_container .search_bar, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .trigger_container .search_bar, .filters_actions_container .trigger_container .search_bar {
  width: calc(100% - 10px);
}
.rule_container .trigger_container .categories, .rule_view .column_container .left .rule_settings .rule_container .trigger_container .categories, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .trigger_container .categories, .filters_actions_container .trigger_container .categories {
  margin-top: 10px;
  height: 300px;
  overflow: scroll;
}
.rule_container .trigger_container .categories .categories_dropdown_container, .rule_view .column_container .left .rule_settings .rule_container .trigger_container .categories .categories_dropdown_container, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .trigger_container .categories .categories_dropdown_container, .filters_actions_container .trigger_container .categories .categories_dropdown_container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.rule_container .trigger_container .categories .categories_dropdown_container .category_container, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .trigger_container .categories .categories_dropdown_container .category_container, .filters_actions_container .trigger_container .categories .categories_dropdown_container .category_container {
  width: 100%;
  border-bottom: none;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
}
.rule_container .trigger_container .categories .categories_dropdown_container .category_container .accordion__button, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .trigger_container .categories .categories_dropdown_container .category_container .accordion__button, .filters_actions_container .trigger_container .categories .categories_dropdown_container .category_container .accordion__button {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  width: 100%;
  padding-left: 10px;
}
.rule_container .trigger_container .categories .categories_dropdown_container .metric_field, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .trigger_container .categories .categories_dropdown_container .metric_field, .filters_actions_container .trigger_container .categories .categories_dropdown_container .metric_field {
  padding: 10px 0px;
  padding-left: 30px;
  cursor: pointer;
}
.rule_container .trigger_container .categories .categories_dropdown_container .metric_field:hover, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .trigger_container .categories .categories_dropdown_container .metric_field:hover, .filters_actions_container .trigger_container .categories .categories_dropdown_container .metric_field:hover {
  background-color: #eee;
}
.rule_container .timeframe_container, .rule_view .column_container .left .rule_settings .rule_container .timeframe_container, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .timeframe_container, .filters_actions_container .timeframe_container {
  width: 300px;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  border: 1px solid #eee;
  border-radius: 3px;
  padding: 10px;
  background-color: #fff;
}
.rule_container .timeframe_container .search_bar, .rule_view .column_container .left .rule_settings .rule_container .timeframe_container .search_bar, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .timeframe_container .search_bar, .filters_actions_container .timeframe_container .search_bar {
  width: calc(100% - 10px);
}
.rule_container .timeframe_container .categories, .rule_view .column_container .left .rule_settings .rule_container .timeframe_container .categories, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .timeframe_container .categories, .filters_actions_container .timeframe_container .categories {
  margin-top: 10px;
  height: 200px;
  overflow: scroll;
}
.rule_container .timeframe_container .categories .categories_dropdown_container, .rule_view .column_container .left .rule_settings .rule_container .timeframe_container .categories .categories_dropdown_container, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .timeframe_container .categories .categories_dropdown_container, .filters_actions_container .timeframe_container .categories .categories_dropdown_container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.rule_container .timeframe_container .categories .categories_dropdown_container .category_container, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .timeframe_container .categories .categories_dropdown_container .category_container, .filters_actions_container .timeframe_container .categories .categories_dropdown_container .category_container {
  width: 100%;
  border-bottom: none;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
}
.rule_container .timeframe_container .categories .categories_dropdown_container .category_container .accordion__button, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .timeframe_container .categories .categories_dropdown_container .category_container .accordion__button, .filters_actions_container .timeframe_container .categories .categories_dropdown_container .category_container .accordion__button {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  width: 100%;
  padding-left: 10px;
}
.rule_container .timeframe_container .categories .categories_dropdown_container .metric_field, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .timeframe_container .categories .categories_dropdown_container .metric_field, .filters_actions_container .timeframe_container .categories .categories_dropdown_container .metric_field {
  padding: 10px 0px;
  padding-left: 30px;
  cursor: pointer;
}
.rule_container .timeframe_container .categories .categories_dropdown_container .metric_field:hover, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .timeframe_container .categories .categories_dropdown_container .metric_field:hover, .filters_actions_container .timeframe_container .categories .categories_dropdown_container .metric_field:hover {
  background-color: #eee;
}
.rule_container .predicate_container, .rule_view .column_container .left .rule_settings .rule_container .predicate_container, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .predicate_container, .filters_actions_container .predicate_container {
  width: 230px;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  border: 1px solid #eee;
  border-radius: 3px;
  background-color: #fff;
}
.rule_container .predicate_container .metric_field, .rule_view .column_container .left .rule_settings .rule_container .predicate_container .metric_field, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .predicate_container .metric_field, .filters_actions_container .predicate_container .metric_field {
  padding: 10px 10px;
  cursor: pointer;
}
.rule_container .predicate_container .metric_field:hover, .rule_view .column_container .left .rule_settings .rule_container .predicate_container .metric_field:hover, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .predicate_container .metric_field:hover, .filters_actions_container .predicate_container .metric_field:hover {
  background-color: #eee;
}
.rule_container .conditions_container, .rule_view .column_container .left .rule_settings .rule_container .conditions_container, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container, .filters_actions_container .conditions_container {
  display: grid !important;
  border-radius: 5px;
}
.rule_container .conditions_container .expressions_operand_bar, .rule_view .column_container .left .rule_settings .rule_container .conditions_container .expressions_operand_bar, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .expressions_operand_bar, .filters_actions_container .conditions_container .expressions_operand_bar {
  grid-column: 1/1;
  height: calc(100% - 67px);
  padding-top: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.rule_container .conditions_container .expressions_operand_bar .background, .rule_view .column_container .left .rule_settings .rule_container .conditions_container .expressions_operand_bar .background, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .expressions_operand_bar .background, .filters_actions_container .conditions_container .expressions_operand_bar .background {
  border-radius: 3px;
  height: 100%;
  width: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #e3e3e3;
}
.rule_container .conditions_container .expressions_operand_bar .background .text, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .expressions_operand_bar .background .text, .filters_actions_container .conditions_container .expressions_operand_bar .background .text {
  transform: rotate(-90deg);
  color: #fff;
  font-weight: lighter;
  font-size: 12px;
}
.rule_container .conditions_container .conditions_grid, .rule_view .column_container .left .rule_settings .rule_container .conditions_container .conditions_grid, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid, .filters_actions_container .conditions_container .conditions_grid {
  font-size: 14px;
}
.rule_container .conditions_container .conditions_grid .expressions_container, .rule_view .column_container .left .rule_settings .rule_container .conditions_container .conditions_grid .expressions_container, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container, .filters_actions_container .conditions_container .conditions_grid .expressions_container {
  display: grid;
  grid-template-rows: auto;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_operand_bar, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_operand_bar, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_operand_bar {
  grid-column: 1/1;
  height: calc(100% - 55px);
  padding-top: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_operand_bar .background, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_operand_bar .background, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_operand_bar .background {
  border-radius: 3px;
  height: 100%;
  width: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #e3e3e3;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_operand_bar .background .text, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_operand_bar .background .text, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_operand_bar .background .text {
  margin-left: -4px;
  transform: rotate(-90deg);
  color: #fff;
  font-weight: lighter;
  font-size: 12px;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr)) 50px;
  margin: 5px 0px;
  font-size: 12px;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_metric, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_metric, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_metric {
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  margin: 0px 10px 0px 0px;
  border: 1px solid #eee;
  border-radius: 3px;
  background-color: #fff;
  position: relative;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_metric .select, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_metric .select, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_metric .select {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 30px;
  align-items: center;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_metric .select .icon, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_metric .select .icon, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_metric .select .icon {
  font-size: 12px;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_metric .dropdown, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_metric .dropdown, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_metric .dropdown {
  position: absolute;
  z-index: 99999;
  top: 35px;
  left: 0px;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_timeframe, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_timeframe, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_timeframe {
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  margin: 0px 10px 0px 0px;
  border: 1px solid #eee;
  border-radius: 3px;
  background-color: #fff;
  position: relative;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_timeframe .select, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_timeframe .select, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_timeframe .select {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 30px;
  align-items: center;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_timeframe .select .icon, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_timeframe .select .icon, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_timeframe .select .icon {
  font-size: 12px;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_timeframe .dropdown, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_timeframe .dropdown, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_timeframe .dropdown {
  position: absolute;
  z-index: 99999;
  top: 35px;
  left: 0px;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_predicate, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_predicate, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_predicate {
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  margin: 0px 10px 0px 0px;
  border: 1px solid #eee;
  border-radius: 3px;
  background-color: #fff;
  position: relative;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_predicate .select, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_predicate .select, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_predicate .select {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 30px;
  align-items: center;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_predicate .select .icon, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_predicate .select .icon, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_predicate .select .icon {
  font-size: 12px;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_predicate .dropdown, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_predicate .dropdown, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_predicate .dropdown {
  position: absolute;
  z-index: 99999;
  top: 35px;
  left: 0px;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_value, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_value, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_value {
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  margin: 0px 10px 0px 0px;
  border: 1px solid #eee;
  border-radius: 3px;
  background-color: #fff;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_value input, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_value input, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_value input {
  border: none;
  height: 30px;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_value input:focus, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_value input:focus, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_value input:focus {
  outline: none;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_actions, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_actions, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_actions {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_actions .delete, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_actions .delete, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .expression_container .expression_actions .delete {
  cursor: pointer;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr)) 50px;
  margin: 5px 0px;
  font-size: 12px;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_metric, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_metric, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_metric {
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  margin: 0px 10px 0px 0px;
  border: 1px solid #eee;
  border-radius: 3px;
  background-color: #fff;
  position: relative;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_metric .select, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_metric .select, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_metric .select {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 30px;
  align-items: center;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_metric .select .icon, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_metric .select .icon, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_metric .select .icon {
  font-size: 12px;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_metric .dropdown, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_metric .dropdown, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_metric .dropdown {
  position: absolute;
  z-index: 99999;
  top: 35px;
  left: 0px;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_timeframe, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_timeframe, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_timeframe {
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  margin: 0px 10px 0px 0px;
  border: 1px solid #eee;
  border-radius: 3px;
  background-color: #fff;
  position: relative;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_timeframe .select, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_timeframe .select, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_timeframe .select {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 30px;
  align-items: center;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_timeframe .select .icon, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_timeframe .select .icon, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_timeframe .select .icon {
  font-size: 12px;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_timeframe .dropdown, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_timeframe .dropdown, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_timeframe .dropdown {
  position: absolute;
  z-index: 99999;
  top: 35px;
  left: 0px;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_predicate, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_predicate, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_predicate {
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  margin: 0px 10px 0px 0px;
  border: 1px solid #eee;
  border-radius: 3px;
  background-color: #fff;
  position: relative;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_predicate .select, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_predicate .select, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_predicate .select {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 30px;
  align-items: center;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_predicate .select .icon, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_predicate .select .icon, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_predicate .select .icon {
  font-size: 12px;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_predicate .dropdown, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_predicate .dropdown, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_predicate .dropdown {
  position: absolute;
  z-index: 99999;
  top: 35px;
  left: 0px;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_value, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_value, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_value {
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  margin: 0px 10px 0px 0px;
  border: 1px solid #eee;
  border-radius: 3px;
  background-color: #fff;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_value input, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_value input, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_value input {
  border: none;
  height: 30px;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_value input:focus, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_value input:focus, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_value input:focus {
  outline: none;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_actions, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_actions, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_actions {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_actions .delete, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_actions .delete, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .ads_manager_expression_container .expression_actions .delete {
  cursor: pointer;
}
.rule_container .conditions_container .conditions_grid .expressions_container .expressions_grid .group_actions_container, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .group_actions_container, .filters_actions_container .conditions_container .conditions_grid .expressions_container .expressions_grid .group_actions_container {
  margin: 5px 0px;
}
.rule_container .conditions_container .conditions_grid .conditions_actions_container .add_or_condition, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .conditions_actions_container .add_or_condition, .filters_actions_container .conditions_container .conditions_grid .conditions_actions_container .add_or_condition {
  font-size: 12px;
}
.rule_container .conditions_container .conditions_grid .conditions_actions_container .add_or_condition .button, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .conditions_actions_container .add_or_condition .button, .filters_actions_container .conditions_container .conditions_grid .conditions_actions_container .add_or_condition .button {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  margin-left: 3px;
  width: 100px;
}
.rule_container .conditions_container .conditions_grid .conditions_actions_container .add_or_condition .button .icon, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .conditions_actions_container .add_or_condition .button .icon, .filters_actions_container .conditions_container .conditions_grid .conditions_actions_container .add_or_condition .button .icon {
  margin-right: 10px;
}
.rule_container .conditions_container .conditions_grid .conditions_actions_container .add_or_condition .button .text, .ads_manager_view .ads_manager_container .filters_container .conditions_dropdown .conditions_container .conditions_container .conditions_grid .conditions_actions_container .add_or_condition .button .text, .filters_actions_container .conditions_container .conditions_grid .conditions_actions_container .add_or_condition .button .text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.add_filter_button {
  font-size: 12px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  margin-left: 3px;
  width: 100%;
  height: 70px;
  align-items: center;
  justify-content: center;
}
.add_filter_button .icon {
  margin-right: 10px;
}
.add_filter_button .text,
.add_filter_button .icon {
  margin-top: -5px;
}

.rule_view {
  padding: 20px;
  overflow-y: scroll;
}
.rule_view .header_container {
  display: flex;
  flex-direction: column;
}
.rule_view .header_container .title_and_actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.rule_view .header_container .title_and_actions .title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-bottom: 10px;
}
.rule_view .header_container .title_and_actions .title textarea {
  border: none;
  width: 100%;
  font-size: 18px;
  line-height: 1.3em;
}
.rule_view .header_container .title_and_actions .title textarea:focus {
  outline: none;
}
.rule_view .header_container .title_and_actions .actions {
  display: flex;
  flex-direction: row;
}
.rule_view .header_container .title_and_actions .actions .logs {
  grid-column: 2/2;
  font-size: 14px;
  height: 30px;
  width: 60px;
  color: #fff;
  background-color: #e3e3e3;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 20px;
}
.rule_view .header_container .title_and_actions .actions .logs:hover {
  background-color: #2196f3;
}
.rule_view .header_container .title_and_actions .actions .save {
  grid-column: 3/3;
  font-size: 14px;
  height: 30px;
  width: 60px;
  color: #fff;
  background-color: #e3e3e3;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.rule_view .header_container .title_and_actions .actions .save:hover {
  background-color: #2196f3;
}
.rule_view .header_container .line_break {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 1px;
  background-color: #eeeeee;
}
.rule_view .column_container {
  display: grid;
  grid-template-columns: 60% 40%;
  overflow: hidden;
  border: 1px solid #eee;
  max-height: 600px;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
  padding-top: 0px;
}
.rule_view .column_container .left {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: hidden;
  padding-right: 10px;
}
.rule_view .column_container .left .rule_settings {
  max-height: 650px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 70px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.rule_view .column_container .left .rule_settings::-webkit-scrollbar {
  display: none;
}
.rule_view .column_container .left .rule_settings .description_container {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
}
.rule_view .column_container .left .rule_settings .description_container .textarea_container {
  width: 100%;
  overflow-x: hidden;
  height: 100px;
  margin-bottom: 10px;
  color: #474747;
  line-height: 1.2em;
}
.rule_view .column_container .left .rule_settings .description_container .textarea_container textarea {
  width: 100%;
  border: none;
  font-size: 14px;
}
.rule_view .column_container .left .rule_settings .description_container .textarea_container textarea:focus {
  outline: none;
}
.rule_view .column_container .left .rule_settings .rule_container {
  width: 100%;
  border-radius: 5px;
  padding: 10px 10px 10px 15px;
  border: 1px solid #eee;
  margin-top: 10px;
}
.rule_view .column_container .left .rule_settings .rule_container .rule_scope_action_container {
  display: flex;
  flex-direction: row;
}
.rule_view .column_container .left .rule_settings .rule_container .action_container {
  font-size: 12px;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: 30px;
  margin: 5px 0px 10px 0px;
  cursor: pointer;
  position: relative;
  margin-right: 20px;
}
.rule_view .column_container .left .rule_settings .rule_container .action_container .icon_container {
  grid-column: 1/1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #eee;
  border-radius: 3px;
  margin-right: 10px;
  width: 30px;
}
.rule_view .column_container .left .rule_settings .rule_container .action_container .scope_description_container {
  grid-column: 2/2;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.rule_view .column_container .left .rule_settings .rule_container .action_container .scope_description_container .text {
  font-weight: bold;
  margin-bottom: 3px;
}
.rule_view .column_container .left .rule_settings .rule_container .action_container .action_name_container {
  grid-column: 2/2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  margin-right: 10px;
}
.rule_view .column_container .left .rule_settings .rule_container .action_container .action_name_container .text {
  font-weight: bold;
  margin-bottom: 3px;
}
.rule_view .column_container .left .rule_settings .rule_container .action_container .action_description {
  grid-column: 3/3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: auto;
}
.rule_view .column_container .left .rule_settings .rule_container .action_container .action_description .budget_params_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 30px;
}
.rule_view .column_container .left .rule_settings .rule_container .action_container .action_description .budget_params_container .budget_amount_container {
  height: 100%;
  margin-right: 10px;
}
.rule_view .column_container .left .rule_settings .rule_container .action_container .action_description .budget_params_container .budget_amount_container input {
  height: 100%;
  border: 2px solid #eee;
  border-radius: 3px;
  text-align: right;
  font-size: 14px;
  padding-right: 5px;
  width: 100px;
}
.rule_view .column_container .left .rule_settings .rule_container .action_container .action_description .budget_params_container .budget_amount_container input:focus {
  outline: none;
}
.rule_view .column_container .left .rule_settings .rule_container .action_container .action_description .budget_params_container .budget_type_dropdown_container {
  height: 100%;
}
.rule_view .column_container .left .rule_settings .rule_container .action_container .action_description .budget_params_container .budget_type_dropdown_container .budget_type_container {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 0px 10px;
  border: 2px solid #eee;
  border-radius: 3px;
  width: 60px;
}
.rule_view .column_container .left .rule_settings .rule_container .action_container .actions_dropdown {
  position: absolute;
  width: 190px;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  border: 1px solid #eee;
  border-radius: 3px;
  background-color: #fff;
  z-index: 9999;
  top: 40px;
}
.rule_view .column_container .left .rule_settings .rule_container .action_container .actions_dropdown .metric_field {
  padding: 10px 10px;
  cursor: pointer;
}
.rule_view .column_container .left .rule_settings .rule_container .action_container .actions_dropdown .metric_field:hover {
  background-color: #eee;
}
.rule_view .column_container .left .rule_settings .schedule_container {
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #eee;
  font-size: 14px;
  border-radius: 5px;
}
.rule_view .column_container .left .rule_settings .schedule_container .title {
  font-size: 16px;
  margin: 0px 20px 20px 0px;
  font-weight: bold;
}
.rule_view .column_container .left .rule_settings .schedule_container .description {
  line-height: 1.5em;
}
.rule_view .column_container .left .rule_settings .schedule_container .scheduler_dropdown_container {
  position: relative;
  margin-top: 20px;
}
.rule_view .column_container .left .rule_settings .schedule_container .scheduler_dropdown_container .select {
  display: flex;
  flex-direction: row;
  width: 300px;
  justify-content: space-between;
  border: 1px solid #eee;
  padding: 10px;
  border-radius: 3px;
  cursor: pointer;
}
.rule_view .column_container .left .rule_settings .schedule_container .scheduler_dropdown_container .schedule_dropdown {
  position: absolute;
  width: 300px;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  border: 1px solid #eee;
  border-radius: 3px;
  background-color: #fff;
  z-index: 9999;
  top: 40px;
}
.rule_view .column_container .left .rule_settings .schedule_container .scheduler_dropdown_container .schedule_dropdown .metric_field {
  padding: 10px 10px;
  cursor: pointer;
}
.rule_view .column_container .left .rule_settings .schedule_container .scheduler_dropdown_container .schedule_dropdown .metric_field:hover {
  background-color: #eee;
}
.rule_view .column_container .left .rule_settings .notifications_container {
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #eee;
  border-radius: 5px;
  font-size: 14px;
}
.rule_view .column_container .left .rule_settings .notifications_container .title {
  font-size: 16px;
  margin: 0px 20px 20px 0px;
  font-weight: bold;
}
.rule_view .column_container .left .rule_settings .notifications_container .description {
  line-height: 1.5em;
}
.rule_view .column_container .left .rule_settings .notifications_container .notifications_dropdown_container {
  position: relative;
  margin-top: 20px;
}
.rule_view .column_container .left .rule_settings .notifications_container .notifications_dropdown_container .select {
  display: flex;
  flex-direction: row;
  width: 300px;
  justify-content: space-between;
  border: 1px solid #eee;
  padding: 10px;
  border-radius: 3px;
  cursor: pointer;
}
.rule_view .column_container .left .rule_settings .notifications_container .notifications_dropdown_container .schedule_dropdown {
  position: absolute;
  width: 300px;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  border: 1px solid #eee;
  border-radius: 3px;
  background-color: #fff;
  z-index: 9999;
  top: 40px;
  left: 0px;
}
.rule_view .column_container .left .rule_settings .notifications_container .notifications_dropdown_container .schedule_dropdown .metric_field {
  padding: 10px 10px;
  cursor: pointer;
}
.rule_view .column_container .left .rule_settings .notifications_container .notifications_dropdown_container .schedule_dropdown .metric_field:hover {
  background-color: #eee;
}
.rule_view .column_container .left .rule_settings .attributions_container {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #eee;
  font-size: 14px;
}
.rule_view .column_container .left .rule_settings .attributions_container .title {
  font-size: 16px;
  margin: 0px 20px 20px 0px;
  font-weight: bold;
}
.rule_view .column_container .left .rule_settings .attributions_container .description {
  margin: 0px 20px 20px 0px;
  width: 500px;
  line-height: 1.5em;
}
.rule_view .column_container .left .rule_settings .attributions_container .switch_label_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}
.rule_view .column_container .left .rule_settings .attributions_container .switch_label_container .switch {
  margin-right: 10px;
}
.rule_view .column_container .left .rule_settings .attributions_container .attributions_dropdown_container {
  position: relative;
}
.rule_view .column_container .left .rule_settings .attributions_container .attributions_dropdown_container .select {
  display: flex;
  flex-direction: row;
  width: 300px;
  justify-content: space-between;
  border: 1px solid #eee;
  padding: 10px;
  border-radius: 3px;
  cursor: pointer;
}
.rule_view .column_container .left .rule_settings .attributions_container .attributions_dropdown_container .schedule_dropdown {
  position: absolute;
  width: 300px;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  border: 1px solid #eee;
  border-radius: 3px;
  background-color: #fff;
  z-index: 9999;
  top: 40px;
  left: 0px;
}
.rule_view .column_container .left .rule_settings .attributions_container .attributions_dropdown_container .schedule_dropdown .metric_field {
  padding: 10px 10px;
  cursor: pointer;
}
.rule_view .column_container .left .rule_settings .attributions_container .attributions_dropdown_container .schedule_dropdown .metric_field:hover {
  background-color: #eee;
}
.rule_view .column_container .right {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: scroll;
  padding-left: 10px;
}
.rule_view .column_container .right .assets_tabs_container {
  height: 40px;
  display: grid;
  grid-template-columns: auto auto;
  width: 100%;
  border: 1px solid #eee;
  margin-top: 10px;
  border-radius: 5px;
}
.rule_view .column_container .right .assets_tabs_container .tab_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-right: 1px solid #eee;
  cursor: pointer;
}
.rule_view .column_container .right .assets_tabs_container .tab_container:last-of-type {
  border-right: none;
}
.rule_view .column_container .right .assets_tabs_container .tab_container .text {
  font-weight: bold;
}
.rule_view .column_container .right .assets_container {
  font-size: 12px;
  max-height: 650px;
  overflow: scroll;
  padding-bottom: 70px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.rule_view .column_container .right .assets_container::-webkit-scrollbar {
  display: none;
}
.rule_view .column_container .right .assets_container .assets_container_accordion {
  margin-top: 10px;
  margin-bottom: 50px;
}
.rule_view .column_container .right .assets_container .accordion_parent_container {
  display: relative;
  margin: 10px 0px !important;
  border: 1px solid #eee;
  padding: 7px 5px;
  border-radius: 5px;
}
.rule_view .column_container .right .assets_container .accordion_parent_container:first-of-type {
  margin-top: 0px !important;
}
.rule_view .column_container .right .assets_container .accordion_parent_container:last-of-type {
  margin-bottom: 0px !important;
}
.rule_view .column_container .right .assets_container .heading_container {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.rule_view .column_container .right .assets_container .heading_container .heading_button {
  display: grid;
  grid-template-columns: 10px 30px auto;
  width: 100%;
}
.rule_view .column_container .right .assets_container .heading_container .heading_button .heading_checkbox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.rule_view .column_container .right .assets_container .heading_container .heading_button .heading_checkbox input {
  cursor: pointer;
  width: 15px;
  height: 15px;
}
.rule_view .column_container .right .assets_container .heading_container .heading_button .heading_icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.rule_view .column_container .right .assets_container .heading_container .heading_button .heading_name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: bold;
}
.rule_view .column_container .right .assets_container .accordion_items_container {
  margin-left: 22px;
}
.rule_view .column_container .right .assets_container .asset_item_row {
  line-height: 1.5em;
  display: grid;
  grid-template-columns: 40px auto;
  grid-template-rows: auto;
  margin: 10px 0px;
}
.rule_view .column_container .right .assets_container .asset_item_row .name {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.rule_view .column_container .right .assets_container .asset_item_row:first-of-type {
  margin-top: 10px;
}
.rule_view .column_container .right .assets_container .select_checkbox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.rule_view .column_container .right .assets_container .select_checkbox input {
  cursor: pointer;
  width: 15px;
  height: 15px;
}
.rule_view .column_container .right .assets_container .heading_name {
  font-weight: bold;
  line-height: 1.5em;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ant-tree {
  width: 100% !important;
}

.ant-tree-treenode {
  padding: 5px 0px !important;
  width: 100% !important;
}

.ant-tree .ant-tree-node-content-wrapper {
  width: 100% !important;
}

.rule_logs_and_tabs_container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.rule_logs_and_tabs_container .log_tabs_container {
  display: grid;
  grid-template-columns: auto auto auto;
  border: 1px solid #eee;
  margin: 20px 0px 10px 0px;
  width: 300px;
  height: 30px;
  border-radius: 5px;
  align-self: flex-end;
}
.rule_logs_and_tabs_container .log_tabs_container .tab {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #eee;
  height: 100%;
  cursor: pointer;
}
.rule_logs_and_tabs_container .log_tabs_container .tab:last-of-type {
  border-right: none;
}

.rule_logs_container {
  min-height: 100px;
  max-height: 700px;
  border: 1px solid #eee;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  overflow-y: scroll;
}
.rule_logs_container .empty_logs_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  align-items: center;
  justify-content: center;
}
.rule_logs_container .logs_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
}
.rule_logs_container .logs_container .log_container {
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
  border-bottom: 1px solid #eee;
  padding: 10px;
}
.rule_logs_container .logs_container .log_container:last-of-type {
  border-bottom: none;
  margin-bottom: 0px;
  padding-bottom: 0px;
}
.rule_logs_container .logs_container .log_container .log_header_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5px;
}
.rule_logs_container .logs_container .log_container .log_header_container .name {
  grid-column: 1/1;
  font-weight: bold;
  margin-right: 20px;
  line-height: 1.5em;
}
.rule_logs_container .logs_container .log_container .log_header_container .asset_identification {
  display: flex;
  flex-direction: row;
}
.rule_logs_container .logs_container .log_container .log_header_container .asset_identification .scope {
  font-weight: bold;
  margin-right: 10px;
}
.rule_logs_container .logs_container .log_container .log_description_container {
  display: grid;
  grid-template-columns: 200px 200px 200px auto;
  margin-bottom: 5px;
  align-items: center;
}
.rule_logs_container .logs_container .log_container .log_description_container .crated_at {
  grid-column: 1/1;
}
.rule_logs_container .logs_container .log_container .log_description_container .prev_value {
  grid-column: 2/2;
  display: flex;
  flex-direction: row;
}
.rule_logs_container .logs_container .log_container .log_description_container .prev_value .label {
  margin-right: 5px;
}
.rule_logs_container .logs_container .log_container .log_description_container .updated_value {
  grid-column: 3/3;
  display: flex;
  flex-direction: row;
}
.rule_logs_container .logs_container .log_container .log_description_container .updated_value .label {
  margin-right: 5px;
}
.rule_logs_container .logs_container .log_container .log_description_container .status_data_container {
  grid-column: 5/5;
  justify-self: flex-end;
  display: grid;
  grid-template-columns: 100px 100px 100px;
  align-items: center;
}
.rule_logs_container .logs_container .log_container .log_description_container .status_data_container .expression_status_totals {
  display: flex;
  flex-direction: row;
  justify-self: flex-end;
}
.rule_logs_container .logs_container .log_container .log_description_container .status_data_container .expression_status_totals .value {
  margin-right: 2px;
}
.rule_logs_container .logs_container .log_container .log_description_container .status_data_container .expression_status_totals .label {
  margin-right: 10px;
}
.rule_logs_container .logs_container .log_container .log_description_container .status_data_container .status {
  justify-self: flex-end;
  padding: 5px 10px;
  border-radius: 3px;
}
.rule_logs_container .logs_container .log_container .expression_container {
  display: grid;
  grid-template-columns: 100px 100px 100px 100px 100px 100px 100px;
  margin: 5px 0px;
  padding: 7px 0px;
  padding-left: 10px;
  border-radius: 3px;
}

.integrations_view {
  display: grid;
  grid-template-columns: 200px auto;
  font-size: 14px;
  overflow-y: scroll;
}
.integrations_view .nav_container {
  grid-column: 1/1;
  display: flex;
  flex-direction: column;
}
.integrations_view .nav_container .nav {
  height: 100%;
  margin: 10px;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 5px;
}
.integrations_view .nav_container .nav .nav_item {
  cursor: pointer;
  padding: 10px 0px 10px 5px;
  color: #bebebe;
}
.integrations_view .nav_container .nav .nav_item:hover {
  color: #fff;
  background-color: #41a9ff;
  border-radius: 3px;
}
.integrations_view .integrations_container {
  grid-column: 2/2;
  overflow-y: scroll;
}
.integrations_view .integrations_container .integrations_group_container {
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
}
.integrations_view .integrations_container .integrations_group_container .group_name {
  margin-left: 15px;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}
.integrations_view .integrations_container .integrations_group_container .integrations {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 150px;
  margin: 10px 0px;
}
.integrations_view .integrations_container .integrations_group_container .integrations .integration_container {
  border: 1px solid #eee;
  border-radius: 5px;
  margin: 0px 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.integrations_view .integrations_container .integrations_group_container .integrations .integration_container .actions {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.integrations_view .integrations_container .integrations_group_container .integrations .integration_container .actions .action_container {
  margin: 0px 20px;
  font-weight: bold;
}
.integrations_view .integrations_container .integrations_group_container .integrations .integration_container .actions .action_container:first-child {
  margin: 0px 20px 0px 0px;
}
.integrations_view .integrations_container .integrations_group_container .integrations .integration_container .logo {
  display: flex;
  flex-direction: column;
  height: 60px;
  margin: 5px 0px;
}
.integrations_view .integrations_container .integrations_group_container .integrations .integration_container .logo img {
  width: 40px;
}
.integrations_view .integrations_container .integrations_group_container .integrations .integration_container .name {
  margin: 10px 0px;
}
.integrations_view .integrations_container .integrations_group_container .integrations .integration_container .status {
  margin: 5px 0px;
  cursor: pointer;
}

.templates_view {
  display: grid;
  grid-template-columns: 200px auto;
  font-size: 12px;
  overflow-y: scroll;
}
.templates_view .nav_container {
  grid-column: 1/1;
  display: flex;
  flex-direction: column;
}
.templates_view .nav_container .nav {
  height: 100%;
  margin: 10px;
  padding: 10px;
  background-color: #fafafa;
  border-radius: 5px;
}
.templates_view .nav_container .nav .nav_item {
  cursor: pointer;
  padding: 7px 0px 7px 5px;
  color: #5f5f5f;
}
.templates_view .nav_container .nav .nav_item:hover {
  color: #fff;
  background-color: #41a9ff;
  border-radius: 3px;
}
.templates_view .templates_container {
  grid-column: 2/2;
  overflow-y: scroll;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-rows: 200px;
}
.templates_view .templates_container .rule_template_container {
  padding: 10px;
  height: 200px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.templates_view .templates_container .rule_template_container .rule_template {
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  border: 1px solid #eee;
  padding: 15px;
  position: relative;
}
.templates_view .templates_container .rule_template_container .rule_template .description_text {
  position: absolute;
  top: 15px;
  line-height: 1.2em;
}
.templates_view .templates_container .rule_template_container .rule_template .description_container {
  font-size: 12px;
}
.templates_view .templates_container .rule_template_container .rule_template .description_container .expressions_container {
  display: grid;
  grid-template-columns: 15px auto;
  margin-bottom: 10px;
}
.templates_view .templates_container .rule_template_container .rule_template .description_container .expressions_container .expressions_line_break {
  grid-column: 1/1;
  background-color: #2196f3;
  height: 100%;
  width: 1px;
}
.templates_view .templates_container .rule_template_container .rule_template .description_container .expressions_container .expressions {
  grid-column: 2/2;
}
.templates_view .templates_container .rule_template_container .rule_template .description_container .expressions_container .expressions .expression_container {
  display: flex;
  flex-direction: row;
}
.templates_view .templates_container .rule_template_container .rule_template .description_container .expressions_container .expressions .expression_container .field {
  margin: 2px 5px 2px 0px;
  color: #303030;
}
.templates_view .templates_container .rule_template_container .rule_template .description_container .expressions_container .expressions .expression_container .bold {
  font-weight: bold;
}
.templates_view .templates_container .rule_template_container .rule_template .add_rule_button {
  height: 35px;
  border: 1px solid #eee;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  cursor: pointer;
  position: absolute;
  left: 15px;
  right: 15px;
  bottom: 15px;
}
.templates_view .templates_container .rule_template_container .rule_template .add_rule_button:hover {
  background-color: #eeeeee;
}

.rules_view {
  font-size: 14px;
  padding: 20px;
  overflow-y: scroll;
}
.rules_view .rules_header .header {
  display: grid;
  grid-template-columns: auto 200px;
  grid-template-rows: 40px;
  align-items: center;
}
.rules_view .rules_header .header .title {
  font-size: 20px;
}
.rules_view .rules_header .header .actions {
  display: flex;
  flex-direction: row;
  justify-content: end;
}
.rules_view .rules_header .header .actions .new_rule_button {
  display: flex;
  flex-direction: column;
  height: 30px;
  width: 150px;
  display: flex;
  flex-direction: row;
  color: #fff;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.rules_view .rules_header .header .actions .new_rule_button .icon {
  margin-right: 10px;
}
.rules_view .rules_header .resources_container {
  display: flex;
  flex-direction: row;
  height: 30px;
  font-size: 12px;
  margin-bottom: 10px;
}
.rules_view .rules_header .resources_container .resource_container {
  height: 100%;
  display: flex;
  flex-direction: row;
  width: 200px;
  align-items: center;
  color: #2196f3;
  font-weight: bold;
  cursor: pointer;
}
.rules_view .rules_header .resources_container .resource_container .icon {
  margin-right: 10px;
}
.rules_view .rules_header .line_break {
  height: 1px;
  display: flex;
  flex-direction: row;
  background-color: #eee;
}
.rules_view .rules_modifier {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 10px 0px;
}
.rules_view .rules_modifier .search_container {
  width: 200px;
  display: flex;
  flex-direction: column;
  height: 30px;
}
.rules_view .rules_modifier .search_container .input_container {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  background-color: #f7f7f7;
  border-radius: 3px;
  color: #d6d6d6;
}
.rules_view .rules_modifier .search_container .input_container .icon {
  width: 20px;
  margin-left: 10px;
}
.rules_view .rules_modifier .search_container .input_container .value input {
  border: none;
  background: none;
  color: #969696;
}
.rules_view .rules_modifier .search_container .input_container .value input:focus {
  outline: none;
}
.rules_view .rules_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
}
.rules_view .rules_container .rule_container {
  margin: 5px 0px;
  display: grid;
  grid-template-columns: 70px auto 150px;
  grid-template-rows: auto;
  align-items: center;
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 10px 0px 10px 10px;
  position: relative;
}
.rules_view .rules_container .rule_container .rule_description {
  grid-column: 2/2;
  display: grid;
  grid-template-columns: auto 150px 120px 100px;
  grid-template-rows: auto;
  font-size: 12px;
  font-weight: lighter;
}
.rules_view .rules_container .rule_container .rule_description .rule_description_item {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
}
.rules_view .rules_container .rule_container .rule_description .rule_description_item .title {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-weight: bold;
  margin-bottom: 5px;
}
.rules_view .rules_container .rule_container .rule_description .rule_description_item .value {
  line-height: 1.5em;
}
.rules_view .rules_container .rule_container .rule_description .name {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: 30px;
}
.rules_view .rules_container .rule_container .rule_description span {
  font-weight: bold;
}
.rules_view .rules_container .rule_container .actions {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.rules_view .rules_container .rule_container .actions .action_item {
  margin: 0px 10px;
  cursor: pointer;
}
.rules_view .rules_container .rule_container .actions a {
  color: black;
}
.rules_view .rules_container .rule_container .actions .logs {
  background-color: #e3e3e3;
  padding: 7px 10px;
  border-radius: 3px;
  color: #fff;
}

.login_view {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.login_view::-webkit-scrollbar {
  display: none;
}
.login_view .auth_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
}
.login_view .auth_container .auth_form_container {
  display: flex;
  flex-direction: column;
  width: calc(100% - 40px);
  min-height: 600px;
  border: 1px solid #eee;
  border-radius: 3px;
  padding: 20px;
  background-color: #fff;
}
@media only screen and (min-width: 600px) {
  .login_view .auth_container .auth_form_container {
    width: 500px;
  }
}
.login_view .auth_container .auth_form_container .header .title {
  font-size: 20px;
  margin-bottom: 15px;
}
.login_view .auth_container .auth_form_container .auth_input_container {
  margin: 10px 0px;
  display: flex;
  flex-direction: column;
}
.login_view .auth_container .auth_form_container .auth_input_container .label {
  font-size: 12px;
}
.login_view .auth_container .auth_form_container .auth_input_container .value {
  margin: 10px 0px;
}
.login_view .auth_container .auth_form_container .auth_input_container .value input {
  border: 1px solid #eee;
  width: 100%;
  height: 40px;
  border-radius: 3px;
  padding-left: 15px;
}
.login_view .auth_container .auth_form_container .cta_container {
  width: 100%;
  background-color: #2196f3;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
}
.login_view .auth_container .auth_form_container .cta_container .login-button {
  display: flex;
  flex-direction: column;
  height: 50px;
  align-items: center;
  justify-content: center;
}
.login_view .auth_container .auth_form_container .footer_actions {
  margin-top: 50px;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
}
.login_view .auth_container .auth_form_container .footer_actions .action_link {
  margin: 5px 0px;
  cursor: pointer;
  margin-bottom: 30px;
}
.login_view .auth_container .auth_form_container .footer_actions .action_link:last-of-type {
  margin-bottom: 0px;
}
.login_view .auth_container .auth_form_container .footer_actions .action_link a {
  color: #000;
}
.login_view .auth_container .auth_form_container .footer_actions .action_link .text {
  display: flex;
  flex-direction: column;
}
.login_view .auth_container .auth_form_container .footer_actions .action_link .text .regular {
  font-weight: 300;
  margin-right: 5px;
  margin-bottom: 5px;
}

.signup_view {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.signup_view::-webkit-scrollbar {
  display: none;
}
.signup_view .auth_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
}
.signup_view .auth_container .headline {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 20px;
}
.signup_view .auth_container .auth_form_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 600px;
  border: 1px solid #eee;
  border-radius: 3px;
  padding: 20px;
  background-color: #fff;
  width: calc(100% - 40px);
}
@media only screen and (min-width: 600px) {
  .signup_view .auth_container .auth_form_container {
    width: 500px;
  }
}
.signup_view .auth_container .auth_form_container .header .title {
  font-size: 15px;
  margin-bottom: 15px;
}
.signup_view .auth_container .auth_form_container .auth_input_container {
  margin: 10px 0px;
  display: flex;
  flex-direction: column;
}
.signup_view .auth_container .auth_form_container .auth_input_container .label {
  font-size: 12px;
}
.signup_view .auth_container .auth_form_container .auth_input_container .value {
  margin: 10px 0px;
}
.signup_view .auth_container .auth_form_container .auth_input_container .value input {
  border: 1px solid #eee;
  width: 100%;
  height: 40px;
  border-radius: 3px;
  padding-left: 15px;
}
.signup_view .auth_container .auth_form_container .cta_container {
  width: 100%;
  background-color: #2196f3;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
}
.signup_view .auth_container .auth_form_container .cta_container .login-button {
  display: flex;
  flex-direction: column;
  height: 50px;
  align-items: center;
  justify-content: center;
}
.signup_view .auth_container .auth_form_container .footer_actions {
  margin-top: 50px;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
}
.signup_view .auth_container .auth_form_container .footer_actions .action_link {
  margin: 5px 0px;
  cursor: pointer;
}
.signup_view .auth_container .auth_form_container .footer_actions .action_link a {
  color: #000;
}
.signup_view .auth_container .auth_form_container .footer_actions .action_link .text {
  display: flex;
  flex-direction: row;
}
.signup_view .auth_container .auth_form_container .footer_actions .action_link .text .regular {
  font-weight: 300;
  margin-right: 5px;
}

.footer {
  margin-top: 20px;
  height: 25px;
  font-size: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #fff;
}
.footer .footer_link {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin: 0px 10px;
  color: #666;
}

.ad_accounts_view {
  font-size: 14px;
  padding: 20px 40px;
  overflow-y: scroll;
}
.ad_accounts_view .account_container {
  margin-bottom: 20px;
}
.ad_accounts_view .account_container .header {
  font-weight: bold;
  font-size: 16px;
}
.ad_accounts_view .account_container .profile_container {
  border-bottom: 1px solid #eee;
  display: grid;
  grid-template-columns: 50px auto 50px;
  grid-template-rows: 50px;
  width: 400px;
  margin: 20px 0px;
  padding-bottom: 30px;
}
.ad_accounts_view .account_container .profile_container .profile_picture {
  height: 100%;
}
.ad_accounts_view .account_container .profile_container .profile_picture img {
  border-radius: 100px;
}
.ad_accounts_view .account_container .profile_container .profile_info {
  height: 100%;
  padding-left: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.ad_accounts_view .account_container .profile_container .profile_info .name {
  margin: 3px 0px;
}
.ad_accounts_view .account_container .profile_container .profile_info .id {
  margin: 3px 0px;
}
.ad_accounts_view .account_container .profile_container .profile_info .id span {
  font-weight: bold;
}
.ad_accounts_view .account_container .profile_container .profile_actions {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ad_accounts_view .account_container .profile_container .profile_actions .close {
  cursor: pointer;
}
.ad_accounts_view .ad_accounts_container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.ad_accounts_view .ad_accounts_container .ad_accounts_header {
  margin-bottom: 30px;
}
.ad_accounts_view .ad_accounts_container .ad_accounts_header .title {
  font-size: 16px;
  font-weight: bold;
}
.ad_accounts_view .ad_accounts_container .ad_accounts_container {
  display: flex;
  flex-direction: column;
}
.ad_accounts_view .ad_accounts_container .ad_accounts_container .ad_accounts_header_columns {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
}
.ad_accounts_view .ad_accounts_container .ad_accounts_container .ad_accounts_header_columns .status {
  width: 70px;
}
.ad_accounts_view .ad_accounts_container .ad_accounts_container .ad_accounts_header_columns .name {
  width: 350px;
}
.ad_accounts_view .ad_accounts_container .ad_accounts_container .ad_accounts_header_columns .id {
  width: 200px;
}
.ad_accounts_view .ad_accounts_container .ad_accounts_container .ad_accounts_header_columns .spend {
  width: 200px;
}
.ad_accounts_view .ad_accounts_container .ad_accounts_container .ad_account_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 15px 0px;
  font-size: 12px;
}
.ad_accounts_view .ad_accounts_container .ad_accounts_container .ad_account_container .ad_account_status {
  width: 70px;
}
.ad_accounts_view .ad_accounts_container .ad_accounts_container .ad_account_container .ad_account_name {
  width: 350px;
}
.ad_accounts_view .ad_accounts_container .ad_accounts_container .ad_account_container .ad_account_id {
  width: 200px;
}
.ad_accounts_view .ad_accounts_container .ad_accounts_container .ad_account_container .ad_account_spent {
  width: 200px;
}

.logs_view {
  font-size: 12px;
  padding: 20px;
  overflow-y: scroll;
}
.logs_view .logs_container {
  display: flex;
  flex-direction: column;
}
.logs_view .logs_container .log_container {
  display: grid;
  grid-template-columns: 200px auto;
  margin-bottom: 20px;
}
.logs_view .logs_container .log_container .item_info_container {
  grid-column: 1/1;
}
.logs_view .logs_container .log_container .item_info_container .item_name {
  font-size: 12px;
  color: #303030;
  margin: 10px 0px;
  line-height: 1.2em;
}
.logs_view .logs_container .log_container .item_info_container .item_id {
  margin: 10px 0px;
  color: #2196f3;
  font-weight: bold;
}
.logs_view .logs_container .log_container .item_info_container .campaign_name {
  margin: 10px 0px;
  color: #a1a1a1;
  line-height: 1.3em;
}
.logs_view .logs_container .log_container .item_info_container .campaign_name span {
  font-weight: bold;
}
.logs_view .logs_container .log_container .rule_info_container {
  margin-left: 10px;
  grid-column: 2/2;
  display: flex;
  flex-direction: column;
  border: 1px solid #eee;
  padding: 10px;
  border-radius: 3px;
}

.rule_container {
  width: 100%;
}
.rule_container .rule_scope_action_container {
  display: flex;
  flex-direction: row;
}
.rule_container .action_container {
  font-size: 12px;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: 30px;
  margin: 5px 0px 10px 0px;
  cursor: pointer;
  position: relative;
  margin-right: 20px;
}
.rule_container .action_container .icon_container {
  grid-column: 1/1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #eee;
  border-radius: 3px;
  margin-right: 10px;
  width: 30px;
}
.rule_container .action_container .scope_description_container {
  grid-column: 2/2;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.rule_container .action_container .scope_description_container .text {
  font-weight: bold;
  margin-bottom: 3px;
}
.rule_container .action_container .action_name_container {
  grid-column: 2/2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  margin-right: 10px;
}
.rule_container .action_container .action_name_container .text {
  font-weight: bold;
  margin-bottom: 3px;
}
.rule_container .action_container .action_description {
  grid-column: 3/3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: auto;
}
.rule_container .action_container .action_description .budget_params_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 30px;
}
.rule_container .action_container .action_description .budget_params_container .budget_amount_container {
  height: 100%;
  margin-right: 10px;
}
.rule_container .action_container .action_description .budget_params_container .budget_amount_container input {
  height: 100%;
  border: 2px solid #eee;
  border-radius: 3px;
  text-align: right;
  font-size: 14px;
  padding-right: 5px;
  width: 100px;
}
.rule_container .action_container .action_description .budget_params_container .budget_amount_container input:focus {
  outline: none;
}
.rule_container .action_container .action_description .budget_params_container .budget_type_dropdown_container {
  height: 100%;
}
.rule_container .action_container .action_description .budget_params_container .budget_type_dropdown_container .budget_type_container {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 0px 10px;
  border: 2px solid #eee;
  border-radius: 3px;
  width: 60px;
}
.rule_container .action_container .actions_dropdown {
  position: absolute;
  width: 190px;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  border: 1px solid #eee;
  border-radius: 3px;
  background-color: #fff;
  z-index: 9999;
  top: 40px;
}
.rule_container .action_container .actions_dropdown .metric_field {
  padding: 10px 10px;
  cursor: pointer;
}
.rule_container .action_container .actions_dropdown .metric_field:hover {
  background-color: #eee;
}

.user_accounts_view {
  font-size: 12px;
  height: 100%;
  overflow: scroll;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
}
.user_accounts_view .user_accounts_table_container {
  margin-top: 20px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.user_accounts_view .user_accounts_table_container::-webkit-scrollbar {
  display: none;
}
.user_accounts_view .user_accounts_table_container .user_search_bar_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 30px;
  background-color: #fafafa;
  width: 100%;
  border-radius: 5px;
  margin-bottom: 10px;
}
.user_accounts_view .user_accounts_table_container .user_search_bar_container .icon {
  width: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.user_accounts_view .user_accounts_table_container .user_search_bar_container .search_input {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-right: 20px;
  height: 30px;
}
.user_accounts_view .user_accounts_table_container .user_search_bar_container .search_input input {
  background: transparent;
  border: none;
  width: 100%;
  font-size: 14px;
}
.user_accounts_view .user_accounts_table_container .user_search_bar_container .search_input input:focus {
  outline: none;
}
.user_accounts_view .user_accounts_table_container .user_accounts_list_container {
  margin-top: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.user_accounts_view .user_accounts_table_container .user_accounts_list_container .table_container {
  overflow: scroll;
  height: 100vh;
  overflow-x: scroll;
  overflow-y: visible;
  padding: 0;
  margin-bottom: 20px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.user_accounts_view .user_accounts_table_container .user_accounts_list_container .table_container::-webkit-scrollbar {
  display: none;
}
.user_accounts_view .user_accounts_table_container .user_accounts_list_container .table_container table {
  font-family: "Inter", sans-serif !important;
  font-size: 125%;
  white-space: nowrap;
  margin: 0;
  border: none;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  border: 1px solid black;
}
.user_accounts_view .user_accounts_table_container .user_accounts_list_container .table_container table td,
.user_accounts_view .user_accounts_table_container .user_accounts_list_container .table_container table th {
  border: 1px solid black;
  padding: 0.5rem 1rem;
}
.user_accounts_view .user_accounts_table_container .user_accounts_list_container .table_container table thead th {
  padding: 3px;
  position: sticky;
  top: 0;
  z-index: 1;
  width: 25vw;
  background: white;
}
.user_accounts_view .user_accounts_table_container .user_accounts_list_container .table_container table td {
  background: #fff;
  padding: 10px 50px;
  text-align: center;
}
.user_accounts_view .user_accounts_table_container .user_accounts_list_container .table_container table tbody th {
  font-weight: 100;
  font-style: italic;
  text-align: left;
  position: relative;
}
.user_accounts_view .user_accounts_table_container .user_accounts_list_container .table_container table thead th:first-child {
  position: sticky;
  left: 0;
  z-index: 3;
}
.user_accounts_view .user_accounts_table_container .user_accounts_list_container .table_container table tbody td:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
}
.user_accounts_view .user_accounts_table_container .user_accounts_list_container .table_container table tbody th {
  position: sticky;
  left: 0;
  background: white;
  z-index: 1;
}

.tracking_code_view {
  font-size: 12px;
  display: grid;
  grid-template-columns: auto 900px auto;
  grid-template-rows: 50px auto;
}
.tracking_code_view .content {
  grid-column: 2/2;
  grid-row: 2/2;
}
.tracking_code_view .content .description_container {
  font-size: 18px;
  margin: 10px 0px 30px 0px;
  display: flex;
  flex-direction: row;
}
.tracking_code_view .content .description_container .js_logo {
  width: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 3px;
}
.tracking_code_view .content .description_container .heading span {
  font-weight: bold;
}
.tracking_code_view .content .description_container .description {
  margin: 10px 0px;
  font-size: 14px;
}
.tracking_code_view .content .code_snippet_container {
  overflow-x: scroll;
  border-radius: 5px;
  border: 1px solid #eee;
  padding: 5px;
  padding-top: 0px;
  background: #fdfdfd;
  margin-bottom: 15px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.tracking_code_view .content .code_snippet_container ::-webkit-scrollbar {
  display: none;
}
.tracking_code_view .content .copy_to_clipboard_container {
  width: 150px;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: #2196f3;
  color: #fff;
  cursor: pointer;
}
.tracking_code_view .content .copy_to_clipboard_container .button {
  display: flex;
  flex-direction: row;
}
.tracking_code_view .content .copy_to_clipboard_container .button .icon {
  margin-right: 10px;
}
.tracking_code_view .content .status_container {
  margin-top: 30px;
  border-top: 1px solid #eee;
  display: flex;
  flex-direction: column;
  height: 350px;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}
.tracking_code_view .content .status_container .icon {
  font-size: 30px;
}
.tracking_code_view .content .status_container .check_status_button {
  margin-top: 10px;
  border: 1px solid black;
  padding: 10px 20px;
  border-radius: 3px;
  border-style: dashed;
  cursor: pointer;
}
.tracking_code_view .content .status_container div {
  margin: 5px 0px;
}

.hljs {
  background-color: transparent;
  margin-top: -10px;
}

.shopify_settings_view {
  font-size: 12px;
}
.shopify_settings_view .connect_shopify_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.shopify_settings_view .connect_shopify_container .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}
.shopify_settings_view .connect_shopify_container .content .shop_url_input_container {
  margin-bottom: 20px;
}
.shopify_settings_view .connect_shopify_container .content .shop_url_input_container input {
  width: 500px;
  height: 50px;
  border: 1px solid #eee;
  padding-left: 10px;
}
.shopify_settings_view .connect_shopify_container .content .connect_button_container {
  width: 500px;
  height: 50px;
  cursor: pointer;
  background-color: #95bf46;
  border: 2px solid #5e8e3d;
  border-radius: 5px;
}
.shopify_settings_view .connect_shopify_container .content .connect_button_container .button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.shopify_settings_view .settings_container {
  margin-top: 15px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}
.shopify_settings_view .settings_container .settings_nav_container {
  width: 200px;
  padding: 10px;
  margin-right: 20px;
}
.shopify_settings_view .settings_container .settings_nav_container .nav {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.shopify_settings_view .settings_container .settings_nav_container .nav .nav_item {
  display: flex;
  flex-direction: column;
  height: 30px;
  width: 100%;
  background-color: #ebf0ff;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  border-radius: 5px;
  cursor: pointer;
  color: #2196f3;
}
.shopify_settings_view .settings_container .content_container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.shopify_settings_view .settings_container .content_container .content .title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 30px;
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
}
.shopify_settings_view .settings_container .content_container .setting_item_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 40px;
}
.shopify_settings_view .settings_container .content_container .setting_item_container .label {
  font-size: 14px;
  margin-bottom: 15px;
}
.shopify_settings_view .settings_container .content_container .setting_item_container .value {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.shopify_settings_view .settings_container .content_container .setting_item_container .value .read_only_input {
  display: flex;
  flex-direction: column;
  width: 100400;
  height: 45px;
  justify-content: center;
  border: 1px solid #eee;
  padding-left: 20px;
  font-size: 20px;
  border-radius: 5px;
}
.shopify_settings_view .settings_container .content_container .setting_item_container .value .switch_input {
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.shopify_settings_view .settings_container .content_container .setting_item_container .value .switch_input .switch {
  margin-right: 20px;
}
.shopify_settings_view .settings_container .content_container .integration_action_buttons_container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.shopify_settings_view .settings_container .content_container .integration_action_buttons_container .integration_button_container {
  display: flex;
  flex-direction: row;
  height: 40px;
  width: 200px;
  border: 1px solid #eee;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
}
.shopify_settings_view .settings_container .content_container .integration_action_buttons_container .integration_button_container .icon {
  margin-right: 10px;
}
.shopify_settings_view .settings_container .content_container .integration_action_buttons_container .delete {
  background-color: #fe3e3e;
  color: #fff;
}

.onboarding_view {
  font-size: 12px;
  overflow: hidden;
}
.onboarding_view .schedule_setup_call_container {
  position: fixed;
  right: 50px;
  top: 80px;
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  padding: 10px;
  line-height: 1.2;
  border-radius: 3px;
  background-color: #fff;
}
.onboarding_view .schedule_setup_call_container .text_row {
  font-size: 14px;
  margin-bottom: 10px;
}
.onboarding_view .schedule_setup_call_container .cta {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 30px;
  background-color: #57e480;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
}
.onboarding_view .onboarding_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  overflow: scroll;
}
.onboarding_view .onboarding_container .onboarding_inner_container {
  display: flex;
  flex-direction: column;
  width: 700px;
  border-radius: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  padding: 20px;
  padding-top: 10px;
  background-color: #fff;
}
.onboarding_view .onboarding_container .onboarding_inner_container .onboarding_header {
  display: grid;
  grid-template-columns: 35px auto;
  margin-top: 19px;
  cursor: pointer;
}
.onboarding_view .onboarding_container .onboarding_inner_container .onboarding_header .step_container {
  grid-column: 1/1;
  display: flex;
  flex-direction: column;
  width: 35px;
  height: 35px;
  align-items: center;
  justify-content: center;
  border: 2px solid #349eff;
  border-radius: 1000px;
}
.onboarding_view .onboarding_container .onboarding_inner_container .onboarding_header .step_container .step_count {
  color: #349eff;
}
.onboarding_view .onboarding_container .onboarding_inner_container .onboarding_header .description_container {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  margin-left: 15px;
  font-size: 16px;
  font-weight: 500;
}
.onboarding_view .onboarding_container .onboarding_inner_container .onboarding_header .description_container .title {
  color: #474747;
}
.onboarding_view .onboarding_container .onboarding_inner_container .onboarding_header .description_container .step_progress_container {
  display: flex;
  flex-direction: row;
  height: 25px;
  align-items: center;
  border: 1px solid #eee;
  border-bottom: 2px solid #eee;
  border-radius: 5px;
  font-size: 12px;
}
.onboarding_view .onboarding_container .onboarding_inner_container .onboarding_header .description_container .step_progress_container .step {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30px;
  border-right: 1px solid #eee;
}
.onboarding_view .onboarding_container .onboarding_inner_container .onboarding_header .description_container .step_progress_container .step:last-of-type {
  border-right: none;
}
.onboarding_view .onboarding_container .onboarding_inner_container .onboarding_panel_container {
  display: grid;
  grid-template-columns: 35px auto;
  margin-bottom: -20px;
}
.onboarding_view .onboarding_container .onboarding_inner_container .onboarding_panel_container .step_line_container {
  grid-column: 1/1;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
}
.onboarding_view .onboarding_container .onboarding_inner_container .onboarding_panel_container .step_line_container .line {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 2px;
  background-color: #349eff;
}
.onboarding_view .onboarding_container .onboarding_inner_container .onboarding_panel_container .content {
  grid-column: 2/2;
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  width: 100%;
  overflow: hidden;
  padding-right: 15px;
}

.carousel_container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  margin-top: 10px;
}
.carousel_container .carousel_view_container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.carousel_container .carousel_view_container .select_container {
  display: flex;
  flex-direction: column;
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 0px 0px;
  background-color: #fff;
  width: 100%;
}
.carousel_container .carousel_view_container .select_container .select_item_container {
  display: grid;
  grid-template-columns: 25px 250px auto;
  padding-left: 10px;
  cursor: pointer;
  align-items: center;
  border-bottom: 1px solid #eee;
  height: 50px;
  width: 100%;
}
.carousel_container .carousel_view_container .select_container .select_item_container .right_adjusted {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.carousel_container .carousel_view_container .select_container .select_item_container .right_adjusted .logo {
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
}
.carousel_container .carousel_view_container .select_container .select_item_container:last-of-type {
  border-bottom: none;
}

.connect_to_fb_container {
  height: 100%;
  width: 100%;
  margin-top: 10px;
}
.connect_to_fb_container .connect_to_fb_button {
  background-color: #5c79ff;
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 50px;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
}
.connect_to_fb_container .connect_to_fb_button a {
  color: #fff;
}

.connect_to_payment_processor_container {
  height: 100%;
  width: 100%;
  margin-top: 10px;
}
.connect_to_payment_processor_container .show_video_container {
  display: flex;
  flex-direction: column;
  height: 40px;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #eee;
}
.connect_to_payment_processor_container .connect_to_stripe_button {
  background-color: #5c79ff;
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 50px;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
}

.webhooks_container {
  display: flex;
  flex-direction: column;
  margin: 20px 0px;
}
.webhooks_container .title {
  margin-bottom: 10px;
  font-size: 14px;
}
.webhooks_container .webhooks_url_container {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  background-color: #fdfdfd;
  border-radius: 5px;
  padding: 5px;
  padding-left: 20px;
  border: 1px solid #eee;
  min-height: 40px;
  line-height: 1.3em;
}
.webhooks_container .copy_code_snippet_to_clipboard_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #5c79ff;
  width: 150px;
  margin-top: 10px;
  height: 40px;
  border-radius: 5px;
  cursor: pointer;
}

.tracking_code_container {
  height: 100%;
  width: 100%;
  margin: 20px 0px;
}
.tracking_code_container .title {
  margin-bottom: 10px;
  font-size: 14px;
}
.tracking_code_container .tracking_code {
  background-color: #fdfdfd;
  border-radius: 5px;
  padding: 5px;
  padding-left: 0px;
  border: 1px solid #eee;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.tracking_code_container .tracking_code ::-webkit-scrollbar {
  display: none;
}
.tracking_code_container .copy_code_snippet_to_clipboard_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #5c79ff;
  width: 150px;
  margin-top: 10px;
  height: 40px;
  border-radius: 5px;
  cursor: pointer;
}

.ad_dynamic_urls_container {
  height: 100%;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
}

.facebook_accounts_container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.facebook_accounts_container .facebook_accounts_search_filter_container {
  display: flex;
  flex-direction: row;
  height: 35px;
  width: 100%;
}
.facebook_accounts_container .facebook_accounts_search_filter_container input {
  height: 100%;
  width: 100%;
  padding-left: 10px;
  border: 1px solid #eee;
  border-bottom: 2px solid #eee;
  border-radius: 5px;
  font-size: 14px;
}
.facebook_accounts_container .loading_fb_accounts_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.facebook_accounts_container .loading_fb_accounts_container .loading_icon_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.facebook_accounts_container .loading_fb_accounts_container .loading_icon_container .text {
  font-size: 16px;
  margin-bottom: 10px;
}
.facebook_accounts_container .loading_fb_accounts_container .loading_icon_container .icon {
  font-size: 30px;
}
.facebook_accounts_container .facebook_accounts_list_container {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  height: 300px;
  overflow: scroll;
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 5px 0px;
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}
.facebook_accounts_container .facebook_accounts_list_container::-webkit-scrollbar {
  display: none !important;
}
.facebook_accounts_container .facebook_accounts_list_container .account {
  display: grid;
  grid-template-columns: 30px auto;
  align-items: center;
  margin: 7px 0px;
  padding-left: 10px;
  cursor: pointer;
}
.facebook_accounts_container .facebook_accounts_list_container .account .name {
  grid-column: 2/2;
  font-size: 14px;
}

.done_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #eee;
  border-radius: 5px;
}
.done_container .step {
  display: grid;
  grid-template-columns: auto 150px;
  border-bottom: 1px solid #eee;
  grid-template-rows: 50px;
  align-items: center;
}
.done_container .step .name {
  padding-left: 20px;
}
.done_container .step .status {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 20px;
  font-size: 16px;
}
.done_container .step:last-of-type {
  border-bottom: none;
}

.onboarding_status_button {
  display: flex;
  flex-direction: row;
  width: 700px;
  margin-top: 20px;
}
.onboarding_status_button .success_status_button {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 50px;
  font-size: 16px;
  background-color: #5c79ff;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}
.onboarding_status_button .disabled_status_button {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 50px;
  font-size: 16px;
  background-color: #f0f0f0;
  color: #d6d6d6;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid #d6d6d6;
}

.initial_video_container {
  display: flex;
  justify-items: center;
  justify-content: center;
  height: 100%;
}
.initial_video_container .video_container {
  width: 800px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.initial_video_container .video_container .close_video_button {
  margin-top: 20px;
  background-color: #5c79ff;
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50px;
  border-radius: 5px;
  cursor: pointer;
  color: #ffff;
}

.initial_video_headline {
  margin-bottom: 30px;
  font-weight: bold;
  font-size: 30px;
  text-align: center;
}

.button_delay_notification {
  margin-top: 20px;
}

.ad_launcher_view {
  width: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.ad_launcher_view .view_header {
  display: flex;
  flex-direction: column;
  height: 50px;
  border-bottom: 1px solid #eee;
  justify-content: center;
  padding-left: 10px;
}
.ad_launcher_view .view_header .title {
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ad_launcher_view .ad_launcher_content {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}
.ad_launcher_view .ad_launcher_content .ads_left_nav {
  min-width: 300px;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #eee;
  height: 100%;
}
.ad_launcher_view .ad_launcher_content .ads_left_nav .left_nav_assets_container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.ad_launcher_view .ad_launcher_content .ads_left_nav .left_nav_assets_container .ad_launcher_nav_campaign_container {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #eee;
  font-size: 14px;
  height: 100%;
  overflow: hidden;
}
.ad_launcher_view .ad_launcher_content .ads_left_nav .left_nav_assets_container .ad_launcher_nav_campaign_container:last-child {
  border-bottom: none;
}
.ad_launcher_view .ad_launcher_content .ads_left_nav .left_nav_assets_container .ad_launcher_nav_campaign_container .new_campaign_asset_header {
  display: flex;
  flex-direction: row;
  height: 30px;
  width: 100%;
  align-items: center;
  padding-left: 10px;
  cursor: pointer;
}
.ad_launcher_view .ad_launcher_content .ads_left_nav .left_nav_assets_container .ad_launcher_nav_campaign_container .new_campaign_asset_header .icon {
  margin-right: 15px;
}
.ad_launcher_view .ad_launcher_content .ads_left_nav .left_nav_assets_container .ad_launcher_nav_campaign_container .new_campaign_asset_header .title {
  margin-right: 20px;
}
.ad_launcher_view .ad_launcher_content .ads_left_nav .left_nav_assets_container .ad_launcher_nav_campaign_container .campaign_asset_header {
  display: flex;
  flex-direction: row;
  height: 30px;
  width: 100%;
  align-items: center;
  padding-left: 10px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}
.ad_launcher_view .ad_launcher_content .ads_left_nav .left_nav_assets_container .ad_launcher_nav_campaign_container .campaign_asset_header .icon {
  margin-right: 15px;
}
.ad_launcher_view .ad_launcher_content .ads_left_nav .left_nav_assets_container .ad_launcher_nav_campaign_container .campaign_asset_header .title {
  margin-right: 20px;
}
.ad_launcher_view .ad_launcher_content .ads_left_nav .left_nav_assets_container .ad_launcher_nav_campaign_container .campaign_asset_header .nav_asset_actions {
  display: flex;
  flex-direction: row;
  margin-left: auto;
}
.ad_launcher_view .ad_launcher_content .ads_left_nav .left_nav_assets_container .ad_launcher_nav_campaign_container .campaign_asset_header .nav_asset_actions .action_container {
  display: flex;
  flex-direction: column;
  width: 30px;
  height: 100%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.ad_launcher_view .ad_launcher_content .ads_left_nav .left_nav_assets_container .ad_launcher_nav_campaign_container .ad_launcher_nav_adsets_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: scroll;
  height: 100%;
  padding-bottom: 50px;
  margin-top: 5px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.ad_launcher_view .ad_launcher_content .ads_left_nav .left_nav_assets_container .ad_launcher_nav_campaign_container .ad_launcher_nav_adsets_container::-webkit-scrollbar {
  display: none;
}
.ad_launcher_view .ad_launcher_content .ads_left_nav .left_nav_assets_container .ad_launcher_nav_campaign_container .ad_launcher_nav_adsets_container .new_adset_container {
  display: flex;
  flex-direction: row;
  padding-left: 20px;
  border-bottom: 1px solid #eee;
  min-height: 25px;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
}
.ad_launcher_view .ad_launcher_content .ads_left_nav .left_nav_assets_container .ad_launcher_nav_campaign_container .ad_launcher_nav_adsets_container .new_adset_container .asset_icon {
  margin-right: 15px;
}
.ad_launcher_view .ad_launcher_content .ads_left_nav .left_nav_assets_container .ad_launcher_nav_campaign_container .ad_launcher_nav_adsets_container .new_adset_container .add_icon {
  width: 22px;
  margin-left: auto;
}
.ad_launcher_view .ad_launcher_content .ads_left_nav .left_nav_assets_container .ad_launcher_nav_campaign_container .ad_launcher_nav_adsets_container .ad_launcher_nav_adset_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 7px;
}
.ad_launcher_view .ad_launcher_content .ads_left_nav .left_nav_assets_container .ad_launcher_nav_campaign_container .ad_launcher_nav_adsets_container .ad_launcher_nav_adset_container .adset_asset_header {
  display: flex;
  flex-direction: row;
  height: 25px;
  width: 100%;
  align-items: center;
  padding-left: 20px;
  font-size: 12px;
  cursor: pointer;
}
.ad_launcher_view .ad_launcher_content .ads_left_nav .left_nav_assets_container .ad_launcher_nav_campaign_container .ad_launcher_nav_adsets_container .ad_launcher_nav_adset_container .adset_asset_header .icon {
  margin-right: 15px;
}
.ad_launcher_view .ad_launcher_content .ads_left_nav .left_nav_assets_container .ad_launcher_nav_campaign_container .ad_launcher_nav_adsets_container .ad_launcher_nav_adset_container .adset_asset_header .title {
  margin-right: 20px;
}
.ad_launcher_view .ad_launcher_content .ads_left_nav .left_nav_assets_container .ad_launcher_nav_campaign_container .ad_launcher_nav_adsets_container .ad_launcher_nav_adset_container .adset_asset_header .nav_asset_actions {
  display: flex;
  flex-direction: row;
  margin-left: auto;
}
.ad_launcher_view .ad_launcher_content .ads_left_nav .left_nav_assets_container .ad_launcher_nav_campaign_container .ad_launcher_nav_adsets_container .ad_launcher_nav_adset_container .adset_asset_header .nav_asset_actions .action_container {
  display: flex;
  flex-direction: column;
  width: 30px;
  height: 100%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.ad_launcher_view .ad_launcher_content .ads_left_nav .left_nav_assets_container .ad_launcher_nav_campaign_container .ad_launcher_nav_adsets_container .ad_launcher_nav_adset_container .ad_launcher_nav_ads_container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.ad_launcher_view .ad_launcher_content .ads_left_nav .left_nav_assets_container .ad_launcher_nav_campaign_container .ad_launcher_nav_adsets_container .ad_launcher_nav_adset_container .ad_launcher_nav_ads_container .ad_launcher_nav_ad_container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.ad_launcher_view .ad_launcher_content .ads_left_nav .left_nav_assets_container .ad_launcher_nav_campaign_container .ad_launcher_nav_adsets_container .ad_launcher_nav_adset_container .ad_launcher_nav_ads_container .ad_launcher_nav_ad_container .ad_asset {
  display: flex;
  flex-direction: row;
  height: 25px;
  width: 100%;
  align-items: center;
  padding-left: 30px;
  font-size: 12px;
  cursor: pointer;
}
.ad_launcher_view .ad_launcher_content .ads_left_nav .left_nav_assets_container .ad_launcher_nav_campaign_container .ad_launcher_nav_adsets_container .ad_launcher_nav_adset_container .ad_launcher_nav_ads_container .ad_launcher_nav_ad_container .ad_asset .icon {
  margin-right: 15px;
}
.ad_launcher_view .ad_launcher_content .ads_left_nav .left_nav_assets_container .ad_launcher_nav_campaign_container .ad_launcher_nav_adsets_container .ad_launcher_nav_adset_container .ad_launcher_nav_ads_container .ad_launcher_nav_ad_container .ad_asset .title {
  margin-right: 20px;
}
.ad_launcher_view .ad_launcher_content .ad_creation_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 20px;
  overflow: scroll;
  position: relative;
  overflow: hidden;
  margin-top: 0px;
}
.ad_launcher_view .ad_launcher_content .ad_creation_container .content {
  display: flex;
  flex-direction: column;
  width: 700px;
  margin-bottom: 80px;
  padding-top: 10px;
  overflow-x: hidden;
  overflow-y: scroll;
}
.ad_launcher_view .ad_launcher_content .ad_creation_container .content::-webkit-scrollbar {
  display: none;
}
.ad_launcher_view .ad_launcher_content .ad_creation_container .content .ad_creation_content_container {
  display: flex;
  flex-direction: column;
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px;
}
.ad_launcher_view .ad_launcher_content .ad_creation_container .content .ad_creation_content_container .campaign_objective_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.ad_launcher_view .ad_launcher_content .ad_creation_container .content .ad_creation_content_container .content_container {
  margin: 10px 0px;
}
.ad_launcher_view .ad_launcher_content .ad_creation_container .content .ad_creation_content_container .content_container .content_header {
  margin: 10px 0px;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}
.ad_launcher_view .ad_launcher_content .ad_creation_container .content .ad_creation_content_container .content_container .content_header .title {
  font-weight: bold;
}
.ad_launcher_view .ad_launcher_content .ad_creation_container .content .ad_creation_content_container .content_container .gender_container {
  display: flex;
  flex-direction: row;
}
.ad_launcher_view .ad_launcher_content .ad_creation_container .content .ad_creation_content_container .content_container .gender_container .option {
  display: flex;
  flex-direction: row;
  margin-right: 10px;
}
.ad_launcher_view .ad_launcher_content .ad_creation_container .content .ad_creation_content_container .content_container .gender_container .option .radio {
  margin-right: 10px;
}
.ad_launcher_view .ad_launcher_content .ad_creation_container .content .ad_creation_content_container .content_container .gender_container .option .radio input {
  cursor: pointer;
}
.ad_launcher_view .ad_launcher_content .ad_creation_container .content .ad_creation_content_container .content_container .content_body_container {
  display: flex;
  flex-direction: row;
}
.ad_launcher_view .ad_launcher_content .ad_creation_container .content .ad_creation_content_container .content_container .content_body_container .set_end_date_container {
  margin-bottom: 15px;
}
.ad_launcher_view .ad_launcher_content .ad_creation_container .content .ad_creation_content_container .content_container .content_body_container .calendar_date_container {
  margin-right: 20px;
}
.ad_launcher_view .ad_launcher_content .ad_creation_container .content .ad_creation_content_container .content_container .content_body_container .age_input_container {
  margin-right: 20px;
}
.ad_launcher_view .ad_launcher_content .ad_creation_container .content .ad_creation_content_container .content_container .content_body_container .select_container {
  width: 230px;
}
.ad_launcher_view .ad_launcher_content .ad_creation_container .content .ad_creation_content_container .content_container .content_body_container .age_divider_container {
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ad_launcher_view .ad_launcher_content .ad_creation_container .content .ad_creation_content_container .content_container .content_body_container .media_container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.ad_launcher_view .ad_launcher_content .ad_creation_container .content .ad_creation_content_container .content_container .content_body_container .media_container .creative_container {
  display: flex;
  flex-direction: column;
  margin: 20px 0px;
}
.ad_launcher_view .ad_launcher_content .ad_creation_container .content .ad_creation_content_container .content_container .content_body_container .media_container .creative_container .image_action_buttons_container {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
}
.ad_launcher_view .ad_launcher_content .ad_creation_container .content .ad_creation_content_container .content_container .content_body_container .media_container .creative_container .image_action_buttons_container .image_action_button {
  margin-right: 10px;
  background-color: #f5f5f5;
  padding: 10px 15px;
  border-radius: 3px;
}
.ad_launcher_view .ad_launcher_content .ad_creation_container .content .ad_creation_content_container .content_container .content_body_container .media_container .creative_action_buttons {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.ad_launcher_view .ad_launcher_content .ad_creation_container .content .ad_creation_content_container .content_container .content_body_container .media_container .creative_action_buttons .action {
  display: flex;
  flex-direction: row;
  margin-right: 20px;
  cursor: pointer;
  background-color: #f5f5f5;
  padding: 10px 15px;
  border-radius: 3px;
}
.ad_launcher_view .ad_launcher_content .ad_creation_container .content .ad_creation_content_container .content_container .content_body_container .media_container .icon {
  margin-right: 10px;
}
.ad_launcher_view .ad_launcher_content .ad_creation_container .content .ad_creation_content_container .content_container .content_body_container .budget_type {
  margin-right: 20px;
}
.ad_launcher_view .ad_launcher_content .ad_creation_container .content .ad_creation_content_container .content_container .select_container {
  display: flex;
  flex-direction: row;
}
.ad_launcher_view .ad_launcher_content .ad_creation_container .content .ad_creation_content_container .content_container .select_container .ant-select {
  width: 100%;
}
.ad_launcher_view .ad_launcher_content .ad_creation_container .content .ad_creation_content_container .header {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #eee;
  height: 50px;
  width: 100%;
  justify-content: space-between;
}
.ad_launcher_view .ad_launcher_content .ad_creation_container .content .ad_creation_content_container .header .header_action {
  margin-right: 10px;
  font-size: 20px;
}
.ad_launcher_view .ad_launcher_content .ad_creation_container .content .ad_creation_content_container .header .title {
  font-size: 20px;
  display: flex;
}
.ad_launcher_view .ad_launcher_content .ad_creation_container .content .ad_creation_content_container .content {
  display: flex;
  flex-direction: row;
  margin: 15px 0px;
}
.ad_launcher_view .ad_launcher_content .ad_creation_container .content .ad_creation_content_container .content .budget_type_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: 25px;
}
.ad_launcher_view .ad_launcher_content .ad_creation_container .content .ad_creation_content_container .content .asset_name_container {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-right: 25px;
}
.ad_launcher_view .ad_launcher_content .ad_creation_container .content .ad_creation_content_container .content .asset_name_container input {
  border: none;
  font-size: 16px;
  width: 100%;
}
.ad_launcher_view .ad_launcher_content .ad_creation_container .content .ad_creation_content_container .content .asset_name_container input:focus {
  outline: none;
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ad_launcher_view .ad_launcher_content .ad_creation_container .content .ad_creation_content_container .content .objective_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.ad_launcher_view .ad_launcher_content .ad_creation_container .content .ad_creation_content_container .content .objective_container .tite {
  display: flex;
  flex-direction: column;
  height: 30px;
  font-weight: bold;
}
.ad_launcher_view .ad_launcher_content .ad_creation_container .content .ad_creation_content_container .content .objective_container .options {
  display: flex;
  flex-direction: column;
}
.ad_launcher_view .ad_launcher_content .ad_creation_container .content .ad_creation_content_container .content .objective_container .options .option_row {
  display: flex;
  flex-direction: row;
  height: 40px;
  align-items: center;
}
.ad_launcher_view .ad_launcher_content .ad_creation_container .content .ad_creation_content_container .content .objective_container .options .option_row .radio {
  width: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ad_launcher_view .ad_launcher_content .ad_creation_container .content .ad_creation_content_container .content .objective_container .options .option_row .radio input {
  cursor: pointer;
}
.ad_launcher_view .ad_launcher_content .ad_creation_container .content .ad_creation_content_container .campaign_budget_settings_container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.ad_launcher_view .ad_launcher_content .ad_creation_container .footer {
  display: flex;
  flex-direction: column;
  height: 50px;
  width: 100%;
  position: absolute;
  bottom: 41px;
  left: 0;
  border-top: 1px solid #eee;
  background-color: #fff;
}

.footer_actions_container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: end;
  width: 100%;
}
.footer_actions_container .create_campaign_button {
  display: flex;
  flex-direction: column;
  height: 50px;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.audiences_view {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 20px;
  overflow: hidden;
}
.audiences_view .tabs {
  display: flex;
  flex-direction: row;
  min-height: 40px;
  border: 1px solid #eee;
  align-items: center;
}
.audiences_view .tabs .tab_container {
  display: flex;
  flex-direction: column;
  width: 200px;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #eee;
  height: 100%;
  cursor: pointer;
  color: #666;
}
.audiences_view .audiences_finder_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 20px;
}
.audiences_view .audiences_finder_container .search_header_container {
  display: flex;
  flex-direction: column;
  height: 40px;
  width: 100%;
  margin-bottom: 20px;
}
.audiences_view .audiences_finder_container .search_header_container .search_container {
  display: grid;
  grid-template-columns: auto 50px;
  grid-template-rows: 40px auto;
  height: 100%;
}
.audiences_view .audiences_finder_container .search_header_container .search_container .search_bar {
  grid-column: 1/1;
  grid-row: 1/1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.audiences_view .audiences_finder_container .search_header_container .search_container .search_bar input {
  height: 100%;
}
.audiences_view .audiences_finder_container .search_header_container .search_container .search_icon {
  grid-column: 2/2;
  grid-row: 1/1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.audiences_view .audiences_finder_container .audience_table_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #eee;
  min-height: 500px;
  overflow: hidden;
  margin-bottom: 60px;
}
.audiences_view .audiences_finder_container .audience_table_container .audience_table_tabs {
  display: grid;
  grid-template-columns: 450px 140px 120px 120px 220px auto;
  grid-template-rows: 40px;
  align-items: center;
}
.audiences_view .audiences_finder_container .audience_table_container .audience_table_tabs .tab_cell {
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  border-bottom: 1px solid #eee;
}
.audiences_view .audiences_finder_container .audience_table_container .audience_table_tabs .tab_cell:first-of-type {
  padding-left: 20px;
}
.audiences_view .audiences_finder_container .audience_table_container .audience_table_tabs .tab_cell:last-of-type {
  min-width: 100px;
}
.audiences_view .audiences_finder_container .audience_table_container .table_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.audiences_view .audiences_finder_container .audience_table_container .table_container::-webkit-scrollbar {
  display: none;
}
.audiences_view .audiences_finder_container .audience_table_container .table_container .name_audience_cell {
  padding-left: 20px;
}
.audiences_view .audiences_finder_container .audience_table_container .table_container .audience_row {
  display: grid;
  grid-template-columns: 450px 140px 120px 120px 220px auto;
  grid-template-rows: 40px;
  align-items: center;
  font-size: 14px;
}
.audiences_view .audiences_finder_container .audience_table_container .table_container .audience_row .audience_cell {
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  height: 40px;
  justify-content: center;
  border-bottom: 1px solid #eee;
}
.audiences_view .audiences_finder_container .audience_table_container .table_container .audience_row .age_row {
  display: flex;
  flex-direction: row;
}
.audiences_view .audiences_finder_container .audience_table_container .table_container .audience_row .age_row .age {
  width: 80px;
}
.audiences_view .audiences_finder_container .audience_table_container .table_container .audience_row .actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: 40px;
  align-items: center;
  border-bottom: 1px solid #eee;
  min-width: 100px;
}
.audiences_view .audiences_finder_container .audience_table_container .table_container .audience_row .actions .stats {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 20px;
  cursor: pointer;
}
.audiences_view .audiences_finder_container .audience_table_container .table_container .audience_row .actions .save {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 20px;
}
.audiences_view .audiences_finder_container .audience_table_container .table_container .audience_row .actions .save .icon {
  cursor: pointer;
}
.audiences_view .audiences_finder_container .audience_table_container .table_container .audience_row .actions .suggestions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 20px;
}
.audiences_view .audiences_finder_container .audience_table_container .table_container .audience_row .actions .suggestions .icon {
  cursor: pointer;
}

.column_title_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  min-height: 25px;
  justify-content: space-between;
  padding: 0px 5px;
  margin-top: 2px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}

.loading_view {
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
  z-index: 999;
  background-color: #fff;
  opacity: 0.6;
}
.loading_view .loading_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 40px;
}

.interst_insights_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0px 20px;
}
.interst_insights_container .interest_header {
  display: grid;
  grid-template-columns: auto 300px;
  grid-template-rows: 30px;
  width: 100%;
  border-bottom: 1px solid #eee;
  margin-bottom: 20px;
  padding-bottom: 10px;
}
.interst_insights_container .interest_header .left_adjusted {
  grid-column: 1/1;
  grid-row: 1/1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.interst_insights_container .interest_header .left_adjusted .interest_name {
  font-size: 25px;
}
.interst_insights_container .interest_header .right_adjusted {
  grid-column: 2/2;
  grid-row: 1/1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.interst_insights_container .interest_header .right_adjusted .size_container {
  display: flex;
  flex-direction: row;
  margin-right: 20px;
}
.interst_insights_container .interest_header .right_adjusted .size_container .icon {
  margin-right: 10px;
}
.interst_insights_container .full_container {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 400px;
  margin-bottom: 20px;
}
.interst_insights_container .full_container .full_outer_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.interst_insights_container .full_container .half_outer_container {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
}
.interst_insights_container .full_container .inner_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  border-radius: 5px;
  align-items: center;
}
.interst_insights_container .full_container .inner_container .chart_header {
  display: flex;
  flex-direction: column;
  min-height: 40px;
  width: 100%;
  border-bottom: 1px solid #eee;
  justify-content: center;
  font-weight: bold;
  padding-left: 20px;
}
.interst_insights_container .full_container .inner_container .chart_container {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 50px;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.interst_insights_container .full_container .inner_container .chart_container .chart {
  grid-row: 1/1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 80%;
  align-items: center;
  justify-content: center;
}

.labels_container {
  grid-row: 2/2;
  display: flex;
  flex-direction: row;
  height: 50px;
  align-items: center;
  justify-content: center;
}
.labels_container .label_container {
  width: 150px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  margin: 0px 5px;
  border-radius: 5px;
  margin-top: -20px;
}
.labels_container .label_container .name {
  display: flex;
  flex-direction: column;
  min-height: 20px;
  font-size: 12px;
  color: #666;
}
.labels_container .label_container .value {
  display: flex;
  flex-direction: column;
  min-height: 20px;
  font-size: 18px;
  font-weight: bold;
}

.bar_chart_label {
  font-weight: bold;
}

.ReactModal__Content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.ReactModal__Content .interst_insights_container {
  padding: 20px;
  display: flex;
  flex-direction: row;
  min-width: 100%;
  overflow: hidden;
}
.ReactModal__Content .interst_insights_container .modal_content {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: auto;
  padding-right: 20px;
  overflow: scroll;
  padding-bottom: 10px;
  margin-bottom: -20px;
  padding-left: 10px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.ReactModal__Content .interst_insights_container .modal_content::-webkit-scrollbar {
  display: none;
}
.ReactModal__Content .interst_insights_container .audience_close_modal_container {
  display: flex;
  flex-direction: column;
  width: 35px;
  cursor: pointer;
  font-size: 40px;
  color: #eee;
  height: 40px;
  cursor: pointer;
  margin-top: -5px;
}

.audiences_view {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 20px;
  overflow: hidden;
}
.audiences_view .tabs {
  display: flex;
  flex-direction: row;
  min-height: 40px;
  border: 1px solid #eee;
  align-items: center;
}
.audiences_view .tabs .tab_container {
  display: flex;
  flex-direction: column;
  width: 200px;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #eee;
  height: 100%;
  cursor: pointer;
  color: #666;
}
.audiences_view .audiences_finder_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 20px;
}
.audiences_view .audiences_finder_container .search_header_container {
  display: flex;
  flex-direction: column;
  height: 40px;
  width: 100%;
  margin-bottom: 20px;
}
.audiences_view .audiences_finder_container .search_header_container .search_container {
  display: grid;
  grid-template-columns: auto 50px;
  grid-template-rows: 40px auto;
  height: 100%;
}
.audiences_view .audiences_finder_container .search_header_container .search_container .search_bar {
  grid-column: 1/1;
  grid-row: 1/1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.audiences_view .audiences_finder_container .search_header_container .search_container .search_bar input {
  height: 100%;
}
.audiences_view .audiences_finder_container .search_header_container .search_container .search_icon {
  grid-column: 2/2;
  grid-row: 1/1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.audiences_view .audiences_finder_container .audience_table_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #eee;
  min-height: 500px;
  overflow: hidden;
  margin-bottom: 60px;
}
.audiences_view .audiences_finder_container .audience_table_container .table_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.audiences_view .audiences_finder_container .audience_table_container .table_container::-webkit-scrollbar {
  display: none;
}
.audiences_view .audiences_finder_container .audience_table_container .table_container .audience_table_tabs {
  display: grid;
  grid-template-columns: 450px 140px 120px 150px 230px auto;
  grid-template-rows: 40px;
  align-items: center;
}
.audiences_view .audiences_finder_container .audience_table_container .table_container .audience_table_tabs .tab_cell {
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  border-bottom: 1px solid #eee;
}
.audiences_view .audiences_finder_container .audience_table_container .table_container .audience_table_tabs .tab_cell:first-of-type {
  padding-left: 20px;
}
.audiences_view .audiences_finder_container .audience_table_container .table_container .audience_table_tabs .tab_cell:last-of-type {
  min-width: 100px;
}
.audiences_view .audiences_finder_container .audience_table_container .table_container .table_rows .advertiser_row {
  display: grid;
  grid-template-columns: 450px 140px 120px 150px 230px auto;
  grid-template-rows: 120px;
  font-size: 14px;
  min-height: 120px;
  max-height: 120px;
}
.audiences_view .audiences_finder_container .audience_table_container .table_container .table_rows .advertiser_row .name_audience_cell {
  padding-left: 20px;
}
.audiences_view .audiences_finder_container .audience_table_container .table_container .table_rows .advertiser_row .advertiser_cell {
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  min-height: 120px;
  max-height: 120px;
  justify-content: center;
  border-bottom: 1px solid #eee;
}
.audiences_view .audiences_finder_container .audience_table_container .table_container .table_rows .advertiser_row .name_audience_cell {
  display: flex;
  flex-direction: row;
  align-items: center !important;
  justify-content: flex-start;
}
.audiences_view .audiences_finder_container .audience_table_container .table_container .table_rows .advertiser_row .name_audience_cell .logo {
  display: flex;
  flex-direction: column;
  width: 40px;
  margin-right: 20px;
}
.audiences_view .audiences_finder_container .audience_table_container .table_container .table_rows .advertiser_row .name_audience_cell .logo img {
  border-radius: 100px;
}
.audiences_view .audiences_finder_container .audience_table_container .table_container .table_rows .advertiser_row .duration_column .duration_row {
  margin: 15px 0px;
}
.audiences_view .audiences_finder_container .audience_table_container .table_container .table_rows .advertiser_row .related_product_column {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 30px;
}
.audiences_view .audiences_finder_container .audience_table_container .table_container .table_rows .advertiser_row .related_product_column .logo {
  width: 20px;
  margin-right: 10px;
}
.audiences_view .audiences_finder_container .audience_table_container .table_container .table_rows .advertiser_row .related_product_column .logo img {
  border-radius: 100px;
}
.audiences_view .audiences_finder_container .audience_table_container .table_container .table_rows .advertiser_row .age_row {
  display: flex;
  flex-direction: row;
}
.audiences_view .audiences_finder_container .audience_table_container .table_container .table_rows .advertiser_row .age_row .age {
  width: 80px;
}
.audiences_view .audiences_finder_container .audience_table_container .table_container .table_rows .advertiser_row .actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: 100%;
  align-items: center;
  border-bottom: 1px solid #eee;
  min-width: 100px;
}
.audiences_view .audiences_finder_container .audience_table_container .table_container .table_rows .advertiser_row .actions .stats {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 20px;
  cursor: pointer;
}
.audiences_view .audiences_finder_container .audience_table_container .table_container .table_rows .advertiser_row .actions .save {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 20px;
}
.audiences_view .audiences_finder_container .audience_table_container .table_container .table_rows .advertiser_row .actions .save .icon {
  cursor: pointer;
}
.audiences_view .audiences_finder_container .audience_table_container .table_container .table_rows .advertiser_row .actions .suggestions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 20px;
}
.audiences_view .audiences_finder_container .audience_table_container .table_container .table_rows .advertiser_row .actions .suggestions .icon {
  cursor: pointer;
}

.column_title_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  min-height: 25px;
  justify-content: space-between;
  padding: 0px 5px;
  margin-top: 2px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}

.loading_view {
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
  z-index: 999;
  background-color: #fff;
  opacity: 0.6;
}
.loading_view .loading_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 40px;
}

.page_insights_container {
  display: grid;
  grid-template-columns: auto 50px;
  width: 100%;
  height: 100%;
}
.page_insights_container .interest_header {
  display: grid;
  grid-template-columns: auto 300px;
  grid-template-rows: 30px;
  width: 100%;
  border-bottom: 1px solid #eee;
  margin-bottom: 20px;
  padding-bottom: 10px;
}
.page_insights_container .interest_header .left_adjusted {
  grid-column: 1/1;
  grid-row: 1/1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.page_insights_container .interest_header .left_adjusted .interest_name {
  font-size: 25px;
}
.page_insights_container .interest_header .right_adjusted {
  grid-column: 2/2;
  grid-row: 1/1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.page_insights_container .interest_header .right_adjusted .size_container {
  display: flex;
  flex-direction: row;
  margin-right: 20px;
}
.page_insights_container .interest_header .right_adjusted .size_container .icon {
  margin-right: 10px;
}
.page_insights_container .full_container {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.page_insights_container .full_container .full_outer_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.page_insights_container .full_container .half_outer_container {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
}
.page_insights_container .full_container .inner_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  border-radius: 5px;
  align-items: center;
}
.page_insights_container .full_container .inner_container .chart_header {
  display: flex;
  flex-direction: column;
  min-height: 40px;
  width: 100%;
  border-bottom: 1px solid #eee;
  justify-content: center;
  font-weight: bold;
  padding-left: 20px;
}
.page_insights_container .full_container .inner_container .chart_container {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 50px;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.page_insights_container .full_container .inner_container .chart_container .chart {
  grid-row: 1/1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 80%;
  align-items: center;
  justify-content: center;
}

.labels_container {
  grid-row: 2/2;
  display: flex;
  flex-direction: row;
  height: 50px;
  align-items: center;
  justify-content: center;
}
.labels_container .label_container {
  width: 150px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  margin: 0px 5px;
  border-radius: 5px;
  margin-top: -20px;
}
.labels_container .label_container .name {
  display: flex;
  flex-direction: column;
  min-height: 20px;
  font-size: 12px;
  color: #666;
}
.labels_container .label_container .value {
  display: flex;
  flex-direction: column;
  min-height: 20px;
  font-size: 18px;
  font-weight: bold;
}

.bar_chart_label {
  font-weight: bold;
}

.ReactModal__Content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.ReactModal__Content .page_insights_container {
  padding: 20px;
  display: flex;
  flex-direction: row;
  min-width: 100%;
  overflow: hidden;
}
.ReactModal__Content .page_insights_container .modal_content {
  grid-column: 1/1;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: auto;
  padding-right: 20px;
  overflow: scroll;
  padding-bottom: 10px;
  margin-bottom: -20px;
  padding-left: 10px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.ReactModal__Content .page_insights_container .modal_content::-webkit-scrollbar {
  display: none;
}
.ReactModal__Content .page_insights_container .audience_close_modal_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  font-size: 40px;
  color: #eee;
  height: 40px;
  cursor: pointer;
  margin-top: -5px;
}

.facebook_ads_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
}
.facebook_ads_container .fb_ad_outline_container {
  display: flex;
  flex-direction: column;
  min-width: 350px;
  max-width: 350px;
  border-radius: 2px;
  margin: 0px 10px;
  margin-bottom: 20px;
  justify-content: center;
}
.facebook_ads_container .fb_ad_outline_container .page_header_container {
  display: grid;
  grid-template-columns: auto 50px;
  grid-template-rows: 80px;
  font-size: 14px;
  border-top: 1px solid #eee;
  border-right: 1px solid #eee;
  border-left: 1px solid #eee;
}
.facebook_ads_container .fb_ad_outline_container .page_header_container .left_adjusted {
  grid-column: 1/1;
  grid-row: 1/1;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.facebook_ads_container .fb_ad_outline_container .page_header_container .left_adjusted .page_avatar_container {
  display: flex;
  flex-direction: column;
  width: 60px;
  height: 100%;
  margin-right: 10px;
  align-items: center;
  justify-content: center;
}
.facebook_ads_container .fb_ad_outline_container .page_header_container .left_adjusted .page_avatar_container .avatar {
  display: flex;
  flex-direction: column;
  width: 50px;
  height: 50px;
  background-color: #666;
  border-radius: 100px;
}
.facebook_ads_container .fb_ad_outline_container .page_header_container .left_adjusted .page_info_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.facebook_ads_container .fb_ad_outline_container .page_header_container .left_adjusted .page_info_container .page_name {
  font-weight: bold;
}
.facebook_ads_container .fb_ad_outline_container .page_header_container .left_adjusted .page_info_container .post_type_container {
  font-size: 12px;
}
.facebook_ads_container .fb_ad_outline_container .page_header_container .left_adjusted .page_info_container div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 20px;
}
.facebook_ads_container .fb_ad_outline_container .page_header_container .right_adjusted {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.facebook_ads_container .fb_ad_outline_container .page_header_container .right_adjusted .actions_container {
  display: flex;
  flex-direction: column;
  height: 50px;
  width: 50px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.facebook_ads_container .fb_ad_outline_container .ad_headline_container {
  display: flex;
  flex-direction: column;
  height: 40px;
  background-color: red;
}
.facebook_ads_container .fb_ad_outline_container .ad_text_container {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  padding: 5px 5px 15px 5px;
  line-height: 1.5;
  border-right: 1px solid #eee;
  border-left: 1px solid #eee;
  color: #000;
  font-size: 13px;
}
.facebook_ads_container .fb_ad_outline_container .ad_content_container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-right: 1px solid #eee;
  border-left: 1px solid #eee;
}
.facebook_ads_container .fb_ad_outline_container .ad_content_container .content_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.facebook_ads_container .fb_ad_outline_container .ad_actions_container {
  display: grid;
  grid-template-columns: auto 150px;
  grid-template-rows: 60px;
  align-items: center;
  background-color: #f1f5f8;
  padding: 0px 10px;
  margin-top: -3px;
}
.facebook_ads_container .fb_ad_outline_container .ad_actions_container .left_adjusted {
  grid-column: 1/1;
}
.facebook_ads_container .fb_ad_outline_container .ad_actions_container .left_adjusted .link {
  display: flex;
  flex-direction: column;
  height: 20px;
  justify-content: center;
  font-size: 12px;
}
.facebook_ads_container .fb_ad_outline_container .ad_actions_container .left_adjusted .link_text {
  display: flex;
  flex-direction: column;
  height: 20px;
  justify-content: center;
  font-weight: bold;
  font-size: 14px;
}
.facebook_ads_container .fb_ad_outline_container .ad_actions_container .right_adjusted {
  grid-column: 2/2;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.facebook_ads_container .fb_ad_outline_container .ad_actions_container .right_adjusted .action_button {
  display: flex;
  flex-direction: row;
  border: 1px solid #ddd;
  height: 35px;
  align-items: center;
  justify-content: center;
  width: 100px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 3px;
  cursor: pointer;
}

.onboarding_two_container {
  font-size: 12px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  line-height: 1.3;
}
.onboarding_two_container .onboarding_container {
  display: grid;
  grid-template-columns: auto 800px auto;
  grid-template-rows: 50px auto;
  overflow: scroll;
  height: 100%;
}
.onboarding_two_container .onboarding_container .onboard_container {
  grid-column: 2/2;
  grid-row: 2/2;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.connect_to_fb_container {
  margin-bottom: 20px;
}

.video_card_container {
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 20px;
}
.video_card_container .title {
  font-size: 20px;
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
}
.video_card_container .video_container img {
  border-radius: 5px;
  border: 3px solid #000;
}
.video_card_container .video_container .icon {
  margin-top: 20px;
  font-size: 30px;
}

.onboarding_three_view {
  font-size: 12px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  line-height: 1.3;
}
.onboarding_three_view .onboarding_tabs {
  position: fixed;
  top: 20px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  color: #5c79ff;
  z-index: 2;
}
.onboarding_three_view .onboarding_tabs .tab {
  margin-right: 30px;
  cursor: pointer;
  padding-bottom: 3px;
}
.onboarding_three_view .onboarding_tabs .subnav {
  display: flex;
  flex-direction: row;
  position: fixed;
  top: 55px;
}
.onboarding_three_view .onboarding_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.onboarding_three_view .onboarding_container .slide_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.onboarding_three_view .onboarding_container .slide_container ::-webkit-scrollbar {
  display: none;
}
.onboarding_three_view .onboarding_container .slide_container .slide_content_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  text-align: left;
  margin-top: 100px;
  overflow: scroll;
  padding: 5px;
  width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.onboarding_three_view .onboarding_container .slide_container .slide_content_container ::-webkit-scrollbar {
  display: none;
}
.onboarding_three_view .onboarding_container .slide_container .slide_content_container .inner_content {
  display: flex;
  flex-direction: column;
  width: 800px;
}
.onboarding_three_view .onboarding_container .slide_container .slide_content_container .inner_content .next_section {
  background-color: #5c79ff;
  color: #fff;
  display: flex;
  flex-direction: column;
  min-height: 50px;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
  align-self: center;
}
.onboarding_three_view .onboarding_container .slide_container .slide_content_container .inner_content .connect_to_fb_button {
  background-color: #5c79ff;
  color: #fff;
  display: flex;
  flex-direction: column;
  min-height: 50px;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
}
.onboarding_three_view .onboarding_container .slide_container .slide_content_container .inner_content .connect_to_fb_button a {
  color: #fff;
}

.video_card_container {
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 20px;
  margin-top: 20px;
}
.video_card_container .title {
  font-size: 20px;
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
}
.video_card_container .video_container img {
  border-radius: 5px;
  border: 3px solid #000;
}
.video_card_container .video_container .icon {
  margin-top: 20px;
  font-size: 30px;
}

#root {
  width: 100%;
  height: 100%;
}

#app {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
}

.accounts_container {
  width: 400px;
  min-height: 500px;
  overflow-y: scroll;
  position: absolute;
  z-index: 999;
  right: 0px;
  top: 0px;
  border: 1px solid #eee;
  background-color: #fff;
  color: black;
}
.accounts_container .account_container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 30px;
  align-items: center;
  border-bottom: 1px solid black;
  padding-left: 20px;
  cursor: pointer;
  font-size: 12px;
}

.screen_modal_content {
  display: flex;
  flex-direction: column;
  min-width: 100%;
  min-height: 100%;
  overflow: hidden;
}

.ReactModal__Overlay {
  z-index: 4;
  transition: transform 100ms linear;
  transform-origin: center center;
  transform: scale(0.7);
}

.ReactModal__Overlay--after-open {
  transform: scale(1);
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content {
  display: flex;
  padding: 0px !important;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.ReactModal__Content .modal_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: aqua;
}

html,
body,
#main {
  font-family: helvetica;
  letter-spacing: 1.3px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
html::-webkit-scrollbar,
body::-webkit-scrollbar,
#main::-webkit-scrollbar {
  display: none;
}

.ant-btn-primary {
  background: #1890ff !important;
}
/*# sourceMappingURL=index.cc9dc26e.css.map */
