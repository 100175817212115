.ad_launcher_view {
    width: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .view_header {
        display: flex;
        flex-direction: column;
        height: 50px;
        border-bottom: 1px solid #eee;
        justify-content: center;
        padding-left: 10px;

        .title {
            height: 40px;
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }

    .ad_launcher_content {
        display: flex;
        flex-direction: row;
        height: 100%;
        width: 100%;

        .ads_left_nav {
            min-width: 300px;
            max-width: 300px;
            display: flex;
            flex-direction: column;
            border-right: 1px solid #eee;
            height: 100%;

            .left_nav_assets_container {
                display: flex;
                flex-direction: column;
                height: 100%;

                .ad_launcher_nav_campaign_container {
                    display: flex;
                    flex-direction: column;
                    border-bottom: 1px solid #eee;
                    font-size: 14px;
                    height: 100%;
                    overflow: hidden;

                    &:last-child {
                        border-bottom: none;
                    }

                    .new_campaign_asset_header {
                        display: flex;
                        flex-direction: row;
                        height: 30px;
                        width: 100%;
                        align-items: center;
                        padding-left: 10px;
                        cursor: pointer;

                        .icon {
                            margin-right: 15px;
                        }

                        .title {
                            margin-right: 20px;
                        }
                    }

                    .campaign_asset_header {
                        display: flex;
                        flex-direction: row;
                        height: 30px;
                        width: 100%;
                        align-items: center;
                        padding-left: 10px;
                        border-bottom: 1px solid #eee;
                        cursor: pointer;

                        .icon {
                            margin-right: 15px;
                        }

                        .title {
                            margin-right: 20px;
                        }

                        .nav_asset_actions {
                            display: flex;
                            flex-direction: row;
                            margin-left: auto;

                            .action_container {
                                display: flex;
                                flex-direction: column;
                                width: 30px;
                                height: 100%;
                                align-items: center;
                                justify-content: center;
                                cursor: pointer;
                            }
                        }
                    }

                    .ad_launcher_nav_adsets_container {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        overflow: scroll;
                        height: 100%;
                        padding-bottom: 50px;
                        margin-top: 5px;
                        -ms-overflow-style: none;
                        scrollbar-width: none;

                        &::-webkit-scrollbar {
                            display: none;
                        }

                        .new_adset_container {
                            display: flex;
                            flex-direction: row;
                            padding-left: 20px;
                            border-bottom: 1px solid #eee;
                            min-height: 25px;
                            align-items: center;
                            font-size: 12px;
                            cursor: pointer;

                            .asset_icon {
                                margin-right: 15px;
                            }

                            .add_icon {
                                width: 22px;
                                margin-left: auto;
                            }
                        }

                        .ad_launcher_nav_adset_container {
                            display: flex;
                            flex-direction: column;
                            width: 100%;
                            margin-bottom: 7px;

                            .adset_asset_header {
                                display: flex;
                                flex-direction: row;
                                height: 25px;
                                width: 100%;
                                align-items: center;
                                padding-left: 20px;
                                font-size: 12px;
                                cursor: pointer;

                                .icon {
                                    margin-right: 15px;
                                }

                                .title {
                                    margin-right: 20px;
                                }

                                .nav_asset_actions {
                                    display: flex;
                                    flex-direction: row;
                                    margin-left: auto;

                                    .action_container {
                                        display: flex;
                                        flex-direction: column;
                                        width: 30px;
                                        height: 100%;
                                        align-items: center;
                                        justify-content: center;
                                        cursor: pointer;
                                    }
                                }
                            }

                            .ad_launcher_nav_ads_container {
                                display: flex;
                                flex-direction: column;
                                width: 100%;

                                .ad_launcher_nav_ad_container {
                                    display: flex;
                                    flex-direction: column;
                                    width: 100%;

                                    .ad_asset {
                                        display: flex;
                                        flex-direction: row;
                                        height: 25px;
                                        width: 100%;
                                        align-items: center;
                                        padding-left: 30px;
                                        font-size: 12px;
                                        cursor: pointer;

                                        .icon {
                                            margin-right: 15px;
                                        }

                                        .title {
                                            margin-right: 20px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .ad_creation_container {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding-left: 20px;
            overflow: scroll;
            position: relative;
            overflow: hidden;
            margin-top: 0px;

            .content {
                display: flex;
                flex-direction: column;
                width: 700px;
                margin-bottom: 80px;
                padding-top: 10px;
                overflow-x: hidden;
                overflow-y: scroll;

                &::-webkit-scrollbar {
                    display: none;
                }

                .ad_creation_content_container {
                    display: flex;
                    flex-direction: column;
                    border: 1px solid #eee;
                    border-radius: 5px;
                    padding: 10px;
                    margin-bottom: 20px;

                    .campaign_objective_container {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        height: 100%;
                    }

                    .content_container {
                        margin: 10px 0px;

                        .content_header {
                            margin: 10px 0px;
                            display: flex;
                            flex-direction: column;
                            margin-bottom: 15px;

                            .title {
                                font-weight: bold;
                            }
                        }

                        .gender_container {
                            display: flex;
                            flex-direction: row;

                            .option {
                                display: flex;
                                flex-direction: row;
                                margin-right: 10px;

                                .radio {
                                    margin-right: 10px;

                                    input {
                                        cursor: pointer;
                                    }
                                }
                            }
                        }

                        .content_body_container {
                            display: flex;
                            flex-direction: row;

                            .set_end_date_container {
                                margin-bottom: 15px;
                            }

                            .calendar_date_container {
                                margin-right: 20px;
                            }

                            .age_input_container {
                                margin-right: 20px;
                            }

                            .select_container {
                                width: 230px;
                            }

                            .age_divider_container {
                                margin-right: 20px;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: center;
                            }

                            .media_container {
                                display: flex;
                                flex-direction: column;
                                width: 100%;
                                // margin-right: 20px;
                                // background-color: #f5f5f5;
                                // padding: 10px;
                                // border-radius: 3px;
                                // cursor: pointer;

                                .creative_container {
                                    display: flex;
                                    flex-direction: column;
                                    margin: 20px 0px;

                                    .image_action_buttons_container {
                                        margin-top: 20px;
                                        display: flex;
                                        flex-direction: row;

                                        .image_action_button {
                                            margin-right: 10px;
                                            background-color: #f5f5f5;
                                            padding: 10px 15px;
                                            border-radius: 3px;
                                        }
                                    }
                                }

                                .creative_action_buttons {
                                    display: flex;
                                    flex-direction: row;
                                    width: 100%;

                                    .action {
                                        display: flex;
                                        flex-direction: row;
                                        margin-right: 20px;
                                        cursor: pointer;
                                        background-color: #f5f5f5;
                                        padding: 10px 15px;
                                        border-radius: 3px;
                                    }
                                }

                                .icon {
                                    margin-right: 10px;
                                }
                            }

                            .budget_type {
                                margin-right: 20px;
                            }
                        }

                        .select_container {
                            display: flex;
                            flex-direction: row;

                            .ant-select {
                                width: 100%;
                            }
                        }
                    }

                    .header {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        border-bottom: 1px solid #eee;
                        height: 50px;
                        width: 100%;
                        justify-content: space-between;

                        .header_action {
                            margin-right: 10px;
                            font-size: 20px;
                        }

                        .title {
                            font-size: 20px;
                            display: flex;
                        }
                    }

                    .content {
                        display: flex;
                        flex-direction: row;
                        margin: 15px 0px;

                        .budget_type_container {
                            display: flex;
                            flex-direction: column;
                            width: 100%;
                            padding-right: 25px;
                        }

                        .asset_name_container {
                            display: flex;
                            flex-direction: row;
                            width: 100%;
                            padding-right: 25px;

                            input {
                                border: none;
                                font-size: 16px;
                                width: 100%;

                                &:focus {
                                    outline: none;
                                    border-color: inherit;
                                    -webkit-box-shadow: none;
                                    box-shadow: none;
                                }
                            }
                        }

                        .objective_container {
                            display: flex;
                            flex-direction: column;
                            width: 100%;
                            height: 100%;

                            .tite {
                                display: flex;
                                flex-direction: column;
                                height: 30px;
                                font-weight: bold;
                            }

                            .options {
                                display: flex;
                                flex-direction: column;

                                .option_row {
                                    display: flex;
                                    flex-direction: row;
                                    height: 40px;
                                    align-items: center;

                                    .radio {
                                        width: 30px;
                                        display: flex;
                                        flex-direction: column;
                                        align-items: center;
                                        justify-content: center;

                                        input {
                                            cursor: pointer;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .campaign_budget_settings_container {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                    }
                }
            }

            .footer {
                display: flex;
                flex-direction: column;
                height: 50px;
                width: 100%;
                position: absolute;
                bottom: 41px;
                left: 0;
                border-top: 1px solid #eee;
                background-color: #fff;
            }
        }
    }
}

.footer_actions_container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: end;
    width: 100%;

    .create_campaign_button {
        display: flex;
        flex-direction: column;
        height: 50px;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
    }
}
