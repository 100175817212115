.tracking_code_view {
    font-size: 12px;
    display: grid;
    grid-template-columns: auto 900px auto;
    grid-template-rows: 50px auto;

    .content {
        grid-column: 2/2;
        grid-row: 2/2;

        .description_container {
            font-size: 18px;
            margin: 10px 0px 30px 0px;
            display: flex;
            flex-direction: row;

            .js_logo {
                width: 50px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                margin-top: 3px;
            }

            .heading {
                span {
                    font-weight: bold;
                }
            }

            .description {
                margin: 10px 0px;
                font-size: 14px;
            }
        }

        .code_snippet_container {
            overflow-x: scroll;
            border-radius: 5px;
            border: 1px solid #eee;
            padding: 5px;
            padding-top: 0px;
            background: #fdfdfd;
            margin-bottom: 15px;
            -ms-overflow-style: none;
            scrollbar-width: none;

            ::-webkit-scrollbar {
                display: none;
            }
        }

        .copy_to_clipboard_container {
            width: 150px;
            height: 40px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            background-color: #2196f3;
            color: #fff;
            cursor: pointer;

            .button {
                display: flex;
                flex-direction: row;

                .icon {
                    margin-right: 10px;
                }
            }
        }

        .status_container {
            margin-top: 30px;
            border-top: 1px solid #eee;
            display: flex;
            flex-direction: column;
            height: 350px;
            width: 100%;
            align-items: center;
            justify-content: center;
            font-size: 14px;

            .icon {
                font-size: 30px;
            }

            .check_status_button {
                margin-top: 10px;
                border: 1px solid black;
                padding: 10px 20px;
                border-radius: 3px;
                border-style: dashed;
                cursor: pointer;
            }

            div {
                margin: 5px 0px;
            }
        }
    }
}

.hljs {
    background-color: transparent;
    margin-top: -10px;
}
