@import "./conditions";

.ads_manager_view {
    width: 100%;
    padding: 20px;
    overflow-y: scroll;

    .ads_manager_top_toolbar {
        display: grid;
        grid-template-columns: auto 500px;
        grid-template-rows: 50px;
        margin-top: 10px;

        .table_actions_container {
            width: 350px;
            height: 32px;
            z-index: 3;

            .actions_container {
                height: 32px;
                border-radius: 3px;
                border: 1px solid #d9d9d9;
                display: grid;
                grid-template-columns: auto auto auto auto;
                margin-bottom: 5px;

                .action {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    font-size: 12px;

                    .icon {
                        margin-right: 10px;
                    }
                }
            }
        }

        .left_adjusted {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            grid-column: 1/1;

            .adsmanger_view_switch_container {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: -20px;
                font-size: 12px;

                .switch {
                    margin-right: 10px;
                }
            }
        }

        .right_adjusted {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            grid-column: 2/2;

            .date_select_container {
                display: flex;
                flex-direction: column;
                height: 50px;
                align-items: flex-end;
            }

            .conversion_window_container {
                display: flex;
                flex-direction: column;
                font-size: 12px;
                margin-right: 10px;
                margin-top: -17px;

                .label {
                    display: flex;
                    align-items: center;
                    margin-right: 10px;
                    margin-bottom: 5px;
                }

                .input {
                    .ant-input-number {
                        width: 110px;
                    }

                    input {
                        height: 30px;
                    }
                }
            }

            .attribution_click_setting_container {
                font-size: 10px;
                display: flex;
                flex-direction: column;
                width: 100px;
                margin-right: 10px;
                margin-top: -3px;

                .attribution_click_setting_row_container {
                    display: flex;
                    flex-direction: row;
                    margin-bottom: 5px;

                    .radio {
                        margin-right: 10px;

                        input {
                            cursor: pointer;
                        }
                    }
                }
            }

            .table_settings {
                span {
                    margin-right: 5px;
                }
            }
        }
    }

    .ads_manager_container {
        min-height: 500px;
        width: 100%;
        font-size: 14px;
        border: 1px solid #eee;

        height: calc(100% - 60px);
        overflow: hidden;
        padding-bottom: 50px;

        .tabs {
            display: grid;
            grid-template-columns: repeat(3, minmax(0, 1fr));
            grid-template-rows: 50px;
            position: relative;

            .tab {
                display: flex;
                flex-direction: column;
                align-items: center;
                border: 1px solid #eee;
                border-top: none;
                cursor: pointer;

                .text {
                    position: absolute;
                    top: 20px;
                }
            }

            .tab:first-child {
                border-right: none;
                border-left: none;
            }

            .tab:last-child {
                border-left: none;
                border-right: none;
            }
        }

        .filters_container {
            height: 40px;
            border-bottom: 1px solid #eee;
            display: grid;
            grid-template-columns: 40px auto 30px;
            grid-template-rows: auto;
            color: #303030;
            position: relative;

            .icon {
                grid-column: 1/1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-size: 20px;
            }

            .filters_content_container {
                overflow-x: scroll;
                -ms-overflow-style: none;
                scrollbar-width: none;

                &::-webkit-scrollbar {
                    display: none;
                }

                .filters {
                    grid-column: 2/2;
                    height: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    font-size: 12px;
                    font-weight: lighter;
                    width: 100%;

                    .filter {
                        color: #fff;
                        background-color: #2196f3;
                        border-radius: 50px;
                        cursor: pointer;
                        margin-right: 10px;
                        display: grid;
                        grid-template-columns: auto 1px 25px;
                        height: 25px;
                        align-items: center;
                        padding-left: 10px;

                        .text {
                            grid-column: 1/1;
                            margin-right: 10px;
                        }

                        .line_break {
                            grid-column: 2/2;
                            height: 25px;
                            background-color: #1e88e5;
                        }

                        .delete {
                            grid-column: 3/3;
                            margin-top: 1px;
                            margin-left: 3px;
                        }
                    }
                }
            }

            .create_rule {
                grid-column: 3/3;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: start;
                font-size: 20px;
                cursor: pointer;
                color: #303030;
            }

            .conditions_dropdown {
                position: absolute;
                background-color: #fff;
                left: 40px;
                top: 5px;

                box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
                border-radius: 5px;
                z-index: 2147483647;

                .conditions_header_container {
                    border-bottom: 1px solid #eee;
                    height: 30px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    .header {
                        height: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        padding-left: 25px;
                        font-weight: bold;
                    }

                    .close {
                        height: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        padding-right: 10px;
                        cursor: pointer;
                        font-weight: bold;
                    }
                }

                .conditions_container {
                    padding: 10px;
                    @extend %conditions_container;
                }
            }
        }

        .data_content_container {
            width: 100%;
            height: 100%;
            overflow: scroll;
            -ms-overflow-style: none;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
            }

            .table_actions {
                display: flex;
                flex-direction: row;
                height: 45px;
                border-bottom: 1px solid #eee;
                width: 100%;
                align-items: center;
                position: relative;
                z-index: 3;
                justify-content: flex-start;

                .pagination {
                    display: flex;
                    flex-direction: row;
                    width: 80px;
                    height: 100%;
                    margin-left: auto;
                    font-size: 16px;
                    color: #ccc;
                    padding: 10px;

                    .prev {
                        display: flex;
                        flex-direction: column;
                        width: 50%;
                        height: 100%;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        border: 1px solid #eee;
                        border-radius: 3px;
                        margin-right: 10px;
                    }

                    .next {
                        display: flex;
                        flex-direction: column;
                        width: 50%;
                        height: 100%;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        border: 1px solid #eee;
                        border-radius: 3px;
                    }
                }

                .create_asset_container {
                    // margin-right: auto;
                    margin-left: 10px;
                    display: flex;
                    flex-direction: row;
                    background-color: #1e88e5;
                    color: #fff;
                    height: 25px;
                    width: 90px;
                    align-items: center;
                    justify-content: center;
                    border-radius: 3px;
                    cursor: pointer;
                    font-size: 12px;

                    .icon {
                        margin-right: 10px;
                    }
                }

                .action {
                    width: 90px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    cursor: pointer;
                    font-size: 12px;

                    .icon {
                        margin-right: 10px;
                    }
                }
            }

            .stats {
                border-bottom: 1px solid #eee;
                display: grid;
                grid-template-columns: repeat(5, minmax(0, 1fr));

                .stat {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    height: 90px;
                    border-right: 1px solid #eee;
                    padding-left: 20px;

                    .title {
                        font-size: 12px;
                    }

                    .value {
                        font-size: 35px;
                    }
                }

                .stat:first-child {
                    border-left: none;
                }

                .stat:last-child {
                    border-right: none;
                }
            }

            .table-wrapper {
                background: #fff;
                // overflow: hidden;
                overflow: scroll;
            }

            table {
                width: 100%;
                overflow: auto;
                display: grid;
                -ms-overflow-style: none;
                scrollbar-width: none;

                &::-webkit-scrollbar {
                    display: none;
                }
            }

            table thead tr th {
                border-bottom: 1px solid #eee;

                .sort {
                    font-size: 12px;
                    cursor: pointer;
                }

                .header_content {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    justify-content: flex-end;
                    align-items: center;

                    .sort {
                        margin-top: -5px;
                    }

                    .sortasc {
                        height: 7px;
                    }

                    .sortdesc {
                        height: 7px;
                    }
                }
            }

            table thead tr th:nth-child(1) .header_content {
                justify-content: center;

                input {
                    width: 18px;
                    height: 18px;
                    cursor: pointer;
                }
            }

            table thead tr th:nth-child(2) .header_content {
                justify-content: center;
            }

            table thead tr .name .header_content {
                flex-direction: row-reverse;
            }

            table thead,
            table tbody,
            table tr {
                display: contents;
            }

            table th {
                position: relative;
                font-size: 18px;
                display: flex;
                flex-direction: column;
                height: 40px;
                align-items: flex-end;
                justify-content: center;
                padding: 0px 10px;
                font-size: 12px;
                font-weight: bold;
            }

            table .name {
                align-items: flex-start;
                color: #2196f3;
                font-weight: bold;
            }

            tbody .name span {
                cursor: pointer;
            }

            table th span,
            table td span {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                display: block;
            }

            table td {
                position: relative;
                .ad_stats {
                    position: absolute;
                    left: 0;
                    background-color: #eee;
                    padding: 3px;
                    border-bottom-right-radius: 3px;
                    font-size: 12px;
                }
            }

            table td span {
                display: flex;
                flex-direction: column;
                height: 40px;
                align-items: flex-end;
                justify-content: center;
                padding: 0px 10px;
                font-size: 12px;
            }

            table .name span {
                align-items: flex-start;
            }

            table tr td {
                border-top: 1px solid #eee;
            }

            table > tbody > tr:last-child td {
                border-bottom: 1px solid #eee;
            }

            .resize-handle {
                display: block;
                position: absolute;
                cursor: col-resize;
                width: 1px;
                right: 0;
                top: 0;
                z-index: 0;
                border-right: 1px solid #eee;
                z-index: 2;
            }

            .resize-handle:hover {
                border-color: #2196f3;
            }

            .resize-handle.active {
                border-color: #2196f3;
            }

            tbody tr .checkbox {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                span input {
                    cursor: pointer;
                    width: 18px;
                    height: 18px;
                }
            }

            tbody tr .actions {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }

            .table {
                overflow-x: scroll;
                min-height: 500px;

                .table_row {
                    display: flex;
                    flex-direction: row;

                    .table_cell {
                        height: 40px;
                        font-size: 12px;
                        display: flex;
                        flex-direction: column;
                        align-items: end;
                        justify-content: center;
                        padding: 0px 10px;
                        border: 1px solid #eee;
                        border-top: none;
                        border-right: none;
                    }

                    .table_name {
                        align-items: start;
                    }

                    .table_column {
                        display: flex;
                        flex-direction: column;
                        border: 1px solid #eee;
                        border-top: none;
                        border-right: none;

                        .header_cell {
                            // flex-direction: row;
                            // width: 100%;
                            display: grid;
                            grid-template-columns: auto 5px;
                            height: 100%;
                            position: relative;

                            .cell {
                                grid-column: 1/1;
                                display: flex;
                                flex-direction: row;
                                height: 100%;
                                align-items: center;
                                justify-content: space-between;
                                padding: 0px 10px;

                                .sort {
                                    cursor: pointer;
                                }
                            }

                            .drag_handle {
                                grid-column: 2/2;
                                background-color: #1e88e5;
                                cursor: col-resize;
                                // position: absolute;
                            }
                        }

                        .table_checkbox {
                            cursor: pointer;
                            width: 16px;
                            height: 16px;
                        }
                    }

                    .table_column:first-child {
                        border-left: none;
                    }

                    .table_column:last-child {
                        // border-right: none;
                    }
                }
            }
        }
    }
}

.table_settings_modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 20px;
    position: relative;
}

.table_settings_list_li {
    font-size: 12px;
    display: flex;
    flex-direction: row;
    width: 100%;
    z-index: 3 !important;
    padding: 7px;
    margin: 0.5em 0em;
    list-style-type: none;
    border: 1px solid #ccc;
    color: #333;
    border-radius: 3px;
    font-family: Arial, Helvetica Neue, Helvetica, sans-serif;

    .row_container {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        // justify-content: center;

        .checkbox {
            margin-right: 10px;

            input {
                cursor: pointer;
            }
        }

        .drag_handle {
            display: flex;
            flex-direction: column;
            align-self: flex-end;
            margin-left: auto;
            font-weight: bold;
            transform: rotateZ(90deg);
        }
    }
}

.columns_table_container {
    width: 350px;
    display: flex;
    flex-direction: column;
    height: 470px;
    overflow: scroll;
    background: #fff;
    padding: 0px 5px;
    border: 1px solid #eee;
    border-radius: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    position: absolute;
    top: 50px;
    left: 100px;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}

.filters_actions_container {
    display: flex;
    flex-direction: column;
    width: 500px;
    background: #fff;
    padding: 5px 5px 0px 10px;
    border: 1px solid #eee;
    border-radius: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    position: absolute;
    top: 50px;
    left: 5px;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }

    @extend %conditions_container;
}

.sort_container {
    display: grid;
    grid-template-rows: 30px auto 70px;
    width: 350px;
    height: 470px;
    overflow: hidden;
    background: #fff;
    padding: 0px 5px;
    border: 1px solid #eee;
    border-radius: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    position: absolute;
    top: 50px;
    left: 175px;

    .sort_title_container {
        grid-row: 1/1;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 5px;
        border-bottom: 1px solid #eee;
        font-size: 14px;
        justify-content: space-between;

        .close_icon {
            margin-right: 5px;
            cursor: pointer;
        }
    }

    .sort_list_container {
        grid-row: 2/2;
        display: flex;
        flex-direction: column;
        overflow: scroll;
        height: 100%;
        border-bottom: 1px solid #eee;

        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }

        .sort_list_item_container {
            display: flex;
            flex-direction: row;
            min-height: 32px;
            align-items: center;
            padding-left: 5px;
            font-size: 14px;

            input {
                margin-right: 10px;
                cursor: pointer;
            }
        }
    }

    .sort_order_container {
        grid-row: 3/3;
        display: flex;
        flex-direction: column;

        .order_row_container {
            display: flex;
            flex-direction: column;
            min-height: 30px;
            justify-content: center;
            font-size: 14px;
            cursor: pointer;
            padding: 10px;
            padding-left: 0px;

            .order_row_inner_container {
                display: flex;
                flex-direction: row;
                padding: 7px;
                padding-left: 5px;
                border-radius: 3px;

                &:hover {
                    background-color: #eee;
                }

                .icon {
                    margin-right: 10px;
                }
            }
        }
    }
}

.column_title_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    min-height: 25px;
    justify-content: space-between;
    padding: 0px 5px;
    margin-top: 2px;
    border-bottom: 1px solid #eee;
    cursor: pointer;
}

.product_sales_container {
    display: flex;
    flex-direction: column;
    border: 1px solid #eee;
    margin-bottom: 10px;
    padding: 6px;
    font-size: 12px;
    // margin-right: 20px;

    .product_header_container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-top: 10px;

        .title {
            font-weight: bold;
        }

        .header_stats {
            // display: flex;
            // flex-direction: row;
            display: grid;
            grid-template-columns: 105px auto;

            .stats_container {
                width: 75px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-right: 20px;

                &:last-of-type {
                    margin-right: 10px;
                }

                .label {
                    font-weight: bold;
                }
            }
        }
    }

    .sales_container {
        margin-top: 20px;
        display: flex;
        flex-direction: column;

        .sale_row_container {
            display: grid;
            align-items: center;
            grid-template-columns: 300px auto;
            height: 25px;
            padding-left: 5px;

            &:nth-last-of-type(odd) {
                background-color: #f5f5f5;
            }
        }
    }
}

.ReactModal__Content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    background-color: #1e88e5;

    .orders_modal_container {
        padding: 20px;
        overflow: scroll;
        display: flex;
        flex-direction: row;
        min-width: 100%;
        overflow: hidden;

        .orders_modal_content {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-right: auto;
            padding-right: 20px;
            overflow: scroll;
            padding-bottom: 10px;
            margin-bottom: -20px;
            -ms-overflow-style: none;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .close_orders_modal_container {
            display: flex;
            flex-direction: column;
            width: 50px;
            cursor: pointer;
            font-size: 40px;
            color: #eee;
        }
    }
}

.close_modal_container {
    position: absolute;
    right: 0;
    top: 10px;
    right: 10px;
    font-size: 20px;
    color: #ccc;
    cursor: pointer;
}

.range_picker_actions {
    margin: 10px 0px;
    display: flex;
    flex-direction: row;
    justify-content: end;

    .range_picker_button {
        margin: 0px 5px;
    }
}

.expired_trial_modal_container {
    .content_container {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 2;

        .post_outer_container {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .post_container {
                width: 600px;
                height: 300px;
                background-color: #fff;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                border: 2px solid #eee;
                border-radius: 3px;

                .text {
                    font-weight: bold;
                    font-size: 40px;
                    margin-bottom: 20px;
                    text-align: center;
                }

                .cta {
                    background-color: #eb072a;
                    color: #fff;
                    height: 50px;
                    width: 400px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    border-radius: 3px;
                    cursor: pointer;
                }
            }
        }
    }
    .bg_img_container {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        overflow: hidden;
    }
}

.complete_setup_modal {
    .content_container {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 2;

        .post_outer_container {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .post_container {
                width: 600px;
                height: 300px;
                background-color: #fff;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                border: 2px solid #eee;
                border-radius: 3px;

                .text {
                    font-weight: bold;
                    font-size: 40px;
                    margin-bottom: 20px;
                    text-align: center;
                }

                .call_to_actions {
                    .cta {
                        background-color: #1e88e5;
                        color: #fff;
                        height: 50px;
                        width: 400px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        border-radius: 3px;
                        cursor: pointer;
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }

    .bg_img_container {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        overflow: hidden;
    }
}
