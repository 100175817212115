.home_view {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    font-family: "Inter", sans-serif !important;
    font-size: 20px;
    margin-bottom: 20px;

    .header_section_container {
        display: flex;
        flex-direction: column;
        height: 60px;
        width: 100%;
        align-items: center;
        border-bottom: 1px solid #eee;

        .header_container {
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 1000px;

            .header_content_container {
                display: grid;
                grid-template-columns: 130px auto 300px;
                grid-template-rows: 60px;
                align-items: center;

                .logo {
                    grid-column: 1/1;
                }

                .nav {
                    grid-column: 2/2;
                    display: flex;
                    flex-direction: row;

                    .nav_item {
                        display: flex;
                        flex-direction: column;
                        margin: 0px 20px;
                        font-size: 14px;
                        cursor: pointer;
                    }
                }

                .actions {
                    grid-column: 3/3;
                    display: flex;
                    flex-direction: row;
                    justify-content: end;

                    .action_item {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        margin: 0px 20px;
                        font-size: 14px;
                        cursor: pointer;
                    }

                    .try_it_button {
                        padding: 10px 20px;
                        font-size: 12px;
                        margin-right: 0px;
                    }
                }
            }
        }
    }

    .home_section_container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        align-items: center;
        overflow-y: scroll;

        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }

        .home_container {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            padding-top: 30px;

            .headline_container {
                grid-column: 2/2;
                // font-size: 50px;
                font-size: 36px;
                font-weight: 800;
                text-align: center;
            }

            .main_video_container {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 40px;
                margin-bottom: 60px;
                width: 100%;

                .video {
                    display: flex;
                    flex-direction: column;
                    border-radius: 10px;
                    overflow: hidden;
                }
            }

            .content_section_outer_container {
                display: grid;
                grid-template-columns: auto 1000px auto;
                grid-template-rows: auto;
                padding: 30px 0px 40px 0px;
            }

            .content_section_container {
                grid-column: 2/2;
                display: flex;
                flex-direction: column;
                // margin-bottom: 30px;

                .headline {
                    font-size: 50px;
                    font-weight: bold;
                    margin-bottom: 40px;
                }

                .content {
                    .text_section {
                        display: flex;
                        flex-direction: column;
                        margin-bottom: 20px;
                        padding-right: 30px;
                        line-height: 1.2;

                        .steps_section {
                            display: flex;
                            flex-direction: row;

                            .icon {
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: center;
                                margin-right: 20px;
                            }
                        }
                    }
                }
            }

            .right_content_section {
                display: grid;
                grid-template-rows: auto auto;
                // margin-bottom: 50px;

                .headline {
                    grid-column: 1/2;
                    grid-row: 1/1;
                    // font-family: sans-serif;
                    font-size: 50px;
                    font-weight: bold;
                    margin-bottom: 40px;
                }

                .content {
                    grid-column: 1/2;
                    grid-row: 2/2;

                    .text_section {
                        display: flex;
                        flex-direction: column;
                        margin-bottom: 20px;
                        padding-right: 30px;
                        line-height: 1.2;
                    }
                }

                .side_section {
                    grid-column: 2/3;
                    grid-row: 1/3;
                }

                .image {
                    grid-column: 2/3;
                    grid-row: 1/3;

                    .cookie_monster_img_container {
                        display: flex;
                        flex-direction: column;
                    }

                    .img_container {
                        width: 100%;
                        display: flex;
                        flex-direction: column;

                        img {
                            border-radius: 5px;
                        }
                    }
                }
            }

            .left_content_section {
                display: grid;
                grid-template-rows: auto auto;
                // margin-bottom: 50px;

                .headline {
                    grid-column: 2/3;
                    grid-row: 1/1;
                    // font-family: sans-serif;
                    font-size: 50px;
                    font-weight: bold;
                    margin-bottom: 40px;
                    padding-left: 40px;
                }

                .content {
                    grid-column: 2/3;
                    grid-row: 2/2;

                    .text_section {
                        display: flex;
                        flex-direction: column;
                        margin-bottom: 20px;
                        padding-left: 40px;
                        line-height: 1.2;
                    }
                }

                .side_section {
                    grid-column: 1/2;
                    grid-row: 1/3;
                }

                .image {
                    grid-column: 1/2;
                    grid-row: 1/3;

                    .img_container {
                        width: 100%;
                        display: flex;
                        flex-direction: column;

                        img {
                            border-radius: 5px;
                        }
                    }
                }
            }

            .feature_content_section {
                display: flex;
                flex-direction: row;
                width: 100%;
                justify-content: space-between;
                // margin-bottom: 50px;

                .feature_container {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    border-right: 1px solid #eee;
                    align-items: center;

                    &:last-of-type {
                        border: none;
                    }

                    .title {
                        margin-bottom: 15px;
                        font-weight: bold;
                        font-size: 18px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        width: 100%;
                    }

                    .content {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        height: 100%;

                        .bullet {
                            display: flex;
                            flex-direction: column;
                            margin-bottom: 10px;
                            padding: 0px 15px;
                            line-height: 1.2;

                            .text {
                                margin-bottom: 15px;
                                font-size: 14px;
                            }
                        }
                    }
                }
            }

            .headline_content_section {
                grid-column: 2/2;
                display: flex;
                flex-direction: column;
                font-weight: bold;
                // margin-bottom: 50px;
                text-align: center;
            }

            .features_container {
                display: flex;
                flex-direction: column;
                background-color: #f8f8f8;
                padding: 20px;
                border-radius: 10px;

                .feature {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-bottom: 20px;
                    font-size: 14px;
                    padding-left: 10px;

                    &:last-of-type {
                        margin-bottom: 0px;
                    }

                    .icon {
                        margin-right: 10px;
                    }
                }
            }

            .cost_per_hour_section {
                display: flex;
                flex-direction: row;
                background-color: #f8f8f8;
                padding: 20px;
                border-radius: 10px;
                height: 200px;
                justify-content: center;
                align-items: center;

                .amount {
                    font-size: 50px;
                    font-weight: bold;
                    margin-right: 10px;
                }

                .time {
                    margin-top: 20px;
                    font-size: 20px;
                }
            }

            .get_started_section {
                display: flex;
                flex-direction: column;
                align-items: center;

                .headline {
                    font-weight: bold;
                    font-size: 50px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-bottom: 10px;
                }

                .subheadline {
                    font-weight: bold;
                    font-size: 25px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-bottom: 20px;
                }

                .cta {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 400px;
                    height: 50px;
                    // background-color: #0165e1;
                    color: #fff;
                    font-size: 20px;
                    font-weight: bold;
                    border-radius: 5px;
                    margin-bottom: 20px;
                    cursor: pointer;
                }

                .risk_reversals {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    margin-bottom: 20px;
                    font-size: 12px;

                    .item {
                        display: flex;
                        flex-direction: row;
                        margin: 0px 20px;

                        .icon {
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }
}

.header_try_it_button_container {
    display: grid;
    grid-template-columns: 100px 50px;
    grid-template-rows: 40px;
    border-radius: 30px;
    background-color: #eb072a;
    color: #fff;
    align-items: center;

    .text {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: -20px;
    }

    .bubble_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 40px;
        background-color: #eb072a;
        border-radius: 100px;

        .icon_container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-right: -7px;
            width: 30px;
            height: 30px;
            background-color: #fff;
            border-radius: 100px;

            .icon {
                color: #000;
                font-size: 16px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin-right: -3px;
            }
        }
    }
}

.cta_try_it_button_container {
    display: grid;
    grid-template-columns: 300px 50px;
    grid-template-rows: 60px;
    border-radius: 30px;
    background-color: #eb072a;
    color: #fff;
    align-items: center;

    .text {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: -60px;
    }

    .bubble_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        background-color: #eb072a;
        border-radius: 100px;

        .icon_container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-right: -7px;
            width: 40px;
            height: 40px;
            background-color: #fff;
            border-radius: 100px;

            .icon {
                color: #000;
                font-size: 16px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin-right: -3px;
            }
        }
    }
}

.wave {
    // display: flex;
    // flex-direction: column;
    // width: 100%;
    // height: 30px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100px;
    z-index: 0;
    margin-top: 3px;
    // background-image: linear-gradient(#ff9d2f, #ff6126);
    transform: skewY(-3deg);
    transform-origin: top left;
}
