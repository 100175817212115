.shopify_settings_view {
    font-size: 12px;

    .connect_shopify_container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: 16px;

            .shop_url_input_container {
                margin-bottom: 20px;
                input {
                    width: 500px;
                    height: 50px;
                    border: 1px solid #eee;
                    padding-left: 10px;
                }
            }

            .connect_button_container {
                width: 500px;
                height: 50px;
                cursor: pointer;
                background-color: #95bf46;
                border: 2px solid #5e8e3d;
                border-radius: 5px;

                .button {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    .settings_container {
        margin-top: 15px;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;

        .settings_nav_container {
            width: 200px;
            padding: 10px;
            margin-right: 20px;

            .nav {
                display: flex;
                flex-direction: column;
                width: 100%;
                height: 100%;

                .nav_item {
                    display: flex;
                    flex-direction: column;
                    height: 30px;
                    width: 100%;
                    background-color: #ebf0ff;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 10px;
                    border-radius: 5px;
                    cursor: pointer;
                    color: #2196f3;
                }
            }
        }

        .content_container {
            padding: 20px;
            display: flex;
            flex-direction: column;
            width: 100%;

            .content {
                .title {
                    font-size: 20px;
                    font-weight: bold;
                    margin-bottom: 30px;
                    border-bottom: 1px solid #eee;
                    padding-bottom: 20px;
                }
            }

            .setting_item_container {
                display: flex;
                flex-direction: column;
                width: 100%;
                margin-bottom: 40px;

                .label {
                    font-size: 14px;
                    margin-bottom: 15px;
                }

                .value {
                    display: flex;
                    flex-direction: column;
                    width: 100%;

                    .read_only_input {
                        display: flex;
                        flex-direction: column;
                        width: 100400;
                        height: 45px;
                        justify-content: center;
                        border: 1px solid #eee;
                        padding-left: 20px;
                        font-size: 20px;
                        border-radius: 5px;
                    }

                    .switch_input {
                        font-size: 14px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        // justify-content: center;

                        .switch {
                            margin-right: 20px;
                        }
                    }
                }
            }

            .integration_action_buttons_container {
                display: flex;
                flex-direction: column;
                width: 100%;

                .integration_button_container {
                    display: flex;
                    flex-direction: row;
                    height: 40px;
                    width: 200px;
                    border: 1px solid #eee;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    border-radius: 5px;
                    font-size: 14px;

                    .icon {
                        margin-right: 10px;
                    }
                }

                .delete {
                    background-color: #fe3e3e;
                    color: #fff;
                }
            }
        }
    }
}
