@import "./colors";

.open_left_nav {
    display: flex;
    flex-direction: column;
    width: 200px;
    height: 100%;
    color: #fff;
    justify-content: space-between;
    border: none;
    border-right: 2px solid #eee;

    .header_nav {
        .nav_item {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: start;
            cursor: pointer;
            padding: 10px 17px;
            color: #666;
            border-bottom: 1px solid #eee;

            &:first-of-type {
                border-top: 1px solid #eee;
            }

            .icon {
                font-size: 1em;
                margin-right: 15px;
            }

            .text {
                font-size: 12px;
                line-height: 1.3;
            }
        }
    }

    .footer_nav {
        position: relative;

        .nav_item {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: start;
            cursor: pointer;
            padding: 10px 17px;
            color: #666;
            border-bottom: 1px solid #eee;
            border-top: 1px solid #eee;

            .icon {
                font-size: 1em;
                margin-right: 15px;
            }

            .text {
                font-size: 12px;
            }
        }

        .settings_select {
            position: absolute;
            background-color: #fff;
            width: 185px;
            bottom: 70px;
            left: 15px;
            border-bottom-left-radius: 3px;
            border-top-left-radius: 3px;
            z-index: 2147483647;

            .close {
                color: #fff;
                right: 0px;
                position: absolute;
                font-size: 10px;
                background-color: #d6d6d6;
                border-radius: 3px;
                top: 5px;
                right: 5px;
                cursor: pointer;
                width: 20px;
                height: 20px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }

            .setting_nav_item {
                color: #474747;
                font-size: 24px;
                font-weight: lighter;
                display: flex;
                flex-direction: row;
                font-size: 12px;
                height: 30px;
                align-items: center;
                padding-left: 10px;
                cursor: pointer;

                .icon {
                    margin-right: 10px;
                }

                &:hover {
                    background-color: #eeeeee;
                }
            }

            .setting_nav_item:last-child {
                border-bottom-left-radius: 3px;
            }

            .setting_nav_item:first-child {
                border-top-left-radius: 3px;
            }
        }

        .settings_nav_item {
            display: grid;
            grid-template-columns: 150px auto;
            grid-template-rows: auto;
            font-size: 10px;

            .left {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding-left: 15px;

                .avatar {
                    margin-right: 10px;
                }
            }

            .right {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: end;
                padding-right: 15px;

                .icon {
                    font-size: 16px;
                    cursor: pointer;
                }
            }
        }

        .retract_item {
            justify-content: end;
            padding-right: 0px;
        }
    }
}

.account_status_container {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    padding: 5px;
    color: #000;

    .content_container {
        border: 1px solid #eee;

        .account_status_header {
            display: flex;
            flex-direction: column;
            height: 40px;
            align-items: center;
            justify-content: center;
            border-bottom: 1px solid #eee;
            font-weight: bold;
            background-color: #fffacd;
        }

        .status_container {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 40px;
            border-bottom: 1px solid #eee;
            line-height: 1.3;

            .icon {
                width: 30px;
                padding-left: 5px;
            }

            .label {
                width: 120px;
            }
        }

        .schedule_a_call_container {
            margin: 10px 0px;
            padding: 5px;
            div {
                margin-bottom: 10px;
            }

            a {
                display: flex;
                flex-direction: column;
                width: calc(100% - 10px);
                height: 30px;
                background-color: #57e480;
                align-items: center;
                justify-content: center;
                border-radius: 3px;
                color: #fff;
                cursor: pointer;
                margin-left: 5px;
            }
        }
    }
}
