@import "./colors";
.analysis_container {
    display: flex;
    flex-direction: column;
    width: 100%;

    .query_builder_container {
        display: flex;
        flex-direction: column;

        .conditions_container {
            display: flex;
            flex-direction: column;
            border-bottom: 1px solid $gray_100;
            padding: 10px 0px 5px 10px;

            .event_type_container {
                margin-bottom: 5px;
                display: flex;
                flex-direction: row;
                align-items: center;

                .select {
                    width: 150px;
                    margin-right: 10px;
                    .ant-select {
                        width: 100%;
                    }
                }

                .remove {
                    cursor: pointer;
                }
            }

            .condition_container {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 5px 0px;

                .filter {
                    width: 150px;
                    margin-right: 10px;
                    .ant-select {
                        width: 100%;
                    }
                }

                .condition {
                    width: 150px;
                    margin-right: 10px;
                    .ant-select {
                        width: 100%;
                    }
                }

                .value {
                    width: 300px;
                    margin-right: 10px;
                    .ant-select {
                        width: 100%;
                    }
                }

                .add {
                    width: 30px;
                    cursor: pointer;
                }

                .remove {
                    width: 30px;
                    cursor: pointer;
                }
            }
        }
    }
    .add_step {
        font-size: 12px;
        padding: 10px 10px;
        border-bottom: 2px solid $gray_100;
        color: $gray_500;
        cursor: pointer;
    }
}
