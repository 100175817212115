.onboarding_three_view {
    font-size: 12px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    line-height: 1.3;

    .onboarding_tabs {
        position: fixed;
        top: 20px;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        justify-content: center;
        color: #5c79ff;
        z-index: 2;

        .tab {
            margin-right: 30px;
            cursor: pointer;
            padding-bottom: 3px;
        }

        .subnav {
            display: flex;
            flex-direction: row;
            position: fixed;
            top: 55px;
        }
    }

    .onboarding_container {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
        height: 100%;

        .slide_container {
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: center;
            justify-content: center;
            height: 100%;

            -ms-overflow-style: none;
            scrollbar-width: none;
            ::-webkit-scrollbar {
                display: none;
            }

            .slide_content_container {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 50px;
                text-align: left;
                margin-top: 100px;
                // height: 800px;
                overflow: scroll;
                padding: 5px;
                // padding-bottom: 50px;
                width: 100%;

                -ms-overflow-style: none;
                scrollbar-width: none;

                ::-webkit-scrollbar {
                    display: none;
                }

                .inner_content {
                    display: flex;
                    flex-direction: column;
                    width: 800px;

                    .next_section {
                        background-color: #5c79ff;
                        color: #fff;
                        display: flex;
                        flex-direction: column;
                        min-height: 50px;
                        // width: 760px;
                        width: 100%;
                        align-items: center;
                        justify-content: center;
                        border-radius: 5px;
                        cursor: pointer;
                        margin-bottom: 20px;
                        align-self: center;
                    }

                    .connect_to_fb_button {
                        background-color: #5c79ff;
                        color: #fff;
                        display: flex;
                        flex-direction: column;
                        min-height: 50px;
                        width: 100%;
                        align-items: center;
                        justify-content: center;
                        border-radius: 5px;
                        cursor: pointer;
                        margin-bottom: 20px;

                        a {
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}

.video_card_container {
    background-color: #fff;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 20px;
    margin-top: 20px;

    .title {
        font-size: 20px;
        text-align: center;
        margin-bottom: 20px;
        font-weight: bold;
    }

    .video_container {
        img {
            border-radius: 5px;
            border: 3px solid #000;
        }
        .icon {
            margin-top: 20px;
            font-size: 30px;
        }
    }
}
