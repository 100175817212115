.order_view {
    // position: absolute;
    // top: 0;
    // bottom: 0;
    // right: 0;
    // left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    font-family: "Inter", sans-serif !important;
    overflow-x: hidden;
    overflow-y: scroll;

    .loading_container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;

        .text {
            margin-bottom: 40px;
            font-size: 30px;
        }

        .icon {
            font-size: 50px;
        }
    }

    .header_section_container {
        display: flex;
        flex-direction: column;
        height: 60px;
        width: 100%;
        align-items: center;
        border-bottom: 1px solid #eee;

        .header_container {
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 1000px;

            .header_content_container {
                display: grid;
                grid-template-columns: 130px auto 300px;
                grid-template-rows: 60px;
                align-items: center;

                .logo {
                    grid-column: 1/1;
                }

                .nav {
                    grid-column: 2/2;
                    display: flex;
                    flex-direction: row;

                    .nav_item {
                        display: flex;
                        flex-direction: column;
                        margin: 0px 20px;
                        font-size: 14px;
                        cursor: pointer;
                    }
                }

                .actions {
                    grid-column: 3/3;
                    display: flex;
                    flex-direction: row;
                    justify-content: end;

                    .action_item {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        margin: 0px 20px;
                        font-size: 14px;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .main_section_container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        align-items: center;
        overflow-x: hidden;
        overflow-y: scroll;
        // margin-bottom: 50px;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }

        .main_container {
            display: flex;
            flex-direction: column;
            width: 1000px;
            padding-top: 30px;
            align-items: center;

            .form_container {
                display: flex;
                flex-direction: column;
                width: 100%;
                // box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
                border-radius: 5px;
                padding: 0px 30px;
                // padding-bottom: 50px;
                margin-bottom: 30px;

                .form_section_contianer {
                    display: flex;
                    flex-direction: column;
                    width: 100%;

                    .headline {
                        font-size: 30px;
                        font-weight: 500;
                        margin-bottom: 20px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        font-weight: bold;
                    }

                    .subheadline {
                        font-weight: 500;
                        margin-bottom: 20px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }

                    .title {
                        font-size: 30px;
                        font-weight: 500;
                        margin-bottom: 20px;
                    }

                    .order_confirmation_container {
                        margin-top: 20px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                        line-height: 1.2;
                        font-size: 14px;
                        padding: 15px;
                        background-color: #ffffe0;
                        border-radius: 3px;

                        .order_confirmation_textbox {
                            margin-right: 10px;
                        }
                    }

                    .contact_info_container {
                        display: grid;
                        grid-template-columns: 50% 50%;
                        margin: 10px 0px;

                        .title {
                            grid-column: 1/3;
                            grid-row: 1/1;
                            font-size: 25px;
                            margin-bottom: 10px;
                            font-weight: normal;
                            margin-bottom: 15px;
                        }

                        .input_container {
                            margin-right: 30px;
                            grid-row: 2/2;

                            input {
                                border: none;
                                border: 1px solid #eee;
                                padding: 10px;
                                width: 100%;
                            }
                        }
                    }

                    .payment_info_container {
                        display: flex;
                        flex-direction: column;
                        background-color: #eff8fe;
                        padding: 20px;
                        padding-bottom: 40px;
                        border-radius: 5px;
                        margin-top: 30px;

                        .header {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;

                            .title {
                                font-size: 25px;
                            }
                        }

                        input {
                            border: none;
                            border: 1px solid #eee;
                            padding: 10px;
                            width: 100%;
                        }

                        .credit_card_logos {
                            margin-bottom: 20px;
                        }

                        .card_details_container {
                            display: flex;
                            flex-direction: row;
                            width: 100%;
                            justify-content: space-between;
                            margin-top: 20px;

                            .detail_container {
                                display: flex;
                                flex-direction: column;
                                width: 100%;
                            }
                        }

                        .card {
                            background-color: #fff;
                            border-radius: 5px;
                            padding: 0px 10px;
                        }

                        .ant-select-selector {
                            height: 40px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                        }
                    }

                    .order_summary_container {
                        margin-top: 40px;

                        .text {
                            font-weight: normal;
                            margin-bottom: 15px;
                            font-size: 25px;
                        }

                        .order_details_container {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            font-size: 20px;

                            .label {
                                font-weight: bold;
                            }
                        }
                    }

                    .cta_container {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        align-items: center;
                        margin-top: 40px;

                        .button {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            width: 500px;
                            height: 50px;
                            border-radius: 3px;
                            color: #fff;
                            font-weight: bold;
                            border: none;
                        }
                    }
                }
            }
        }
    }
}

.recurly-element-card {
    height: 50px;
}

.ant-checkbox-inner {
    width: 20px;
    height: 20px;
}

// 376740287493008
// 11/24
// 0983
