.ad_accounts_view {
    font-size: 14px;
    padding: 20px 40px;
    overflow-y: scroll;

    .account_container {
        margin-bottom: 20px;

        .header {
            font-weight: bold;
            font-size: 16px;
        }

        .profile_container {
            border-bottom: 1px solid #eee;
            display: grid;
            grid-template-columns: 50px auto 50px;
            grid-template-rows: 50px;
            width: 400px;
            margin: 20px 0px;
            padding-bottom: 30px;

            .profile_picture {
                height: 100%;

                img {
                    border-radius: 100px;
                }
            }

            .profile_info {
                height: 100%;

                padding-left: 15px;
                display: flex;
                flex-direction: column;
                justify-content: center;

                .name {
                    margin: 3px 0px;
                }

                .id {
                    margin: 3px 0px;

                    span {
                        font-weight: bold;
                    }
                }
            }

            .profile_actions {
                height: 100%;

                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .close {
                    cursor: pointer;
                }
            }
        }
    }

    .ad_accounts_container {
        display: flex;
        flex-direction: column;
        width: 100%;

        .ad_accounts_header {
            margin-bottom: 30px;

            .title {
                font-size: 16px;
                font-weight: bold;
            }
        }

        .ad_accounts_container {
            display: flex;
            flex-direction: column;

            .ad_accounts_header_columns {
                display: flex;
                flex-direction: row;
                border-bottom: 1px solid #eee;
                padding-bottom: 10px;

                .status {
                    width: 70px;
                }

                .name {
                    width: 350px;
                }

                .id {
                    width: 200px;
                }

                .spend {
                    width: 200px;
                }
            }

            .ad_account_container {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 15px 0px;
                font-size: 12px;

                .ad_account_status {
                    width: 70px;
                }

                .ad_account_name {
                    width: 350px;
                }

                .ad_account_id {
                    width: 200px;
                }

                .ad_account_spent {
                    width: 200px;
                }
            }
        }
    }
}
