.rule_container {
    width: 100%;

    .rule_scope_action_container {
        display: flex;
        flex-direction: row;
    }

    .action_container {
        font-size: 12px;
        display: grid;
        grid-template-columns: auto auto auto;
        grid-template-rows: 30px;
        margin: 5px 0px 10px 0px;
        cursor: pointer;
        position: relative;
        margin-right: 20px;

        .icon_container {
            grid-column: 1/1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: #eee;
            border-radius: 3px;
            margin-right: 10px;
            width: 30px;
        }

        .scope_description_container {
            grid-column: 2/2;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .text {
                font-weight: bold;
                margin-bottom: 3px;
            }
        }

        .action_name_container {
            grid-column: 2/2;
            display: flex;
            flex-direction: column;
            justify-content: center;
            overflow: hidden;
            margin-right: 10px;

            .text {
                font-weight: bold;
                margin-bottom: 3px;
            }
        }

        .action_description {
            grid-column: 3/3;
            display: flex;
            flex-direction: column;
            justify-content: center;

            cursor: auto;

            .budget_params_container {
                display: flex;
                flex-direction: row;
                align-items: center;
                height: 30px;

                .budget_amount_container {
                    height: 100%;
                    margin-right: 10px;

                    input {
                        height: 100%;
                        border: 2px solid #eee;
                        border-radius: 3px;
                        text-align: right;
                        font-size: 14px;
                        padding-right: 5px;
                        width: 100px;

                        &:focus {
                            outline: none;
                        }
                    }
                }

                .budget_type_dropdown_container {
                    height: 100%;
                    .budget_type_container {
                        height: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                        cursor: pointer;
                        padding: 0px 10px;
                        border: 2px solid #eee;
                        border-radius: 3px;
                        width: 60px;
                    }
                }
            }
        }

        .actions_dropdown {
            position: absolute;
            width: 190px;
            display: flex;
            flex-direction: column;
            font-size: 12px;
            border: 1px solid #eee;
            border-radius: 3px;
            background-color: #fff;
            z-index: 9999;
            top: 40px;

            .metric_field {
                padding: 10px 10px;
                cursor: pointer;

                &:hover {
                    background-color: #eee;
                }
            }
        }
    }

    @extend %conditions_container;
}
