.logs_view {
    font-size: 12px;
    padding: 20px;
    overflow-y: scroll;

    .logs_container {
        display: flex;
        flex-direction: column;

        .log_container {
            display: grid;
            grid-template-columns: 200px auto;
            margin-bottom: 20px;

            .item_info_container {
                grid-column: 1/1;

                .item_name {
                    font-size: 12px;
                    color: #303030;
                    margin: 10px 0px;
                    line-height: 1.2em;
                }

                .item_id {
                    margin: 10px 0px;
                    color: #2196f3;
                    font-weight: bold;
                }

                .campaign_name {
                    margin: 10px 0px;
                    color: #a1a1a1;
                    line-height: 1.3em;

                    span {
                        font-weight: bold;
                    }
                }
            }

            .rule_info_container {
                margin-left: 10px;
                grid-column: 2/2;
                display: flex;
                flex-direction: column;
                border: 1px solid #eee;
                padding: 10px;
                border-radius: 3px;
            }
        }
    }
}
