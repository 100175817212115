.login_view {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }

    .auth_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        // margin-bottom: 50px;
        margin-top: 20px;
        // overflow: scroll;
        width: 100%;

        .auth_form_container {
            display: flex;
            flex-direction: column;
            width: calc(100% - 40px);
            min-height: 600px;
            border: 1px solid #eee;
            border-radius: 3px;
            padding: 20px;
            background-color: #fff;

            @media only screen and (min-width: 600px) {
                width: 500px;
            }

            .header {
                .title {
                    font-size: 20px;
                    margin-bottom: 15px;
                }
            }

            .auth_input_container {
                margin: 10px 0px;
                display: flex;
                flex-direction: column;

                .label {
                    font-size: 12px;
                }

                .value {
                    margin: 10px 0px;

                    input {
                        border: 1px solid #eee;
                        width: 100%;
                        height: 40px;
                        border-radius: 3px;
                        padding-left: 15px;
                    }
                }
            }

            .cta_container {
                width: 100%;
                background-color: #2196f3;
                height: 50px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border-radius: 3px;
                color: #fff;
                cursor: pointer;

                .login-button {
                    display: flex;
                    flex-direction: column;
                    height: 50px;
                    align-items: center;
                    justify-content: center;
                }
            }

            .footer_actions {
                margin-top: 50px;
                font-size: 12px;
                font-weight: bold;
                display: flex;
                flex-direction: column;
                height: 100%;
                justify-content: flex-end;

                .action_link {
                    margin: 5px 0px;
                    cursor: pointer;
                    margin-bottom: 30px;

                    &:last-of-type {
                        margin-bottom: 0px;
                    }

                    a {
                        color: #000;
                    }

                    .text {
                        display: flex;
                        flex-direction: column;

                        .regular {
                            font-weight: 300;
                            margin-right: 5px;
                            margin-bottom: 5px;
                        }
                    }
                }
            }
        }
    }
}

.signup_view {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }

    .auth_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        // margin-bottom: 50px;
        margin-top: 20px;
        // overflow: scroll;
        width: 100%;

        .headline {
            font-size: 40px;
            font-weight: bold;
            margin-bottom: 20px;
        }

        .auth_form_container {
            display: flex;
            flex-direction: column;
            width: 100%;
            min-height: 600px;
            border: 1px solid #eee;
            border-radius: 3px;
            padding: 20px;
            background-color: #fff;
            width: calc(100% - 40px);

            @media only screen and (min-width: 600px) {
                width: 500px;
            }

            .header {
                .title {
                    font-size: 15px;
                    margin-bottom: 15px;
                }
            }

            .auth_input_container {
                margin: 10px 0px;
                display: flex;
                flex-direction: column;

                .label {
                    font-size: 12px;
                }

                .value {
                    margin: 10px 0px;

                    input {
                        border: 1px solid #eee;
                        width: 100%;
                        height: 40px;
                        border-radius: 3px;
                        padding-left: 15px;
                    }
                }
            }

            .cta_container {
                width: 100%;
                background-color: #2196f3;
                height: 50px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border-radius: 3px;
                color: #fff;
                cursor: pointer;

                .login-button {
                    display: flex;
                    flex-direction: column;
                    height: 50px;
                    align-items: center;
                    justify-content: center;
                }
            }

            .footer_actions {
                margin-top: 50px;
                font-size: 12px;
                font-weight: bold;
                display: flex;
                flex-direction: column;

                .action_link {
                    margin: 5px 0px;
                    cursor: pointer;

                    a {
                        color: #000;
                    }

                    .text {
                        display: flex;
                        flex-direction: row;

                        .regular {
                            font-weight: 300;
                            margin-right: 5px;
                        }
                    }
                }
            }
        }
    }
}

.footer {
    margin-top: 20px;
    height: 25px;
    font-size: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: #fff;

    .footer_link {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        margin: 0px 10px;
        color: #666;
    }
}
