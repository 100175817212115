@import "styles/reset";
@import "styles/app";

html,
body,
#main {
    font-family: helvetica;
    letter-spacing: 1.3px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
}

.ant-btn-primary {
    background: #1890ff !important;
}
