@import "./conditions";

.rule_view {
    padding: 20px;
    overflow-y: scroll;

    .header_container {
        display: flex;
        flex-direction: column;

        .title_and_actions {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .title {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 100%;
                margin-bottom: 10px;

                textarea {
                    border: none;
                    width: 100%;
                    font-size: 18px;
                    line-height: 1.3em;

                    &:focus {
                        outline: none;
                    }
                }
            }

            .actions {
                display: flex;
                flex-direction: row;

                .logs {
                    grid-column: 2/2;
                    font-size: 14px;
                    height: 30px;
                    width: 60px;
                    color: #fff;
                    background-color: #e3e3e3;
                    border-radius: 3px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    margin-right: 20px;

                    &:hover {
                        background-color: #2196f3;
                    }
                }

                .save {
                    grid-column: 3/3;
                    font-size: 14px;
                    height: 30px;
                    width: 60px;
                    color: #fff;
                    background-color: #e3e3e3;
                    border-radius: 3px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;

                    &:hover {
                        background-color: #2196f3;
                    }
                }
            }
        }

        .line_break {
            display: flex;
            flex-direction: row;
            width: 100%;
            height: 1px;
            background-color: #eeeeee;
        }
    }

    .column_container {
        display: grid;
        grid-template-columns: 60% 40%;
        overflow: hidden;
        border: 1px solid #eee;
        max-height: 600px;
        padding: 10px;
        border-radius: 5px;
        margin-top: 10px;
        padding-top: 0px;

        .left {
            display: flex;
            flex-direction: column;
            width: 100%;
            overflow-x: hidden;
            padding-right: 10px;

            .rule_settings {
                max-height: 650px;
                overflow-y: scroll;
                overflow-x: hidden;
                padding-bottom: 70px;
                -ms-overflow-style: none;
                scrollbar-width: none;

                &::-webkit-scrollbar {
                    display: none;
                }

                .description_container {
                    display: flex;
                    flex-direction: column;
                    padding-top: 10px;

                    .textarea_container {
                        width: 100%;
                        overflow-x: hidden;
                        height: 100px;
                        margin-bottom: 10px;
                        color: #474747;
                        line-height: 1.2em;

                        textarea {
                            width: 100%;
                            border: none;
                            font-size: 14px;

                            &:focus {
                                outline: none;
                            }
                        }
                    }
                }

                .rule_container {
                    width: 100%;
                    border-radius: 5px;
                    padding: 10px 10px 10px 15px;
                    border: 1px solid #eee;
                    margin-top: 10px;

                    .rule_scope_action_container {
                        display: flex;
                        flex-direction: row;
                    }

                    .action_container {
                        font-size: 12px;
                        display: grid;
                        grid-template-columns: auto auto auto;
                        grid-template-rows: 30px;
                        margin: 5px 0px 10px 0px;
                        cursor: pointer;
                        position: relative;
                        margin-right: 20px;

                        .icon_container {
                            grid-column: 1/1;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            background-color: #eee;
                            border-radius: 3px;
                            margin-right: 10px;
                            width: 30px;
                        }

                        .scope_description_container {
                            grid-column: 2/2;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;

                            .text {
                                font-weight: bold;
                                margin-bottom: 3px;
                            }
                        }

                        .action_name_container {
                            grid-column: 2/2;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            overflow: hidden;
                            margin-right: 10px;

                            .text {
                                font-weight: bold;
                                margin-bottom: 3px;
                            }
                        }

                        .action_description {
                            grid-column: 3/3;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;

                            cursor: auto;

                            .budget_params_container {
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                height: 30px;

                                .budget_amount_container {
                                    height: 100%;
                                    margin-right: 10px;

                                    input {
                                        height: 100%;
                                        border: 2px solid #eee;
                                        border-radius: 3px;
                                        text-align: right;
                                        font-size: 14px;
                                        padding-right: 5px;
                                        width: 100px;

                                        &:focus {
                                            outline: none;
                                        }
                                    }
                                }

                                .budget_type_dropdown_container {
                                    height: 100%;
                                    .budget_type_container {
                                        height: 100%;
                                        display: flex;
                                        flex-direction: row;
                                        align-items: center;
                                        justify-content: space-between;
                                        cursor: pointer;
                                        padding: 0px 10px;
                                        border: 2px solid #eee;
                                        border-radius: 3px;
                                        width: 60px;
                                    }
                                }
                            }
                        }

                        .actions_dropdown {
                            position: absolute;
                            width: 190px;
                            display: flex;
                            flex-direction: column;
                            font-size: 12px;
                            border: 1px solid #eee;
                            border-radius: 3px;
                            background-color: #fff;
                            z-index: 9999;
                            top: 40px;

                            .metric_field {
                                padding: 10px 10px;
                                cursor: pointer;

                                &:hover {
                                    background-color: #eee;
                                }
                            }
                        }
                    }

                    @extend %conditions_container;
                }

                .schedule_container {
                    margin-top: 10px;
                    padding: 10px;
                    border: 1px solid #eee;
                    font-size: 14px;
                    border-radius: 5px;

                    .title {
                        font-size: 16px;
                        margin: 0px 20px 20px 0px;
                        font-weight: bold;
                    }

                    .description {
                        line-height: 1.5em;
                    }

                    .scheduler_dropdown_container {
                        position: relative;
                        margin-top: 20px;

                        .select {
                            display: flex;
                            flex-direction: row;
                            width: 300px;
                            justify-content: space-between;
                            border: 1px solid #eee;
                            padding: 10px;
                            border-radius: 3px;
                            cursor: pointer;
                        }

                        .schedule_dropdown {
                            position: absolute;
                            width: 300px;
                            display: flex;
                            flex-direction: column;
                            font-size: 12px;
                            border: 1px solid #eee;
                            border-radius: 3px;
                            background-color: #fff;
                            z-index: 9999;
                            top: 40px;

                            .metric_field {
                                padding: 10px 10px;
                                cursor: pointer;

                                &:hover {
                                    background-color: #eee;
                                }
                            }
                        }
                    }
                }

                .notifications_container {
                    margin-top: 10px;
                    padding: 10px;
                    border: 1px solid #eee;
                    border-radius: 5px;
                    font-size: 14px;

                    .title {
                        font-size: 16px;
                        margin: 0px 20px 20px 0px;
                        font-weight: bold;
                    }

                    .description {
                        line-height: 1.5em;
                    }

                    .notifications_dropdown_container {
                        position: relative;
                        margin-top: 20px;

                        .select {
                            display: flex;
                            flex-direction: row;
                            width: 300px;
                            justify-content: space-between;
                            border: 1px solid #eee;
                            padding: 10px;
                            border-radius: 3px;
                            cursor: pointer;
                        }

                        .schedule_dropdown {
                            position: absolute;
                            width: 300px;
                            display: flex;
                            flex-direction: column;
                            font-size: 12px;
                            border: 1px solid #eee;
                            border-radius: 3px;
                            background-color: #fff;
                            z-index: 9999;
                            top: 40px;
                            left: 0px;

                            .metric_field {
                                padding: 10px 10px;
                                cursor: pointer;

                                &:hover {
                                    background-color: #eee;
                                }
                            }
                        }
                    }
                }

                .attributions_container {
                    margin-top: 20px;
                    padding-top: 20px;
                    border-top: 1px solid #eee;
                    font-size: 14px;

                    .title {
                        font-size: 16px;
                        margin: 0px 20px 20px 0px;
                        font-weight: bold;
                    }

                    .description {
                        margin: 0px 20px 20px 0px;
                        width: 500px;
                        line-height: 1.5em;
                    }

                    .switch_label_container {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        margin-bottom: 20px;

                        .switch {
                            margin-right: 10px;
                        }
                    }

                    .attributions_dropdown_container {
                        position: relative;

                        .select {
                            display: flex;
                            flex-direction: row;
                            width: 300px;
                            justify-content: space-between;
                            border: 1px solid #eee;
                            padding: 10px;
                            border-radius: 3px;
                            cursor: pointer;
                        }

                        .schedule_dropdown {
                            position: absolute;
                            width: 300px;
                            display: flex;
                            flex-direction: column;
                            font-size: 12px;
                            border: 1px solid #eee;
                            border-radius: 3px;
                            background-color: #fff;
                            z-index: 9999;
                            top: 40px;
                            left: 0px;

                            .metric_field {
                                padding: 10px 10px;
                                cursor: pointer;

                                &:hover {
                                    background-color: #eee;
                                }
                            }
                        }
                    }
                }
            }
        }

        .right {
            display: flex;
            flex-direction: column;
            width: 100%;
            overflow-x: scroll;
            padding-left: 10px;

            .assets_tabs_container {
                height: 40px;
                display: grid;
                grid-template-columns: auto auto;
                width: 100%;
                border: 1px solid #eee;
                margin-top: 10px;
                border-radius: 5px;

                .tab_container {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                    border-right: 1px solid #eee;
                    cursor: pointer;

                    &:last-of-type {
                        border-right: none;
                    }

                    .text {
                        font-weight: bold;
                    }
                }
            }

            .assets_container {
                font-size: 12px;
                max-height: 650px;
                overflow: scroll;
                padding-bottom: 70px;
                -ms-overflow-style: none;
                scrollbar-width: none;

                &::-webkit-scrollbar {
                    display: none;
                }

                .assets_container_accordion {
                    margin-top: 10px;
                    margin-bottom: 50px;
                }

                .accordion_parent_container {
                    display: relative;
                    margin: 10px 0px !important;
                    border: 1px solid #eee;
                    padding: 7px 5px;
                    border-radius: 5px;
                }

                .accordion_parent_container:first-of-type {
                    margin-top: 0px !important;
                }

                .accordion_parent_container:last-of-type {
                    margin-bottom: 0px !important;
                }

                .heading_container {
                    cursor: pointer;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .heading_button {
                        display: grid;
                        grid-template-columns: 10px 30px auto;
                        width: 100%;

                        .heading_checkbox {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;

                            input {
                                cursor: pointer;
                                width: 15px;
                                height: 15px;
                            }
                        }

                        .heading_icon {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                        }

                        .heading_name {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            font-weight: bold;
                        }
                    }
                }

                .accordion_items_container {
                    margin-left: 22px;
                }

                .asset_item_row {
                    line-height: 1.5em;
                    display: grid;
                    grid-template-columns: 40px auto;
                    grid-template-rows: auto;
                    margin: 10px 0px;

                    .name {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                    }
                }

                .asset_item_row:first-of-type {
                    margin-top: 10px;
                }

                .select_checkbox {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    input {
                        cursor: pointer;
                        width: 15px;
                        height: 15px;
                    }
                }

                .heading_name {
                    font-weight: bold;
                    line-height: 1.5em;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
            }
        }
    }
}

.ant-tree {
    width: 100% !important;
}

.ant-tree-treenode {
    padding: 5px 0px !important;
    width: 100% !important;
}

.ant-tree .ant-tree-node-content-wrapper {
    width: 100% !important;
}

.rule_logs_and_tabs_container {
    display: flex;
    flex-direction: column;
    width: 100%;

    .log_tabs_container {
        display: grid;
        grid-template-columns: auto auto auto;
        border: 1px solid #eee;
        margin: 20px 0px 10px 0px;
        width: 300px;
        height: 30px;
        border-radius: 5px;
        align-self: flex-end;

        .tab {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-right: 1px solid #eee;
            height: 100%;
            cursor: pointer;

            &:last-of-type {
                border-right: none;
            }
        }
    }
}

.rule_logs_container {
    min-height: 100px;
    max-height: 700px;
    border: 1px solid #eee;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    overflow-y: scroll;

    .empty_logs_container {
        display: flex;
        flex-direction: column;
        width: 100%;
        flex: 1;
        align-items: center;
        justify-content: center;
    }

    .logs_container {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 10px;

        .log_container {
            display: flex;
            flex-direction: column;
            margin: 10px 0px;
            border-bottom: 1px solid #eee;
            padding: 10px;

            &:last-of-type {
                border-bottom: none;
                margin-bottom: 0px;
                padding-bottom: 0px;
            }

            .log_header_container {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-bottom: 5px;

                .name {
                    grid-column: 1/1;
                    font-weight: bold;
                    margin-right: 20px;
                    line-height: 1.5em;
                }

                .asset_identification {
                    display: flex;
                    flex-direction: row;

                    .scope {
                        font-weight: bold;
                        margin-right: 10px;
                    }
                }
            }

            .log_description_container {
                display: grid;
                grid-template-columns: 200px 200px 200px auto;
                margin-bottom: 5px;
                align-items: center;

                .crated_at {
                    grid-column: 1/1;
                }

                .prev_value {
                    grid-column: 2/2;
                    display: flex;
                    flex-direction: row;

                    .label {
                        margin-right: 5px;
                    }
                }

                .updated_value {
                    grid-column: 3/3;
                    display: flex;
                    flex-direction: row;

                    .label {
                        margin-right: 5px;
                    }
                }

                .status_data_container {
                    grid-column: 5/5;
                    justify-self: flex-end;
                    display: grid;
                    grid-template-columns: 100px 100px 100px;
                    align-items: center;

                    .expression_status_totals {
                        display: flex;
                        flex-direction: row;
                        justify-self: flex-end;

                        .value {
                            margin-right: 2px;
                        }

                        .label {
                            margin-right: 10px;
                        }
                    }

                    .status {
                        justify-self: flex-end;
                        padding: 5px 10px;
                        border-radius: 3px;
                        // color: #fff;
                    }
                }
            }

            .expression_container {
                display: grid;
                grid-template-columns: 100px 100px 100px 100px 100px 100px 100px;
                margin: 5px 0px;
                padding: 7px 0px;
                padding-left: 10px;
                border-radius: 3px;
            }
        }
    }
}
