.user_accounts_view {
    // display: grid;
    // grid-template-columns: auto 900px auto;
    font-size: 12px;
    height: 100%;
    overflow: scroll;
    align-items: center;
    justify-content: center;
    padding: 0px 20px;

    .user_accounts_table_container {
        margin-top: 20px;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }

        .user_search_bar_container {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 30px;
            background-color: #fafafa;
            width: 100%;
            border-radius: 5px;
            margin-bottom: 10px;

            .icon {
                width: 30px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }

            .search_input {
                display: flex;
                flex-direction: row;
                width: 100%;
                padding-right: 20px;
                height: 30px;

                input {
                    background: transparent;
                    border: none;
                    width: 100%;
                    font-size: 14px;

                    &:focus {
                        outline: none;
                    }
                }
            }
        }

        .user_accounts_list_container {
            margin-top: 20px;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            width: 100%;

            .table_container {
                overflow: scroll;
                height: 100vh;
                overflow-x: scroll;
                overflow-y: visible;
                padding: 0;
                margin-bottom: 20px;

                -ms-overflow-style: none;
                scrollbar-width: none;

                &::-webkit-scrollbar {
                    display: none;
                }

                table {
                    font-family: "Inter", sans-serif !important;
                    font-size: 125%;
                    white-space: nowrap;
                    margin: 0;
                    border: none;
                    border-collapse: separate;
                    border-spacing: 0;
                    table-layout: fixed;
                    border: 1px solid black;
                }
                table td,
                table th {
                    border: 1px solid black;
                    padding: 0.5rem 1rem;
                }
                table thead th {
                    padding: 3px;
                    position: sticky;
                    top: 0;
                    z-index: 1;
                    width: 25vw;
                    background: white;
                }
                table td {
                    background: #fff;
                    padding: 10px 50px;
                    text-align: center;
                }

                table tbody th {
                    font-weight: 100;
                    font-style: italic;
                    text-align: left;
                    position: relative;
                }
                table thead th:first-child {
                    position: sticky;
                    left: 0;
                    z-index: 3;
                }
                table tbody td:first-child {
                    position: sticky;
                    left: 0;
                    z-index: 2;
                }
                table tbody th {
                    position: sticky;
                    left: 0;
                    background: white;
                    z-index: 1;
                }
            }
        }
    }
}
