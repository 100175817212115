@import "./colors";
@import "./home";
@import "./order";
@import "./left_nav";
@import "./visualizer";
@import "./analysis";
@import "./ads_manager";
@import "./rule";
@import "./integrations";
@import "./templates";
@import "./rules";
@import "./auth";
@import "./ad_accounts";
@import "./logs";
@import "./rule_uneditable";
@import "./user_accounts";
@import "./tracking_code";
@import "./shopify_settings";
@import "./onboarding";
@import "./ad_launcher";
@import "./audiences";
@import "./advertisers";
@import "./onboarding_two";
@import "./onboarding_three";

#root {
    width: 100%;
    height: 100%;
}

#app {
    position: fixed;
    left: 0;
    right: 0;
    // top: 0;
    bottom: 0;
    display: grid;

    // .top_notification_banner {
    //     position: fixed;
    //     top: 0;
    //     right: 0;
    //     left: 0;
    //     height: 60px;
    //     background-color: #eb072a;
    //     display: flex;
    //     flex-direction: column;
    //     align-items: center;
    //     justify-content: center;
    //     color: #fff;
    //     cursor: pointer;
    // }
}

.accounts_container {
    width: 400px;
    min-height: 500px;
    overflow-y: scroll;
    position: absolute;
    z-index: 999;
    right: 0px;
    top: 0px;
    border: 1px solid #eee;
    background-color: #fff;
    color: black;

    .account_container {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 30px;
        align-items: center;
        border-bottom: 1px solid black;
        padding-left: 20px;
        cursor: pointer;
        font-size: 12px;
    }
}

.screen_modal_content {
    display: flex;
    flex-direction: column;
    min-width: 100%;
    min-height: 100%;
    overflow: hidden;
}

.ReactModal__Overlay {
    z-index: 4;
    transition: transform 100ms linear;
    transform-origin: center center;
    transform: scale(0.7);
}

.ReactModal__Overlay--after-open {
    transform: scale(1);
}

.ReactModal__Overlay--before-close {
    opacity: 0;
}

.ReactModal__Content {
    display: flex;
    padding: 0px !important;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .modal_container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        background-color: aqua;
    }
}
