.templates_view {
    display: grid;
    grid-template-columns: 200px auto;
    font-size: 12px;
    overflow-y: scroll;

    .nav_container {
        grid-column: 1/1;
        display: flex;
        flex-direction: column;

        .nav {
            height: 100%;
            margin: 10px;
            padding: 10px;
            background-color: #fafafa;
            border-radius: 5px;

            .nav_item {
                cursor: pointer;
                padding: 7px 0px 7px 5px;
                color: #5f5f5f;

                &:hover {
                    color: #fff;
                    background-color: #41a9ff;
                    border-radius: 3px;
                }
            }
        }
    }

    .templates_container {
        grid-column: 2/2;
        overflow-y: scroll;
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-template-rows: 200px;

        .rule_template_container {
            padding: 10px;
            height: 200px;
            display: flex;
            flex-direction: column;
            overflow: hidden;

            .rule_template {
                border-radius: 3px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: 100%;
                width: 100%;
                border: 1px solid #eee;
                padding: 15px;
                position: relative;

                .description_text {
                    position: absolute;
                    top: 15px;
                    line-height: 1.2em;
                }

                .description_container {
                    font-size: 12px;

                    .expressions_container {
                        display: grid;
                        grid-template-columns: 15px auto;
                        margin-bottom: 10px;

                        .expressions_line_break {
                            grid-column: 1/1;
                            background-color: #2196f3;
                            height: 100%;
                            width: 1px;
                        }

                        .expressions {
                            grid-column: 2/2;

                            .expression_container {
                                display: flex;
                                flex-direction: row;

                                .field {
                                    margin: 2px 5px 2px 0px;
                                    color: #303030;
                                }

                                .bold {
                                    font-weight: bold;
                                }
                            }
                        }
                    }
                }

                .add_rule_button {
                    height: 35px;
                    border: 1px solid #eee;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    border-radius: 3px;
                    cursor: pointer;
                    position: absolute;
                    left: 15px;
                    right: 15px;
                    bottom: 15px;

                    &:hover {
                        background-color: #eeeeee;
                    }
                }
            }
        }
    }
}
