$blue_50: #e3f2fd;
$blue_100: #bbdefb;
$blue_200: #90caf9;
$blue_300: #64b5f6;
$blue_400: #42a5f5;
$blue_500: #2196f3;
$blue_600: #1e88e5;
$blue_700: #1976d2;
$blue_800: #1565c0;
$blue_900: #0d47a1;

$gray_50: #fafafa;
$gray_100: #f5f5f5;
$gray_200: #eeeeee;
$gray_300: #e0e0e0;
$gray_400: #bdbdbd;
$gray_500: #9e9e9e;
$gray_600: #757575;
$gray_700: #616161;
$gray_800: #424242;
$gray_900: #212121;
