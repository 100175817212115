.onboarding_view {
    font-size: 12px;
    overflow: hidden;

    .schedule_setup_call_container {
        position: fixed;
        right: 50px;
        top: 80px;
        width: 200px;
        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
        padding: 10px;
        line-height: 1.2;
        border-radius: 3px;
        background-color: #fff;

        .text_row {
            font-size: 14px;
            margin-bottom: 10px;
        }

        .cta {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 30px;
            background-color: #57e480;
            align-items: center;
            justify-content: center;
            border-radius: 3px;
            color: #fff;
            cursor: pointer;
        }
    }

    .onboarding_container {
        display: flex;
        flex-direction: column;
        height: 100%;
        align-items: center;
        justify-content: center;
        overflow: scroll;

        .onboarding_inner_container {
            display: flex;
            flex-direction: column;
            width: 700px;

            border-radius: 5px;
            // box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
            padding: 20px;
            padding-top: 10px;
            background-color: #fff;

            .onboarding_header {
                display: grid;
                grid-template-columns: 35px auto;
                margin-top: 19px;
                cursor: pointer;

                .step_container {
                    grid-column: 1/1;
                    display: flex;
                    flex-direction: column;
                    width: 35px;
                    height: 35px;
                    align-items: center;
                    justify-content: center;
                    border: 2px solid #349eff;
                    border-radius: 1000px;

                    .step_count {
                        color: #349eff;
                    }
                }

                .description_container {
                    display: flex;
                    flex-direction: row;
                    height: 100%;
                    align-items: center;
                    justify-content: space-between;
                    margin-left: 15px;
                    font-size: 16px;
                    font-weight: 500;

                    .title {
                        color: #474747;
                    }

                    .step_progress_container {
                        display: flex;
                        flex-direction: row;
                        height: 25px;
                        align-items: center;
                        border: 1px solid #eee;
                        border-bottom: 2px solid #eee;
                        border-radius: 5px;
                        font-size: 12px;

                        .step {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            width: 30px;
                            border-right: 1px solid #eee;

                            &:last-of-type {
                                border-right: none;
                            }
                        }
                    }
                }
            }

            .onboarding_panel_container {
                display: grid;
                grid-template-columns: 35px auto;
                margin-bottom: -20px;

                .step_line_container {
                    grid-column: 1/1;
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    align-items: center;

                    .line {
                        display: flex;
                        flex-direction: column;
                        height: 100%;
                        width: 2px;
                        background-color: #349eff;
                    }
                }

                .content {
                    grid-column: 2/2;
                    display: flex;
                    flex-direction: column;
                    margin-left: 15px;
                    width: 100%;
                    overflow: hidden;
                    padding-right: 15px;
                }
            }
        }
    }
}

.carousel_container {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    margin-top: 10px;

    .carousel_view_container {
        display: flex;
        flex-direction: column;
        width: 100%;

        .select_container {
            display: flex;
            flex-direction: column;
            border: 1px solid #eee;
            border-radius: 5px;
            padding: 0px 0px;
            background-color: #fff;
            width: 100%;

            .select_item_container {
                display: grid;
                grid-template-columns: 25px 250px auto;
                padding-left: 10px;
                cursor: pointer;
                align-items: center;
                border-bottom: 1px solid #eee;
                height: 50px;
                width: 100%;

                .right_adjusted {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;

                    .logo {
                        display: flex;
                        flex-direction: column;
                        padding: 0px 20px;
                    }
                }

                &:last-of-type {
                    border-bottom: none;
                }
            }
        }
    }
}

.connect_to_fb_container {
    height: 100%;
    width: 100%;
    margin-top: 10px;
    // margin-bottom: 30px;

    .connect_to_fb_button {
        background-color: #5c79ff;
        color: #fff;
        display: flex;
        flex-direction: column;
        height: 50px;
        width: 100%;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        cursor: pointer;

        a {
            color: #fff;
        }
    }
}

.connect_to_payment_processor_container {
    height: 100%;
    width: 100%;
    margin-top: 10px;

    .show_video_container {
        display: flex;
        flex-direction: column;
        height: 40px;
        margin-bottom: 20px;
        justify-content: center;
        align-items: center;
        border-radius: 3px;
        cursor: pointer;
        border: 1px solid #eee;
    }

    .connect_to_stripe_button {
        background-color: #5c79ff;
        color: #fff;
        display: flex;
        flex-direction: column;
        height: 50px;
        width: 100%;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        cursor: pointer;
    }
}

.webhooks_container {
    display: flex;
    flex-direction: column;
    margin: 20px 0px;

    .title {
        margin-bottom: 10px;
        font-size: 14px;
    }

    .webhooks_url_container {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        justify-content: center;
        background-color: #fdfdfd;
        border-radius: 5px;
        padding: 5px;
        padding-left: 20px;
        border: 1px solid #eee;
        min-height: 40px;
        line-height: 1.3em;
    }

    .copy_code_snippet_to_clipboard_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #fff;
        background-color: #5c79ff;
        width: 150px;
        margin-top: 10px;
        height: 40px;
        border-radius: 5px;
        cursor: pointer;
    }
}

.tracking_code_container {
    height: 100%;
    width: 100%;
    margin: 20px 0px;

    .title {
        margin-bottom: 10px;
        font-size: 14px;
    }

    .tracking_code {
        background-color: #fdfdfd;
        border-radius: 5px;
        padding: 5px;
        padding-left: 0px;
        border: 1px solid #eee;
        -ms-overflow-style: none;
        scrollbar-width: none;

        ::-webkit-scrollbar {
            display: none;
        }
    }

    .copy_code_snippet_to_clipboard_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #fff;
        background-color: #5c79ff;
        width: 150px;
        margin-top: 10px;
        height: 40px;
        border-radius: 5px;
        cursor: pointer;
    }
}

.ad_dynamic_urls_container {
    height: 100%;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 20px;
}

.facebook_accounts_container {
    display: flex;
    flex-direction: column;
    width: 100%;

    .facebook_accounts_search_filter_container {
        display: flex;
        flex-direction: row;
        height: 35px;
        width: 100%;

        input {
            height: 100%;
            width: 100%;
            padding-left: 10px;
            border: 1px solid #eee;
            border-bottom: 2px solid #eee;
            border-radius: 5px;
            font-size: 14px;
        }
    }

    .loading_fb_accounts_container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;

        .loading_icon_container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .text {
                font-size: 16px;
                margin-bottom: 10px;
            }

            .icon {
                font-size: 30px;
            }
        }
    }

    .facebook_accounts_list_container {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        height: 300px;
        overflow: scroll;
        border: 1px solid #eee;
        border-radius: 5px;
        padding: 5px 0px;
        -ms-overflow-style: none !important;
        scrollbar-width: none !important;

        &::-webkit-scrollbar {
            display: none !important;
        }

        .account {
            display: grid;
            grid-template-columns: 30px auto;
            align-items: center;
            margin: 7px 0px;
            padding-left: 10px;
            cursor: pointer;

            .select {
            }

            .name {
                grid-column: 2/2;
                font-size: 14px;
            }
        }
    }
}

.done_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #eee;
    border-radius: 5px;

    .step {
        display: grid;
        grid-template-columns: auto 150px;
        border-bottom: 1px solid #eee;
        grid-template-rows: 50px;
        align-items: center;

        .name {
            padding-left: 20px;
        }

        .status {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            padding-right: 20px;
            font-size: 16px;
        }

        &:last-of-type {
            border-bottom: none;
        }
    }
}

.onboarding_status_button {
    display: flex;
    flex-direction: row;
    width: 700px;
    margin-top: 20px;

    .success_status_button {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        align-items: center;
        justify-content: center;
        height: 50px;
        font-size: 16px;
        background-color: #5c79ff;
        color: #fff;
        border-radius: 5px;
        cursor: pointer;
    }

    .disabled_status_button {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        align-items: center;
        justify-content: center;
        height: 50px;
        font-size: 16px;
        background-color: #f0f0f0;
        color: #d6d6d6;
        border-radius: 5px;
        cursor: pointer;
        border: 1px solid #d6d6d6;
    }
}

.initial_video_container {
    display: flex;
    justify-items: center;
    justify-content: center;
    height: 100%;

    .video_container {
        width: 800px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .close_video_button {
            margin-top: 20px;
            background-color: #5c79ff;
            width: 400px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 50px;
            border-radius: 5px;
            cursor: pointer;
            color: #ffff;
        }
    }
}

.initial_video_headline {
    margin-bottom: 30px;
    font-weight: bold;
    font-size: 30px;
    text-align: center;
}

.button_delay_notification {
    margin-top: 20px;
}
