.onboarding_two_container {
    font-size: 12px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    line-height: 1.3;

    .onboarding_container {
        display: grid;
        grid-template-columns: auto 800px auto;
        grid-template-rows: 50px auto;
        overflow: scroll;
        height: 100%;

        // .left_nav {
        //     grid-column: 1/1;
        //     grid-row: 1/1;
        //     display: flex;
        //     flex-direction: column;
        //     height: 100%;
        //     background-color: aqua;
        // }

        .onboard_container {
            grid-column: 2/2;
            grid-row: 2/2;
            display: flex;
            flex-direction: column;
            padding: 20px;
        }
    }
}

.connect_to_fb_container {
    margin-bottom: 20px;
}

.video_card_container {
    background-color: #fff;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 20px;

    .title {
        font-size: 20px;
        text-align: center;
        margin-bottom: 20px;
        font-weight: bold;
    }

    .video_container {
        img {
            border-radius: 5px;
            border: 3px solid #000;
        }
        .icon {
            margin-top: 20px;
            font-size: 30px;
        }
    }
}
